/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from 'react'
import axios from 'axios'
import { connect, useDispatch } from 'react-redux'
import {
    CheckSessionExpiry,
    fetchProfileModal,
    openChangePasswordModal,
    toggleDeactivateModal,
    toggleProfileModal,
    toggleSessionModal,
} from '../../redux/modals/toggleModal.actions'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import variables from '../../theme/variables.scss'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Container from '@mui/material/Container'
import Menu from '@mui/material/Menu'
import AccountCircle from '@mui/icons-material/AccountCircle'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MoreIcon from '@mui/icons-material/MoreVert'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import Profile from '../profile'
import '../app-bar/app-bar.scss'
import { getUserDisplayName } from '../../util/index'
import { setAlert } from '../../redux/alert/alert.action'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        borderBottom: 'none',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogTitle-root': {
        background: '#F3F4F6',
        fontWeight: 600,
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export const ProfileDropdownMenu = ({ login, profile, profileLoading }) => {
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
    const [open, setOpen] = React.useState(false)
    const isMenuOpen = Boolean(anchorEl)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

    React.useEffect(() => {
        // dispatch(fetchProfileModal(true, login.accessToken))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
        handleMobileMenuClose()
    }

    const handleLogout = () => {
        axios
            .post(`${process.env.REACT_APP_SCREENFLOW_BASE_URL}/logout`, '', {
                headers: {
                    accessToken: login.accessToken,
                    Authorization: `Bearer ${login.idToken}`,
                },
            })
            .then((response) => {
                if (response?.status === 200) {
                    localStorage.clear()
                    window.location.replace('/')
                } else if (response?.status === 401) {
                    dispatch(toggleSessionModal(true, response?.message))
                } else if (response.data.status === 403) {
                    dispatch(toggleDeactivateModal(true))
                }
            })
            .catch((err) => {
                console.log(err)
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: `${err?.message}. Please try again`,
                        alertOpen: true,
                    })
                )
            })
    }

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setOpen(false)
    }
    const checkSession = () => {
        // dispatch(CheckSessionExpiry(login.accessToken))
        setOpen(true)
    }
    const handleProfile = () => {
        handleMenuClose()
        // dispatch(fetchProfileModal(true, login.accessToken))
        dispatch(toggleProfileModal(true))
    }
    const handleUpdatePassword = () => {
        handleMenuClose()
        dispatch(openChangePasswordModal(true, login.accessToken))
    }

    const menuId = 'primary-search-account-menu'
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 4,
                    ml: 2,
                    width: '215px',
                    fontFamily: 'Inter',
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                },
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            disableScrollLock={true}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleProfile}>
                <img className="me-3" src="/images/profile/user_icon.svg" alt="Profile" /> Profile
            </MenuItem>
            <MenuItem onClick={handleUpdatePassword}>
                <img className="me-3" src="/images/profile/password_icon.svg" alt="Update Password" /> Update
                Password
            </MenuItem>

            <MenuItem
                disabled={true}
                sx={{ cursor: 'not-allowed' }}
                className="cursor-not-allowed"

                //  onClick={handleMenuClose}
            >
                <div style={{ cursor: 'not-allowed !important' }} className="cursor-not-allowed">
                    <img className="me-3" src="/images/profile/tnc_icon.svg" alt="Terms & Conditions" /> Terms
                    & Conditions
                </div>
            </MenuItem>
            <a
                href="https://cardeahealth.io/privacy-policy/"
                target="_blank"
                style={{ textDecoration: 'none', color: 'inherit' }}
                rel="noreferrer"
            >
                <MenuItem onClick={handleMenuClose}>
                    <img className="me-3" src="/images/profile/pp_icon.svg" alt="Privacy Policy" /> Privacy
                    Policy
                </MenuItem>
            </a>
            <a
                href="mailto:flow-support@cardeahealth.io"
                target="_blank"
                style={{ textDecoration: 'none', color: 'inherit' }}
                rel="noreferrer"
            >
                <MenuItem onClick={handleMenuClose}>
                    <img className="me-3" src="/images/profile/email-icon.svg" alt="Contact Us" />
                    Customer Support
                </MenuItem>
            </a>
            <MenuItem style={{ color: 'rgb(111 120 130)' }} onClick={checkSession}>
                <img className="me-3" src="/images/profile/locgout_icon.svg" alt="Logout" /> Logout
            </MenuItem>
        </Menu>
    )

    const mobileMenuId = 'primary-search-account-menu-mobile'
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    )

    return (
        <>
            <Box sx={{ flexGrow: 1, paddingBottom: '75px' }}>
                <Container maxWidth="lg">
                    <Toolbar disableGutters>
                        {profileLoading ? (
                            <Box sx={{ height: '24px', width: '26px' }}>
                                <img
                                    src="/loader.gif"
                                    style={{ height: '100%', width: '100%' }}
                                    alt="loader"
                                />
                            </Box>
                        ) : (
                            <>
                                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                    <p
                                        className="cursor-pointer"
                                        onClick={handleProfileMenuOpen}
                                        style={{
                                            fontSize: '0.875rem',
                                            marginBottom: '0px',
                                            color: variables.purple,
                                        }}
                                    >
                                        {getUserDisplayName(login)}
                                        <KeyboardArrowDownIcon />
                                    </p>
                                </Box>
                                <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                                    <IconButton
                                        size="large"
                                        aria-label="show more"
                                        aria-controls={mobileMenuId}
                                        aria-haspopup="true"
                                        onClick={handleMobileMenuOpen}
                                        color="inherit"
                                    >
                                        <MoreIcon />
                                    </IconButton>
                                </Box>
                            </>
                        )}
                    </Toolbar>
                </Container>

                {renderMobileMenu}
                {renderMenu}
                <Profile />
            </Box>
            <BootstrapDialog onClose={handleClose} aria-labelledby="small-modal" open={open}>
                <BootstrapDialogTitle id="small-modal" onClose={handleClose}>
                    Logout
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography className="fw-bold fs-5" gutterBottom>
                        Are you sure you want to logout?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        className="btn-white-outline"
                        type="submit"
                    >
                        Cancel
                    </Button>
                    <Button onClick={handleLogout} variant="contained" className="btn-primary ms-4">
                        Logout
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    )
}

const mapStateToProps = (state) => ({
    login: state.loginData.loginResponse,
    profile: state.toggleModals.profileData,
    profileLoading: state.toggleModals.profileLoading,
    profileModal: state.toggleModals.profileModal,
    loading: state.appointment.loading,
})

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProfileModal: (payload, token) => dispatch(fetchProfileModal(payload, token)),
        toggleProfileModal: (payload) => dispatch(toggleProfileModal(payload)),
        toggleSessionModal: (payload, data) => dispatch(toggleSessionModal(payload, data)),
        openChangePasswordModal: (payload, token) => dispatch(openChangePasswordModal(payload, token)),
        toggleDeactivateModal: (payload) => dispatch(toggleDeactivateModal(payload)),
        CheckSessionExpiry: (payload) => dispatch(CheckSessionExpiry(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDropdownMenu)
