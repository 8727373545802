import React from 'react'
import { connect } from 'react-redux'
import variables from '../../../theme/variables.scss'
import _ from 'lodash'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Avatar from '@mui/material/Avatar'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'

export const Index = ({ patientId, token, consultListData, consultListLoading }) => {
    return (
        <>
            <Grid
                rowSpacing={0.5}
                columnSpacing={2}
                container
                direction="row"
                justifyContent="start"
                alignItems="center"
            >
                {!!consultListLoading ? (
                    <Grid item container direction="row" justifyContent="center" alignItems="center">
                        <img style={{ width: '35px' }} src="/loader.gif" alt="loader" />
                    </Grid>
                ) : _.isEmpty(consultListData) ? (
                    <Grid item container direction="row" justifyContent="center" alignItems="center">
                        <Box>
                            <Typography className="gray-900 fw-normal fs-8 ">
                                No consult notes found
                            </Typography>
                        </Box>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <List
                            sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                            }}
                            disablePadding={true}
                        >
                            {Object.keys(consultListData).map((val, index) => (
                                <ListItem key={index} disablePadding={true}>
                                    <ListItemText
                                        disableTypography
                                        sx={{
                                            textTransform: 'capitalize',
                                            color: variables.gray600,
                                            fontSize: '0.875rem',
                                            fontWeight: 500,
                                        }}
                                        primary={val ?? '-'}
                                    />
                                    <ListItemSecondaryAction>
                                        <Avatar
                                            sx={{
                                                bgcolor: variables.gray200,
                                                height: '1.25rem',
                                                width: '1.5rem',
                                                color: variables.gray600,
                                                fontSize: '0.75rem',
                                            }}
                                        >
                                            {consultListData[val] ?? '-'}
                                        </Avatar>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                )}
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    consultListData: state?.consultNotes?.consultListData,
    consultListLoading: state?.consultNotes?.consultListLoading,
})

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
