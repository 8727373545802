import React from 'react'
import { connect, useDispatch } from 'react-redux'
import moment from 'moment'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { DATE_FORMAT } from '../../util/dateTimeFormats'
import { toggleCtModal } from '../../redux/modals/toggleModal.actions'
import { getCtDocument } from '../../redux/CCM/CTList/CTList.action'

export const Index = ({ ctDocuments, CTLoading, patientId, token }) => {
    const dispatch = useDispatch()

    return (
        <div>
            {CTLoading ? (
                <div className="w-100 d-flex justify-content-center align-items-center">
                    <img src="/loader.gif" width="35" alt="loader" />
                </div>
            ) : (
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item>
                        <img
                            style={{
                                width: '148px',
                                height: '104px',
                            }}
                            src="/images/pdf-icon.svg"
                            alt="pdf icon"
                        />
                    </Grid>
                    <Grid item>
                        <Typography className="gray-900 fw-normal fs-8 mt-3">CT Report</Typography>
                        <Button
                            size="small"
                            variant="contained"
                            className="btn-secondary mt-1"
                            onClick={() => {
                                const sixtyMonth = moment().subtract(60, 'months').calendar()
                                const proposedDate =
                                    moment(sixtyMonth).format(DATE_FORMAT.YYYY_MM_DD) + 'T00:00:00.000Z'
                                const payload = {
                                    patientId: patientId,
                                    date: proposedDate,
                                }
                                dispatch(getCtDocument(payload, token))
                                dispatch(toggleCtModal(true))
                            }}
                        >
                            View Report
                        </Button>
                    </Grid>
                </Grid>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    ctLUD: state.CT.lud,
    ctDocuments: state.CT.CTDocuments,
    CTLoading: state.CT.CTLoading,
})

const mapDispatchToProps = (dispatch) => {
    return {
        toggleCtModal: (payload) => dispatch(toggleCtModal(payload)),
        getCtDocument: (payload, token) => dispatch(getCtDocument(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
