import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import PropTypes from 'prop-types'
import '../../interval-history/interval-modal/interval-components/inputs.scss'
import variables from '../../../theme/variables.scss'
import _ from 'lodash'
import { connect, useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { toggleConsultLetterModal } from '../../../redux/modals/toggleModal.actions'

import PatientInfo from '../consult-letter-details/PatientInfo'
import SocialHistoryInfo from '../consult-letter-details/SocialHistoryInfo'
import RecommendationInfo from '../consult-letter-details/RecommendationInfo'
import CardiacInfo from '../consult-letter-details/CardiacInfo'
import IntervalHistoryInfo from '../consult-letter-details/IntervalHistoryInfo'
import PhysicalExamInfo from '../consult-letter-details/PhysicalExamInfo'
import MedicalHistoryInfo from '../consult-letter-details/MedicalHistoryInfo'
import AdverseEventInfo from '../consult-letter-details/AdverseEventInfo'
import MedicationInfo from '../consult-letter-details/MedicationInfo'
import PillBoxInfo from '../consult-letter-details/PillBoxInfo'
import LabsInfo from '../consult-letter-details/LabsInfo'
import VitalsInfo from '../consult-letter-details/VitalsInfo'
import EcgInfo from '../consult-letter-details/EcgInfo'
import HolterInfo from '../consult-letter-details/HolterInfo'
import GxtInfo from '../consult-letter-details/GxtInfo'
import EchoInfo from '../consult-letter-details/EchoInfo'
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        minHeight: '500px',
        minWidth: '500px',
        display: 'flex',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialogTitle-root': {
        background: variables.gray100,
        fontWeight: 600,
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

const GridWrapper = ({ children, classNames }) => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            sx={{
                py: 2,
            }}
            className={classNames}
        >
            {children}
        </Grid>
    )
}

export const RecommendationsModal = ({
    patient,
    recommendationsModal,
    recommendationList,
    recommendationLoading,
    lastUpdatedRecommendations,
    consultLetterModal,
    intervalHistoryData,
    physicalExamData,
}) => {
    const dispatch = useDispatch()

    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `${patient?.firstName ?? ''}
       ${patient?.lastName ?? ''} Consult Letter`,
    })

    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick') return

        dispatch(toggleConsultLetterModal(false))
    }

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                maxWidth="md"
                fullWidth={true}
                aria-labelledby="small-modal"
                open={consultLetterModal}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Typography className="fs-5 fw-bold">Consult Letter</Typography>
                </BootstrapDialogTitle>

                <DialogContent dividers sx={{ padding: '0 !important', overflow: 'auto' }}>
                    <Box ref={componentRef}>
                        {/* Patient Info and Social history */}
                        <GridWrapper>
                            <Grid item xs={11}>
                                <PatientInfo />
                            </Grid>
                        </GridWrapper>

                        {/* Recommendation Details and Cardiac History */}
                        <GridWrapper>
                            <Grid item xs={11}>
                                <RecommendationInfo />
                            </Grid>

                            <Grid item xs={11}>
                                <CardiacInfo />
                            </Grid>
                        </GridWrapper>

                        {/* Interval History */}
                        <GridWrapper
                            classNames={
                                recommendationList?.length > 0 &&
                                _.filter(intervalHistoryData?.intervalHistory, { active: true })?.length > 0
                                    ? 'pagebreak'
                                    : ''
                            }
                        >
                            <Grid item xs={11}>
                                <IntervalHistoryInfo />
                            </Grid>
                        </GridWrapper>

                        {/* Physical Exam */}
                        <GridWrapper
                            classNames={
                                _.filter(physicalExamData?.physicalExam, { active: true })?.length > 0
                                    ? 'pagebreak'
                                    : ''
                            }
                        >
                            <Grid item xs={11}>
                                <PhysicalExamInfo />
                            </Grid>
                        </GridWrapper>

                        {/* Medical History , Medication and Adverse Events */}
                        <GridWrapper classNames="pagebreak">
                            <Grid item xs={11}>
                                <Grid container spacing={2} sx={{ pt: 1 }}>
                                    <Grid item xs={3}>
                                        <MedicalHistoryInfo />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <MedicationInfo />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <AdverseEventInfo />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </GridWrapper>

                        <GridWrapper>
                            <Grid item xs={11}>
                                <Grid item xs={7}>
                                    <SocialHistoryInfo />
                                </Grid>
                            </Grid>
                        </GridWrapper>

                        {/* Pill-Box Info, Vitals, Labs, Ecg , Holter, Gxt and Echo Details */}
                        <GridWrapper classNames="pagebreak">
                            <Grid item xs={11}>
                                <Grid container spacing={3} sx={{ pt: 1 }}>
                                    <Grid item xs={5}>
                                        <PillBoxInfo />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <VitalsInfo />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <LabsInfo />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <EcgInfo />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <HolterInfo />
                                    </Grid>
                                    <Grid item xs={7}>
                                        <GxtInfo />
                                    </Grid>
                                    <Grid item xs={8} className="pagebreak">
                                        <EchoInfo />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </GridWrapper>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                            justifyContent: 'end',
                            padding: '2px',
                        }}
                    >
                        <Button
                            onClick={() => dispatch(toggleConsultLetterModal(false))}
                            variant="contained"
                            className="btn-white-outline"
                        >
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            variant="contained"
                            className="btn-primary fs-9"
                            disabled={!recommendationList?.length > 0}
                            onClick={handlePrint}
                        >
                            Print
                        </Button>
                    </Box>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        patientId: state.patient.patientData.patientId,
        token: state.loginData.loginResponse.accessToken,
        patient: state.patient.patientData,
        recommendationsModal: state?.toggleModals?.recommendationsModal,
        recommendationList: state?.recommendations?.list?.recommendation,
        recommendationLoading: state?.recommendations?.recommendationLoading,
        lastUpdatedRecommendations: state?.recommendations?.lud,
        consultLetterModal: state?.toggleModals?.consultLetterModal,
        intervalHistoryData: state?.intervalHistory?.list,
        physicalExamData: state?.physicalExam?.list,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleConsultLetterModal: (payload) => dispatch(toggleConsultLetterModal(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecommendationsModal)
