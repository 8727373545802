import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import _ from 'lodash'
import clsx from 'clsx'
import variables from '../../../../theme/variables.scss'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Autocomplete from '@mui/material/Autocomplete'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import axios from 'axios'
import moment from 'moment'
import {
    toggleDeactivateModal,
    toggleSessionModal,
    toggleCardiacModal,
} from '../../../../redux/modals/toggleModal.actions'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { setAlert } from '../../../../redux/alert/alert.action'
import {
    getCardiacData,
    getCardiacListData,
    setCurrentMedication,
} from '../../../../redux/COP/cardiacList/cardiacList.action'
import { DATE_FORMAT } from '../../../../util/dateTimeFormats'
import './cardiac-form.scss'
import { indicationData, frequencyData } from '../../../../util/formData'

export const Index = ({
    submit,
    token,
    patientId,
    setAlignment,
    alignment,
    searchValue,
    setSearchValue,
    cardiacMedication,
    currentMedication,
}) => {
    /* medicine update helper */
    const fillValHelper = (objectValue) => {
        if (alignment === 'Update') {
            switch (objectValue) {
                case 'strength':
                    return (cardiacMedication[0]?.strength || cardiacMedication[0]?.dosage) ?? null
                case 'number':
                    return cardiacMedication[0]?.number ?? null
                case 'frequency':
                    return cardiacMedication[0]?.frequency ?? null
                case 'startDate':
                    return cardiacMedication[0]?.startDate ?? null
                case 'refills':
                    return cardiacMedication[0]?.refills ?? null
                case 'duration':
                    return (
                        {
                            value: cardiacMedication[0]?.duration?.split(' ')[0] ?? '',
                            type: cardiacMedication[0]?.duration?.split(' ')[1] ?? '',
                        } ?? null
                    )
                case 'indications':
                    return JSON.parse(cardiacMedication[0]?.indication) ?? null
                case 'isPast':
                    return cardiacMedication[0]?.isPast ?? null
                default:
                    return null
            }
        }
        return null
    }

    /* medication add  */
    const [medStrength, setMedStrength] = React.useState(fillValHelper('strength'))
    const [number, setNumber] = React.useState(fillValHelper('number') ?? '')
    const [frequency, setFrequency] = React.useState(fillValHelper('frequency') ?? '')
    const [start, setStart] = React.useState(alignment === 'Update' ? false : true)
    const [startDate, setStartDate] = React.useState(fillValHelper('startDate') ?? null)
    const [refills, setRefills] = React.useState(fillValHelper('refills') ?? 1)
    const [duration, setDuration] = React.useState(fillValHelper('duration') ?? { value: '0', type: 'day' })
    const [indications, setIndications] = React.useState(fillValHelper('indications') ?? [])
    const [isPast, setIsPast] = React.useState(fillValHelper('isPast') ?? false)

    const dispatch = useDispatch()

    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        submit.current = OnSubmit
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const OnSubmit = () => {
        // setLoading(true)

        if (!startDate && !start) {
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg: 'Please enter date!',
                    alertOpen: true,
                })
            )
        } else if (number === '' || frequency === '') {
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg: 'Please enter missing values!',
                    alertOpen: true,
                })
            )
        } else if (_.isEmpty(searchValue?.strength) && (medStrength === '' || _.isEmpty(medStrength))) {
            console.log('cardiacMedication[0]?.strength', cardiacMedication[0]?.strength)
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg: 'Please enter medication strength value',
                    alertOpen: true,
                })
            )
        } else {
            setLoading(false)
            const filterItem =
                alignment === 'Add'
                    ? {
                          strength: searchValue?.strength,
                          number: number,
                          frequency: frequency,
                          indication: indications,
                          startDate: !!start ? moment(Date.now()).format() : moment(startDate).format(),
                          duration: { value: duration?.value, type: duration?.type },
                          refills: refills,
                          className: searchValue?.className,
                          form: searchValue?.form,
                          name: searchValue?.brandName,
                          isPast: isPast,
                      }
                    : alignment === 'Update'
                    ? {
                          id: cardiacMedication[0]?.dosages[0]?.id,
                          strength:
                              medStrength || cardiacMedication[0].strength || cardiacMedication[0]?.dosage,
                          number: number,
                          frequency: frequency,
                          indication: indications,
                          startDate: !!start ? moment(Date.now()).format() : moment(startDate).format(),
                          duration: { value: duration?.value, type: duration?.type },
                          refills: refills,
                          className: cardiacMedication[0]?.class,
                          form: cardiacMedication[0]?.form,
                          name: cardiacMedication[0]?.name,
                          isPast: isPast,
                      }
                    : null
            processMedications(filterItem)
        }
    }

    const processMedications = (filterItem) => {
        setLoading(true)
        const path = alignment === 'Update' ? 'edit' : 'add'
        const payload = {
            patientId: patientId,
            medication: filterItem,
        }

        axios({
            method: alignment === 'Update' ? 'put' : 'post',
            url: `${process.env.REACT_APP_CARD_URL}/medication/${path}`,
            data: payload,
            headers: {
                accessToken: token,
            },
        }).then((response) => {
            if (response?.data?.status === 200) {
                setLoading(false)
                dispatch(
                    setAlert({
                        alertType: 'success',
                        alertMsg: `Medication ${alignment === 'Update' ? 'Updated' : 'Added'} Successfully`,
                        alertOpen: true,
                    })
                )
                const payloadList = {
                    patientId: patientId,
                    type: 'cardiac',
                }

                const sixtyMonth = moment().subtract(60, 'months').calendar()
                const proposedDate = moment(sixtyMonth).format(DATE_FORMAT.YYYY_MM_DD) + 'T00:00:00.000Z'
                const payloadCurrView = {
                    patientId: patientId,
                    type:
                        alignment === 'Add'
                            ? searchValue?.brandName
                            : alignment === 'Update'
                            ? cardiacMedication[0]?.name
                            : null,
                    date: proposedDate,
                }

                /* additonal refetching list and view */
                dispatch(getCardiacListData(payloadList, token))
                dispatch(setCurrentMedication('cardiac'))
                dispatch(getCardiacData(payloadCurrView, token))
                /*  */
                dispatch(toggleCardiacModal(false))
            } else if (response?.data?.status === 400) {
                setLoading(false)
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: `Error in processing Medication. Please! try again`,
                        alertOpen: true,
                    })
                )
            } else if (response?.data?.status === 401) {
                setLoading(false)
                dispatch(toggleSessionModal(true, response?.data?.error))
            } else if (response?.data?.status === 403) {
                setLoading(false)
                dispatch(toggleDeactivateModal(true))
            } else {
                setLoading(false)
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: `Something went wrong. Please! try again.`,
                        alertOpen: true,
                    })
                )
            }
        })
    }

    /* medication add new */
    const handleMedStrengthChange = (event) => {
        const regex = /^[0-9\b]+$/
        // eslint-disable-next-line eqeqeq
        if (event.target.value == '' || regex.test(event.target.value)) {
            setMedStrength(event.target.value)
        }
    }

    const handleNumberChange = (event, nextView) => {
        setNumber(nextView ?? '')
    }
    const handleFrequencyChange = (event, nextView) => {
        setFrequency(nextView ?? '')
    }
    const handleStartChange = (event, nextView) => {
        setStart(!start)
    }
    const handleStartDateChange = (newValue) => {
        setStartDate(newValue)
    }
    const handleRefillChange = (event) => {
        setRefills(event.target.value)
    }

    const handleIsPastChange = (event) => {
        setIsPast(event.target.checked)
    }
    const handleDurationChange = (event) => {
        const regex = /^[0-9\b]+$/
        // eslint-disable-next-line eqeqeq
        if (event.target.value == '' || regex.test(event.target.value)) {
            setDuration((prevDuration) => {
                return {
                    ...prevDuration,
                    value: event.target.value,
                }
            })
        }
    }
    const handleDurationTypeChange = (event) => {
        setDuration((prevDuration) => {
            return {
                ...prevDuration,
                type: event.target.value,
            }
        })
    }

    useEffect(() => {
        if (alignment === 'Add') {
            setMedStrength(null)
            setNumber('')
            setFrequency('')
            setStart(true)
            setStartDate(null)
            setRefills('1')
            setDuration({ value: '0', type: 'day' })
            setIndications([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue])

    return (
        <div className="medication-class">
            {!!searchValue?.brandName || alignment === 'Update' ? (
                <Typography className="fs-5 mt-2 mb-2 fw-bold " gutterBottom>
                    {alignment === 'Add'
                        ? `${searchValue?.brandName ?? ''} ${
                              _.isNil(searchValue?.strength) ? '' : `(${searchValue?.strength})`
                          }`
                        : alignment === 'Update'
                        ? `${cardiacMedication[0]?.name ?? ''} ${
                              _.isNil(cardiacMedication[0]?.strength || cardiacMedication[0]?.dosage)
                                  ? ''
                                  : `(${cardiacMedication[0]?.strength || cardiacMedication[0]?.dosage})`
                          }`
                        : null}
                </Typography>
            ) : null}

            {!!searchValue?.brandName || alignment === 'Update' ? (
                <Grid container direction="row" rowSpacing={1.5} columnSpacing={0.2}>
                    <Grid item xs={6} md={2}>
                        <Typography className="gray-600 fs-8 fw-normal" gutterBottom>
                            Strength *
                        </Typography>
                        <div className="d-flex">
                            {(_.isEmpty(cardiacMedication[0]?.strength) ||
                                cardiacMedication[0]?.strength === '') &&
                            alignment === 'Update' ? (
                                <Box className="d-flex justify-content-start align-items-center">
                                    <TextField
                                        autoComplete="off"
                                        value={medStrength}
                                        onChange={handleMedStrengthChange}
                                        type="number"
                                        min="0"
                                        id="outlined-size-small"
                                        size="small"
                                        sx={{ maxWidth: '60px' }}
                                    />
                                    <Typography className="px-2 fs-8">mg</Typography>
                                </Box>
                            ) : (
                                <Box className="input-chip" sx={{ textTransform: 'uppercase !important' }}>
                                    {alignment === 'Update'
                                        ? fillValHelper('strength') ?? '-NA-'
                                        : alignment === 'Add'
                                        ? searchValue?.strength
                                        : '-'}
                                </Box>
                            )}
                        </div>
                    </Grid>

                    <Grid item xs={6} md={2}>
                        <Typography className="gray-600 fs-8 fw-normal" gutterBottom>
                            Number *
                        </Typography>
                        <div className="d-flex">
                            <ToggleButtonGroup
                                orientation="vertical"
                                value={number}
                                exclusive
                                onChange={handleNumberChange}
                            >
                                <ToggleButton value="0.5" aria-label="0.5">
                                    0.5
                                </ToggleButton>
                                <ToggleButton value="1" aria-label="1">
                                    1
                                </ToggleButton>
                                <ToggleButton value="1.5" aria-label="1.5">
                                    1.5
                                </ToggleButton>
                                <ToggleButton value="2" aria-label="2">
                                    2
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </Grid>

                    <Grid item xs={6} md={8}>
                        <Typography className="gray-600 fs-8 fw-normal" gutterBottom>
                            Frequency *
                        </Typography>
                        <div className="d-flex">
                            <ToggleButtonGroup
                                orientation="horizontal"
                                value={frequency}
                                exclusive
                                onChange={handleFrequencyChange}
                                sx={{
                                    maxWidth: '8rem',
                                    columnGap: 1,
                                    flexWrap: 'wrap',
                                }}
                            >
                                {frequencyData?.map((frq, idx) => {
                                    return (
                                        <ToggleButton
                                            key={idx}
                                            value={frq?.val}
                                            sx={{
                                                lineHeight: 1.7,
                                            }}
                                        >
                                            {frq?.id}
                                        </ToggleButton>
                                    )
                                })}
                            </ToggleButtonGroup>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Typography className="gray-600 fs-8 fw-normal" gutterBottom>
                            Indications
                        </Typography>
                        <div className="d-flex" sx={{ padding: 2 }}>
                            <Autocomplete
                                limitTags={3}
                                value={indications}
                                onChange={(event, newValue) => {
                                    setIndications(newValue)
                                }}
                                freeSolo
                                sx={{ maxHeight: 140, overflow: 'auto' }}
                                className="medication-multiselect"
                                multiple
                                id="tags-outlined"
                                options={indicationData}
                                getOptionLabel={(option) => option}
                                defaultValue={[indicationData[0]]}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Indications"
                                        sx={{ minWidth: '400px' }}
                                    />
                                )}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={1} md={1}>
                        <Typography className="gray-600 fs-8 fw-normal" gutterBottom>
                            Start *
                        </Typography>
                        <div className="d-flex">
                            <ToggleButton
                                value="start"
                                aria-label="start"
                                selected={start}
                                onChange={handleStartChange}
                            >
                                Now
                            </ToggleButton>
                        </div>
                    </Grid>

                    <Grid item xs={1} md={1}>
                        <div className="d-flex"></div>
                    </Grid>
                    <Grid item xs={6} md={8}>
                        <Typography className="gray-600 fs-8  fw-normal" gutterBottom>
                            Start Date *
                        </Typography>

                        <div className="d-flex">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    disabled={!!start}
                                    disablePast={true}
                                    disableMaskedInput
                                    inputFormat="MMM DD, YYYY"
                                    value={startDate}
                                    onChange={handleStartDateChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            className="myDatePicker"
                                            error={false}
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: '',
                                                style: { cursor: !!start ? 'not-allowed' : 'pointer' },
                                            }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                    </Grid>

                    <Grid item xs={6} md={4}>
                        <Typography className="gray-600 fs-8  fw-normal" gutterBottom>
                            Duration
                        </Typography>
                        <div className="d-flex justify-content-start align-items-center">
                            <TextField
                                value={duration?.value}
                                onChange={handleDurationChange}
                                type="number"
                                min="0"
                                id="outlined-size-small"
                                size="small"
                                sx={{ maxWidth: '60px' }}
                            />
                            <Select
                                value={duration?.type ?? ''}
                                onChange={handleDurationTypeChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{ maxHeight: '40px', marginLeft: '2px' }}
                            >
                                <MenuItem value="">None</MenuItem>
                                <MenuItem value={'day'}>Day(s)</MenuItem>
                                <MenuItem value={'week'}>Week(s)</MenuItem>
                                <MenuItem value={'month'}>Month(s)</MenuItem>
                                <MenuItem value={'year'}>Year(s)</MenuItem>
                            </Select>
                        </div>
                    </Grid>

                    <Grid item xs={4} md={6}>
                        <Typography className="gray-600 fs-8  fw-normal" gutterBottom>
                            Refill(s)
                        </Typography>
                        <div className="d-flex">
                            <Select
                                value={refills}
                                onChange={handleRefillChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{ maxHeight: '40px' }}
                            >
                                <MenuItem value="">None</MenuItem>
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                            </Select>
                        </div>
                    </Grid>
                    <Grid item xs={4} md={6}>
                        <div>
                            <FormGroup>
                                <FormControlLabel
                                    label={
                                        <Typography
                                            sx={{
                                                pointerEvents: 'auto !important',
                                            }}
                                            className={clsx(
                                                'fs-8 fw-normal',
                                                !!(currentMedication === 'past') || !!(alignment === 'Add')
                                                    ? 'gray-400 cursor-not-allowed'
                                                    : 'gray-600'
                                            )}
                                        >
                                            Mark as past
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            value={!!isPast}
                                            checked={!!isPast}
                                            onChange={handleIsPastChange}
                                            disabled={
                                                !!(currentMedication === 'past') || !!(alignment === 'Add')
                                            }
                                            disableRipple={true}
                                            sx={{
                                                color: variables.gray400,
                                                '&.Mui-checked': {
                                                    color: variables.purple,
                                                },
                                                '&.Mui-disabled': {
                                                    color: variables.gray200,
                                                    pointerEvents: 'auto !important',
                                                    cursor: 'not-allowed !important',
                                                },
                                            }}
                                        />
                                    }
                                />
                            </FormGroup>
                        </div>
                    </Grid>
                </Grid>
            ) : null}

            <DialogActions>
                <Button
                    onClick={() => setAlignment('Details')}
                    variant="contained"
                    className="btn-white-outline"
                    type="submit"
                >
                    Cancel
                </Button>
                {searchValue?.brandName || alignment === 'Update' ? (
                    <Button
                        disabled={loading || !(number !== '' && frequency !== '') || !(startDate || start)}
                        onClick={() => OnSubmit()}
                        variant="contained"
                        className="btn-primary"
                    >
                        {!!loading
                            ? 'Processing...'
                            : alignment === 'Add'
                            ? 'Save'
                            : alignment === 'Update'
                            ? 'Update'
                            : 'Save'}
                    </Button>
                ) : null}
            </DialogActions>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.loginData.loginResponse.accessToken,
        patientId: state.patient.patientData.patientId,
        vitalData: state.vitalList.list,
        cardiacMedication: state?.cardiacList?.cardiacData,
        currentMedication: state.cardiacList.currentMedication,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSessionModal: (payload, data) => dispatch(toggleSessionModal(payload, data)),
        toggleDeactivateModal: (payload) => dispatch(toggleDeactivateModal(payload)),
        setAlert: (payload) => dispatch(setAlert(payload)),
        toggleCardiacModal: (payload) => dispatch(toggleCardiacModal(payload)),
        getCardiacListData: (payload, token) => dispatch(getCardiacListData(payload, token)),
        getCardiacData: (payload, token) => dispatch(getCardiacData(payload, token)),
        setCurrentMedication: (payload) => dispatch(setCurrentMedication(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
