import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export const Index = ({ familyListData, familyListLoading, patientId, token }) => {
    const placeHolderHelper = (val) => {
        const palceholderObj = [
            { name: 'Heart Attack', placeholder: 'MI' },
            { name: 'Stroke', placeholder: 'CVA' },
            { name: 'Heart Failure', placeholder: 'HF' },
            { name: 'Cardiac Arrest', placeholder: 'CA' },
            { name: 'Aneurysm', placeholder: 'AA' },
            { name: 'Coronary Artery Disease', placeholder: 'CAD' },
        ]
        return _.find(palceholderObj, ['name', val])?.placeholder ?? 'N/A'
    }
    return (
        <>
            <Grid
                rowSpacing={0.5}
                columnSpacing={2}
                container
                direction="row"
                justifyContent="start"
                alignItems="center"
            >
                {!!familyListLoading ? (
                    <Grid item container direction="row" justifyContent="center" alignItems="center">
                        <img style={{ width: '35px' }} src="/loader.gif" alt="loader" />
                    </Grid>
                ) : _.isEmpty(familyListData) ? (
                    <Grid item container direction="row" justifyContent="center" alignItems="center">
                        <Box>
                            <Typography className="gray-900 fw-normal fs-8 ">
                                No family history found
                            </Typography>
                        </Box>
                    </Grid>
                ) : (
                    familyListData?.map((familyMember, idx) => (
                        <Grid item key={idx}>
                            <Box className="action-pills" sx={{ cursor: 'default !important' }}>
                                {familyMember?.familyHistory[0]?.relative}{' '}
                                {placeHolderHelper(familyMember?.familyHistory[0]?.disease)}{' '}
                                {familyMember?.familyHistory[0]?.diagnosisAge}
                            </Box>
                        </Grid>
                    ))
                )}
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    familyListData: state?.familyHistory?.familyListData,
    familyListLoading: state?.familyHistory?.familyListLoading,
})

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
