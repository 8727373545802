import React from 'react'
import PropTypes from 'prop-types'
import '../../components/interval-history/interval-modal/interval-components/inputs.scss'
import variables from '../../theme/variables.scss'

import { connect, useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import FlowTabs from './internal-popup/FlowTabs'
import FlowTable from './internal-popup/FlowTable'
import Loading from '../Loading'
import { toggleEligibilityCriteriaModal } from '../../redux/modals/toggleModal.actions'
import { clearTrialCriteria } from '../../redux/searchFlow/screenFlow/screenFlow.action'
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        minHeight: '500px',
        minWidth: '500px',
        display: 'flex',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialogTitle-root': {
        background: variables.gray100,
        fontWeight: 600,
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

// const calculateCriteriaNumber = (criteria, type) => {
//     return criteria?.filter((c) => c?.criterion_type === type)?.length
// }
// const filterCriteria = (criteria, type) => {
//     return criteria?.filter((c) => c?.criterion_type === type)
// }

const calculateCriteriaNumber = (criteria, type) => {
    return Array.isArray(criteria) ? criteria?.filter((c) => c?.criterion_type === type).length : 0
}

const filterCriteria = (criteria, type) => {
    return Array.isArray(criteria) ? criteria?.filter((c) => c?.criterion_type === type) : []
}

export const EligibilityCriteriaModal = ({
    eligibilityCriteriaModal,
    eligibilityCriteriaDetails,
    clinicalTrialCriteria,
    clinicalTrialCriteriaLoading,
}) => {
    const [tabValue, setTabValue] = React.useState('inc')
    const dispatch = useDispatch()
    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick') return
        dispatch(toggleEligibilityCriteriaModal(false))
        dispatch(clearTrialCriteria())
    }
    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                maxWidth="lg"
                fullWidth={true}
                open={!!eligibilityCriteriaModal}
                PaperProps={{
                    style: {
                        height: '90%',
                    },
                }}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Box sx={{ minHeight: '2.5rem' }}>
                        <Typography className="fs-7 gray-600 fw-light">Trial Critera</Typography>
                        <Typography className="fs-6 fw-bold text-uppercase h-8">
                            {clinicalTrialCriteria?.trial_name ?? ''}
                        </Typography>
                    </Box>
                </BootstrapDialogTitle>

                <DialogContent
                    dividers
                    sx={{
                        padding: '0 !important',
                        overflow: 'auto',
                    }}
                >
                    <Box
                        sx={{
                            px: { xs: 2, sm: 4 },
                            width: '100%',
                        }}
                    >
                        {clinicalTrialCriteriaLoading ? (
                            <Box className="d-flex flex-column align-items-center justify-content-center p-4 ">
                                <Loading />
                                <Typography className="gray-600 fw-normal fs-7 pb-2">
                                    Fetching Criteria Data...
                                </Typography>
                            </Box>
                        ) : (
                            <>
                                <FlowTabs
                                    tabValue={tabValue}
                                    setTabValue={setTabValue}
                                    incValue={calculateCriteriaNumber(
                                        clinicalTrialCriteria?.criteria,
                                        'inclusion'
                                    )}
                                    excValue={calculateCriteriaNumber(
                                        clinicalTrialCriteria?.criteria,
                                        'exclusion'
                                    )}
                                />
                                <Box sx={{ py: 2 }}>
                                    <FlowTable
                                        inCriteria={filterCriteria(
                                            clinicalTrialCriteria?.criteria,
                                            'inclusion'
                                        )}
                                        exCriteria={filterCriteria(
                                            clinicalTrialCriteria?.criteria,
                                            'exclusion'
                                        )}
                                        tabValue={tabValue}
                                    />
                                </Box>
                            </>
                        )}
                    </Box>
                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        eligibilityCriteriaModal: state?.toggleModals?.eligibilityCriteriaModal,
        eligibilityCriteriaDetails: state.screenFlowPortalClinicalTrial?.eligibilityCriteriaDetails,
        clinicalTrialCriteria: state.screenFlowPortalClinicalTrial?.clinicalTrialCriteria,
        clinicalTrialCriteriaLoading: state.screenFlowPortalClinicalTrial?.clinicalTrialCriteriaLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleEligibilityCriteriaModal: (payload) => dispatch(toggleEligibilityCriteriaModal(payload)),
        clearTrialCriteria: () => dispatch(clearTrialCriteria()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EligibilityCriteriaModal)
