import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { DATE_FORMAT } from '../../../util/dateTimeFormats'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

const PatientInfo = ({ patient, loading, lastUpdatedRecommendations }) => {
    return (
        <>
            <Box
                sx={{
                    display: 'none',
                    displayPrint: 'block',
                    pb: 3,
                }}
            >
                <Typography className="gray-900 fs-5 fw-bold text-center">
                    <u>Consult Letter</u>{' '}
                </Typography>
            </Box>
            <Stack direction="row" justifyContent="stretch" spacing={4}>
                <Stack direction="row" spacing={1}>
                    <Box>
                        <Typography className="gray-600 fs-7 fw-normal">Patient Details</Typography>
                        <Typography
                            className="gray-900 fs-8 fw-bold"
                            sx={{ flexWrap: 'wrap', width: '20rem' }}
                        >
                            {patient?.firstName !== '' ? patient?.firstName : 'N/A'}{' '}
                            {patient?.lastName !== '' ? patient?.lastName : 'N/A'}
                        </Typography>
                        <Stack direction="row" spacing={1}>
                            <Typography className="fs-8 fw-normal gray-600">D.O.B: </Typography>
                            <Typography className="fs-8 fw-normal gray-900 ">
                                {patient?.birthday !== '' ? patient?.birthday : 'N/A'}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography className="fs-8 fw-normal gray-600">Age: </Typography>
                            <Typography className="fs-8 fw-normal gray-900 ">
                                {patient?.age !== '' ? patient?.age : 'N/A'}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography className="fs-8 fw-normal gray-600">Gender: </Typography>
                            <Typography className="fs-8 fw-normal gray-900 ">
                                {patient?.gender !== '' ? (patient?.gender === 1 ? 'Male' : 'Female') : 'N/A'}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography className="fs-8 fw-normal gray-600">Phone Number: </Typography>
                            <Typography className="fs-8 fw-normal gray-900 ">
                                {patient?.phone !== '' ? patient?.phone : 'N/A'}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography className="fs-8 fw-normal gray-600">Address: </Typography>
                            <Typography
                                className="fs-8 fw-normal gray-900"
                                sx={{ flexWrap: 'wrap', width: '16rem' }}
                            >
                                {patient?.address?.street !== '' ? patient?.address?.street : 'N/A'},{' '}
                                {patient?.address?.city !== '' ? patient?.address?.city : 'N/A'},{' '}
                                {patient?.address?.postalZip !== '' ? patient?.address?.postalZip : 'N/A'}
                            </Typography>
                        </Stack>
                    </Box>
                    <Box>
                        <Stack direction="row" spacing={1}>
                            <Typography className="fs-8 fw-normal gray-600" sx={{ whiteSpace: 'nowrap' }}>
                                Primary MD:{' '}
                            </Typography>
                            <Typography
                                className="fs-8 fw-normal gray-900 "
                                sx={{ wordBreak: 'break-word', maxWidth: 'fit-content' }}
                            >
                                {patient?.primaryMd !== '' ? patient?.primaryMd : 'N/A'}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography className="fs-8 fw-normal gray-600" sx={{ whiteSpace: 'nowrap' }}>
                                Referring MD:{' '}
                            </Typography>
                            <Typography
                                className="fs-8 fw-normal gray-900"
                                sx={{ wordBreak: 'break-word', maxWidth: 'fit-content' }}
                            >
                                {patient?.referringMd !== '' ? patient?.referringMd : 'N/A'}
                            </Typography>
                        </Stack>
                    </Box>
                </Stack>
                <Box>
                    <Typography className="fs-8 fw-normal gray-600" sx={{ whiteSpace: 'nowrap' }}>
                        Date of Assessment{' '}
                    </Typography>
                    <Typography className="fs-8 fw-normal gray-900 ">
                        {_.isNil(lastUpdatedRecommendations)
                            ? 'N/A'
                            : moment(lastUpdatedRecommendations).format(DATE_FORMAT.MMM_DD_YYYY)}
                    </Typography>
                </Box>
            </Stack>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        patientProfileModal: state.toggleModals.patientProfileModal,
        patient: state.patient.patientData,
        loading: state.patient.loading,
        lastUpdatedRecommendations: state?.recommendations?.lud,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientInfo)
