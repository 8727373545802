import React from 'react'
import { connect } from 'react-redux'
import variables from '../../../../theme/variables.scss'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import { toggleCardiacModal } from '../../../../redux/modals/toggleModal.actions'
import moment from 'moment'
import _ from 'lodash'
import { DATE_FORMAT } from '../../../../util/dateTimeFormats'
import { getCardiacData } from '../../../../redux/COP/cardiacList/cardiacList.action'

export const Index = ({ medicationdata }) => {
    return (
        <Box sx={{ flexGrow: 1, padding: 1 }}>
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 8, md: 12 }}>
                <Grid item xs={2} sm={4} md={3}>
                    <Box>
                        <Typography className="gray-900 fs-8 fw-bold mt-2">Medication</Typography>
                        <Typography className="gray-600 fs-9 mt-2 text-break">
                            {medicationdata[0]?.name ?? '-'}{' '}
                            {!!medicationdata[0]?.isCombinationDrug ? (
                                <Tooltip
                                    title={
                                        !!medicationdata[0]?.isCombinationDrug
                                            ? 'This is a combination drug.'
                                            : null
                                    }
                                >
                                    <Chip
                                        label={!!medicationdata[0]?.isCombinationDrug ? 'C' : null}
                                        size="small"
                                        sx={{
                                            height: '50%',
                                            borderRadius: '20%',
                                            fontSize: '10px',
                                            fontWeight: 600,
                                            color: variables.white100,
                                            backgroundColor: variables.purple,
                                        }}
                                    />
                                </Tooltip>
                            ) : null}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={2} sm={4} md={3}>
                    <Box>
                        <Typography className="gray-900 fs-8 fw-bold mt-2">Dosage</Typography>
                        <Typography
                            className="gray-600 fs-9 mt-2"
                            sx={{ textTransform: 'uppercase !important' }}
                        >
                            {medicationdata[0]?.dosages[0]?.dosage ?? '-'}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={2} sm={4} md={3}>
                    <Box>
                        <Typography className="gray-900 fs-8 fw-bold mt-2">Frequency</Typography>
                        <Typography
                            className="gray-600 fs-9 mt-2"
                            sx={{ textTransform: 'uppercase !important' }}
                        >
                            {medicationdata[0]?.frequency ?? '-'}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={2} sm={4} md={3}>
                    <Box>
                        <Typography className="gray-900 fs-8 fw-bold mt-2">Class</Typography>
                        <Typography className="gray-600 fs-9 mt-2">
                            {medicationdata[0]?.class ?? '-'}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={2} sm={4} md={3}>
                    <Box>
                        <Typography className="gray-900 fs-8 fw-bold mt-2">Started on</Typography>
                        <Typography className="gray-600 fs-9 mt-2">
                            {_.isNil(medicationdata[0]?.startDate)
                                ? '-'
                                : moment(medicationdata[0]?.startDate).format(DATE_FORMAT.MMM_DD_YYYY)}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={2} sm={4} md={3}>
                    <Box>
                        <Typography className="gray-900 fs-8 fw-bold mt-2">Duration</Typography>
                        <Typography className="gray-600 fs-9 mt-2">
                            {medicationdata[0]?.duration ?? '-'}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={2} sm={4} md={3}>
                    <Box>
                        <Typography className="gray-900 fs-8 fw-bold mt-2">Started By</Typography>
                        <Typography className="gray-600 fs-9 mt-2">
                            {medicationdata[0]?.startByUser ?? '-'}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={2} sm={4} md={3}>
                    <Box>
                        <Typography className="gray-900 fs-8 fw-bold mt-2">Indication</Typography>
                        <Typography
                            className="gray-600 fs-9 mt-2"
                            sx={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {!!medicationdata[0]?.indication
                                ? JSON.parse(medicationdata[0]?.indication)?.join(',')
                                : '-'}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {
        patientId: state.patient.patientData.patientId,
        token: state.loginData.loginResponse.accessToken,
        cardiacModal: state.toggleModals.cardiacModal,
        cardiacData: state.cardiacList.list,
        cardiacLoading: state.cardiacList.cardiacLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleCardiacModal: (payload) => dispatch(toggleCardiacModal(payload)),
        getCardiacData: (payload, token) => dispatch(getCardiacData(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
