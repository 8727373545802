/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from 'react'
import axios from 'axios'
import { connect, useDispatch } from 'react-redux'
import {
    CheckSessionExpiry,
    fetchProfileModal,
    openChangePasswordModal,
    toggleDeactivateModal,
    toggleProfileModal,
    toggleSessionModal,
} from '../../redux/modals/toggleModal.actions'
import PropTypes from 'prop-types'
import { alpha, styled } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import MenuItem from '@mui/material/MenuItem'
import Container from '@mui/material/Container'
import Menu from '@mui/material/Menu'
import SearchIcon from '@mui/icons-material/Search'
import AccountCircle from '@mui/icons-material/AccountCircle'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MoreIcon from '@mui/icons-material/MoreVert'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled'
import Profile from '../profile'
import { fetchAllAppointmentData, fetchAppointmentData } from '../../redux/appointment/appointment.action'
import './app-bar.scss'

import { getTrialListData, getTrialListData_v1 } from '../../redux/searchFlow/screenFlow/screenFlow.action'
// import { Auth } from 'aws-amplify'
// import _ from 'lodash'
import { getUserDisplayName } from '../../util/index'
import { setAlert } from '../../redux/alert/alert.action'

const Listbox = styled('ul')(({ theme }) => ({
    width: '100%',
    margin: 0,
    padding: 0,
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    color: '#374151',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    maxHeight: 200,
    borderRadius: '10px',
    marginTop: '10px',
    border: '1px solid rgba(0,0,0,.25)',
    '& li': {
        padding: '10px',
    },
    '& li[data-focus="true"]': {
        backgroundColor: '#4F46E5',
        color: '#ffffff',
        cursor: 'pointer',
    },
    '& li:active': {
        backgroundColor: '#4F46E5',
        color: '#ffffff',
    },
}))

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '8px',
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '20rem',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        backgroundColor: 'none',
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        '&:focus': {
            border: '1px solid #fff',
            borderRadius: '8px',
        },
        [theme.breakpoints.up('md')]: {
            width: '60ch',
        },
    },
}))

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        borderBottom: 'none',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogTitle-root': {
        background: '#F3F4F6',
        fontWeight: 600,
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export const Index = ({
    login,
    AllAppointmentData,
    profile,
    currentAppointmentDate,
    searchPlaceholder,
    searchType = 'workflow',
    hideSearch = false,
}) => {
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
    const [inputType, setInputType] = React.useState('search')
    const [open, setOpen] = React.useState(false)
    const [value, setValue] = React.useState('')
    const isMenuOpen = Boolean(anchorEl)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

    const [trialValue, setTrialValue] = React.useState('') // eslint-disable-line no-unused-vars
    const [trialInputSearch, setTrialInputSearch] = React.useState('')

    React.useEffect(() => {
        if (searchType === 'workflow') {
            const payload = {
                page: 1,
                pageLimit: 100,
                searchKey: '',
                date: currentAppointmentDate,
            }
            dispatch(fetchAllAppointmentData(payload, login.accessToken))
        }
        // dispatch(fetchProfileModal(true, login.accessToken))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
        id: 'use-autocomplete-demo',
        options: AllAppointmentData,
        getOptionLabel: (option) => option?.name,
    })
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
        handleMobileMenuClose()
    }

    const handleLogout = () => {
        axios
            .post(`${process.env.REACT_APP_SCREENFLOW_BASE_URL}/logout`, '', {
                headers: {
                    accessToken: login.accessToken,
                    Authorization: `Bearer ${login.idToken}`,
                },
            })
            .then((response) => {
                if (response?.status === 200) {
                    localStorage.clear()
                    window.location.replace('/')
                } else if (response?.status === 401) {
                    dispatch(toggleSessionModal(true, response?.message))
                } else if (response.data.status === 403) {
                    dispatch(toggleDeactivateModal(true))
                }
            })
            .catch((err) => {
                console.log(err)
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: `${err?.message}. Please try again`,
                        alertOpen: true,
                    })
                )
            })
    }

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setOpen(false)
    }
    const checkSession = () => {
        // dispatch(CheckSessionExpiry(login.accessToken))
        setOpen(true)
    }
    const handleProfile = () => {
        handleMenuClose()
        // dispatch(fetchProfileModal(true, login.accessToken))
        dispatch(toggleProfileModal(true))
    }
    const handleUpdatePassword = () => {
        handleMenuClose()
        dispatch(openChangePasswordModal(true, login.accessToken))
    }

    const handleSearch = (option, index) => {
        setInputType('text')
        setValue(option.name)
        const payloadData = {
            page: 1,
            pageLimit: 10,
            searchKey: option.name,
            date: currentAppointmentDate,
        }
        dispatch(fetchAppointmentData(payloadData, login.accessToken))
    }

    const handleSearchOnKeys = (e) => {
        if (e.key === 'Enter') {
            setInputType('text')
            setValue(e.target.value)
            const payloadData = {
                page: 1,
                pageLimit: 10,
                searchKey: e.target.value,
                date: currentAppointmentDate,
            }
            dispatch(fetchAppointmentData(payloadData, login.accessToken))
        }
        if (e.target.value === '') {
            if (e.key === 'Backspace') {
                setInputType('search')
                const payloadData = {
                    page: 1,
                    pageLimit: 10,
                    searchKey: '',
                    date: currentAppointmentDate,
                }
                dispatch(fetchAppointmentData(payloadData, login.accessToken))
            }
        }
    }

    const handleTrialSearch = (e) => {
        if (e.key === 'Enter') {
            setInputType('text')
            setTrialValue(e.target.value)
            setTrialInputSearch(e.target.value)
            const payloadData = {
                page: 1,
                pageLimit: 10,
                searchTerm: e.target.value,
            }
            dispatch(getTrialListData_v1(payloadData, login.idToken))
        }
        if (e.target.value === '') {
            if (e.key === 'Backspace') {
                setInputType('search')
                setTrialValue(e.target.value)
                setTrialInputSearch(e.target.value)
                const payloadData = {
                    page: 1,
                    pageLimit: 10,
                    searchKey: '',
                }
                dispatch(getTrialListData_v1(payloadData, login.idToken))
            }
        }
    }

    const menuId = 'primary-search-account-menu'
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 4,
                    ml: 2,
                    width: '215px',
                    fontFamily: 'Inter',
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                },
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            disableScrollLock={true}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleProfile}>
                <img className="me-3" src="/images/profile/user_icon.svg" alt="Profile" /> Profile
            </MenuItem>
            <MenuItem onClick={handleUpdatePassword}>
                <img className="me-3" src="/images/profile/password_icon.svg" alt="Update Password" /> Update
                Password
            </MenuItem>

            <MenuItem
                disabled={true}
                sx={{ cursor: 'not-allowed' }}
                className="cursor-not-allowed"

                //  onClick={handleMenuClose}
            >
                <div style={{ cursor: 'not-allowed !important' }} className="cursor-not-allowed">
                    <img className="me-3" src="/images/profile/tnc_icon.svg" alt="Terms & Conditions" /> Terms
                    & Conditions
                </div>
            </MenuItem>
            <a
                href="https://cardeahealth.io/privacy-policy/"
                target="_blank"
                style={{ textDecoration: 'none', color: 'inherit' }}
                rel="noreferrer"
            >
                <MenuItem onClick={handleMenuClose}>
                    <img className="me-3" src="/images/profile/pp_icon.svg" alt="Privacy Policy" /> Privacy
                    Policy
                </MenuItem>
            </a>
            <a
                href="mailto:flow-support@cardeahealth.io"
                target="_blank"
                style={{ textDecoration: 'none', color: 'inherit' }}
                rel="noreferrer"
            >
                <MenuItem onClick={handleMenuClose}>
                    <img className="me-3" src="/images/profile/email-icon.svg" alt="Contact Us" />
                    Customer Support
                </MenuItem>
            </a>
            <MenuItem style={{ color: 'rgb(111 120 130)' }} onClick={checkSession}>
                <img className="me-3" src="/images/profile/locgout_icon.svg" alt="Logout" /> Logout
            </MenuItem>
        </Menu>
    )

    const mobileMenuId = 'primary-search-account-menu-mobile'
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    )

    return (
        <>
            <Box sx={{ flexGrow: 1, paddingBottom: '75px' }}>
                <AppBar
                    className="main-appbar"
                    position="fixed"
                    // absolute
                >
                    <Container maxWidth="lg">
                        <Toolbar>
                            <a href="/">
                                <img
                                    style={{ width: '30px' }}
                                    src="/images/homepage-logo.svg"
                                    alt="small icon"
                                />
                            </a>
                            {!!hideSearch ? null : (
                                <>
                                    {searchType === 'workflow' ? (
                                        <Search>
                                            <SearchIconWrapper>
                                                <SearchIcon sx={{ color: '#C7D2FE' }} />
                                            </SearchIconWrapper>
                                            <StyledInputBase
                                                {...getInputProps()}
                                                value={value}
                                                onKeyUp={(event, newValue) => {
                                                    handleSearchOnKeys(event)
                                                    setValue(newValue)
                                                }}
                                                placeholder={
                                                    searchPlaceholder ?? 'Search appointments for today'
                                                }
                                                inputProps={{ 'aria-label': inputType, type: inputType }}
                                            />
                                            {getInputProps().value?.length > 2 &&
                                            groupedOptions &&
                                            groupedOptions.length > 0 ? (
                                                <Listbox {...getListboxProps()}>
                                                    {groupedOptions &&
                                                        groupedOptions.map((option, index) => (
                                                            <li
                                                                className="cursor-pointer"
                                                                {...getOptionProps({ option, index })}
                                                                onMouseDown={() => {
                                                                    handleSearch(option)
                                                                }}
                                                            >
                                                                {option?.name}
                                                            </li>
                                                        ))}
                                                </Listbox>
                                            ) : null}
                                        </Search>
                                    ) : searchType === 'screenflow' ? (
                                        <Search>
                                            <SearchIconWrapper>
                                                <SearchIcon sx={{ color: '#C7D2FE' }} />
                                            </SearchIconWrapper>
                                            <StyledInputBase
                                                // {...getInputProps()}
                                                // value={trialValue}
                                                value={trialInputSearch}
                                                onChange={(event) => {
                                                    setTrialInputSearch(event.target.value)
                                                }}
                                                // value={trialValue}
                                                onKeyUp={(event, newValue) => {
                                                    handleTrialSearch(event)
                                                    setTrialValue(newValue)
                                                }}
                                                placeholder={
                                                    searchPlaceholder ?? 'Search appointments for today'
                                                }
                                                inputProps={{ 'aria-label': inputType, type: inputType }}
                                            />
                                        </Search>
                                    ) : null}
                                </>
                            )}
                            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                <p
                                    className="cursor-pointer"
                                    onClick={handleProfileMenuOpen}
                                    style={{ fontSize: '0.875rem', marginBottom: '0px' }}
                                >
                                    {getUserDisplayName(login)}
                                    <KeyboardArrowDownIcon />
                                </p>
                            </Box>
                            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="show more"
                                    aria-controls={mobileMenuId}
                                    aria-haspopup="true"
                                    onClick={handleMobileMenuOpen}
                                    color="inherit"
                                >
                                    <MoreIcon />
                                </IconButton>
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
                {renderMobileMenu}
                {renderMenu}
                <Profile />
            </Box>
            <BootstrapDialog onClose={handleClose} aria-labelledby="small-modal" open={open}>
                <BootstrapDialogTitle id="small-modal" onClose={handleClose}>
                    Logout
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography className="fw-bold fs-5" gutterBottom>
                        Are you sure you want to logout?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        className="btn-white-outline"
                        type="submit"
                    >
                        Cancel
                    </Button>
                    <Button onClick={handleLogout} variant="contained" className="btn-primary ms-4">
                        Logout
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    )
}

const mapStateToProps = (state) => ({
    login: state.loginData.loginResponse,
    profile: state.toggleModals.profileData,
    profileModal: state.toggleModals.profileModal,
    AppointmentData: state.appointment.appointmentData.list,
    AllAppointmentData: state.appointment.AllAppointmentData.list,
    loading: state.appointment.loading,
    maxData: state.appointment.appointmentData.resultCount,
    currentAppointmentDate: state.appointment.currentAppointmentDate,
})

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProfileModal: (payload, token) => dispatch(fetchProfileModal(payload, token)),
        toggleProfileModal: (payload) => dispatch(toggleProfileModal(payload)),
        toggleSessionModal: (payload, data) => dispatch(toggleSessionModal(payload, data)),
        openChangePasswordModal: (payload, token) => dispatch(openChangePasswordModal(payload, token)),
        fetchAllAppointmentData: (payload, token) => dispatch(fetchAllAppointmentData(payload, token)),
        fetchAppointmentData: (payload, token) => dispatch(fetchAppointmentData(payload, token)),
        toggleDeactivateModal: (payload) => dispatch(toggleDeactivateModal(payload)),
        CheckSessionExpiry: (payload) => dispatch(CheckSessionExpiry(payload)),

        /* trial */
        getTrialListData: (payload, token) => dispatch(getTrialListData(payload, token)),
        getTrialListData_v1: (payload, token) => dispatch(getTrialListData_v1(payload, token)),
        /* trial */
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
