import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import { getEcgsData } from '../../../../redux/ECH/ecgList/ecgList.action'
import { setCurrentEcg } from '../../../../redux/ECH/ecg/ecg.action'
import { MEDICATION_TYPE } from '../../../../util'
import { DATE_FORMAT } from '../../../../util/dateTimeFormats'

export const Index = ({
    cardiacData,
    cardiacLoading,
    patientId,
    token,
    name,
    time,
    currentMedication,
    otherData,
    otherLoading,
    pastData,
    pastLoading,
}) => {
    const medicationSwitchHelper = (val, isDataSwitch) => {
        const CARDIAC_VALUE = isDataSwitch ? cardiacData : cardiacLoading
        const OTHER_VALUE = isDataSwitch ? otherData : otherLoading
        const PAST_VALUE = isDataSwitch ? pastData : pastLoading
        const helperValues = {
            [MEDICATION_TYPE.CARDIAC]: CARDIAC_VALUE,
            [MEDICATION_TYPE.OTHER]: OTHER_VALUE,
            [MEDICATION_TYPE.PAST]: PAST_VALUE,
        }
        return helperValues[val] || CARDIAC_VALUE
    }

    return (
        <>
            <Grid
                // spacing={2}
                rowSpacing={0.5}
                columnSpacing={2}
                container
                direction="row"
                justifyContent="start"
                alignItems="center"
                overflow="auto"
            >
                {!!medicationSwitchHelper(currentMedication, false) ? (
                    <Grid item container direction="row" justifyContent="center" alignItems="center">
                        <img style={{ width: '35px' }} src="/loader.gif" alt="loader" />
                    </Grid>
                ) : _.isEmpty(medicationSwitchHelper(currentMedication, true)) ? (
                    <Grid item container direction="row" justifyContent="center" alignItems="center">
                        <Box>
                            <Typography className="gray-900 fw-normal fs-8 ">No medications found</Typography>
                        </Box>
                    </Grid>
                ) : (
                    medicationSwitchHelper(currentMedication, true)?.map((med, idx) => (
                        <Grid item key={idx}>
                            <Tooltip
                                title={
                                    !_.isEmpty(med?.startDate)
                                        ? moment(med?.startDate).format(DATE_FORMAT.MMM_DD_YYYY)
                                        : 'N/A'
                                }
                            >
                                <Box
                                    className="action-pills"
                                    sx={{
                                        cursor: 'default !important',
                                        textTransform: 'uppercase !important',
                                    }}
                                >
                                    {med?.name ?? 'N/A'} {(med?.strength || med?.dosage) ?? 'N/A'}
                                </Box>
                            </Tooltip>
                        </Grid>
                    ))
                )}
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    cardiacData: state.cardiacList.list,
    cardiacLoading: state.cardiacList.cardiacLoading,
    otherData: state.cardiacList.otherData,
    otherLoading: state.cardiacList.otherLoading,
    pastData: state.cardiacList.pastData,
    pastLoading: state.cardiacList.pastLoading,
    currentMedication: state.cardiacList.currentMedication,
})

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentEcg: (payload) => dispatch(setCurrentEcg(payload)),
        getEcgsData: (payload, token) => dispatch(getEcgsData(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
