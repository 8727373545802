import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import 'chartjs-adapter-moment'
import variables from '../../../../theme/variables.scss'
import { DATE_FORMAT } from '../../../../util/dateTimeFormats'
import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import moment from 'moment'
import LoadingChart from '../../../labs-component/labs-charts/loading-chart'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler)

export const Index = ({
    cardiacMedicationName,
    cardiacMedicationDosages,
    cardiacMedLoading,
    measurementLoading,
    measurementName,
    measurementData,
    type,
    name,
}) => {
    const [state, setState] = useState([])
    const [measurementState, setMeasurementState] = useState([]) // eslint-disable-line no-unused-vars
    const [labels, setlabels] = useState([])
    const [measurementLabels, setMeasurementlabels] = useState([])
    const [currentData, setCurrentData] = useState([])
    const [currentDate, setCurrentDate] = useState([]) // eslint-disable-line no-unused-vars
    const [currentMeasurementData, setCurrentMeasurementData] = useState([]) // eslint-disable-line no-unused-vars
    const [currentMeasurementDate, setCurrentMeasurementDate] = useState([]) // eslint-disable-line no-unused-vars
    const [doseData, setDoseData] = useState([])
    const [effectData, setEffectData] = useState([])

    function capitalizeFirstLetter(string) {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1)
    }

    /*  dosages data */

    useEffect(() => {
        if (cardiacMedicationDosages?.length > 0) {
            const arrData = cardiacMedicationDosages?.map((data, index) => {
                return data?.dosage?.split(' ')[0]
            })
            const new_Label = cardiacMedicationDosages?.map((data, index) => {
                return moment.parseZone(data?.startDate).format(DATE_FORMAT.MMM_DD_YYYY)
            })

            const arrDoseData = cardiacMedicationDosages?.map((data, index) => {
                return {
                    x: moment.parseZone(data?.startDate).format(DATE_FORMAT.MMM_DD_YYYY),

                    y: parseFloat(data?.dosage?.split(' ')[0]),
                }
            })

            setDoseData(arrDoseData.reverse())
            setlabels(new_Label.reverse())
            setState(arrData.reverse())
            setCurrentData(arrData?.length > 0 && arrData[arrData?.length - 1])
            setCurrentDate(new_Label?.length > 0 && new_Label[new_Label?.length - 1])
        } else {
            setlabels([])
            setState([])
            setCurrentData([])
            setCurrentDate([])
            setDoseData([])
        }
    }, [cardiacMedicationDosages, type])
    /*  */

    /* Exp-measurement data */
    useEffect(() => {
        if (measurementData[0]?.module?.length > 0) {
            const arrData = measurementData[0]?.module?.map((data, index) => {
                return data?.value
            })
            const new_Label = measurementData[0]?.module.map((data, index) => {
                return moment.parseZone(data?.date).format(DATE_FORMAT.MMM_DD_YYYY)
            })

            const arrEffectData = measurementData[0]?.module?.map((data, index) => {
                return {
                    x: moment.parseZone(data?.date).format(DATE_FORMAT.MMM_DD_YYYY),
                    y: data?.value,
                }
            })
            setEffectData(arrEffectData.reverse())
            setMeasurementlabels(new_Label)
            setMeasurementState(arrData.reverse())
            setCurrentMeasurementData(arrData?.length > 0 && arrData[arrData?.length - 1])
            setCurrentMeasurementDate(new_Label?.length > 0 && new_Label[new_Label?.length - 1])
        } else {
            setMeasurementlabels([])
            setMeasurementState([])
            setCurrentMeasurementData([])
            setCurrentMeasurementDate([])
            setEffectData([])
        }
    }, [measurementData, type])
    /*  */

    const getParsedValue = (ctx, val1, val2) => {
        let index = ctx.dataIndex
        let value = ctx.dataset.data[index]
        return value === currentData && index === state.length - 1 ? val1 : val2
    }

    const options = {
        responsive: true,
        borderColor: variables.purple,
        backgroundColor: variables.purple,
        tension: 0.4,
        layout: {
            padding: 2,
        },
        onClick: function (evt, element) {
            if (element.length > 0) {
                var ind = element[0].index
                const value = data.datasets[0].data[ind]
                const label = data.labels[ind]
                setCurrentData(value)
                setCurrentDate(label)
            }
        },
        scales: {
            xAxes: {
                display: true, //this will show all the x-axis grid lines,
                type: 'time',
                time: {
                    displayFormats: {
                        year: type === 'desktop' ? 'MMM YYYY' : `YY'`,
                    },
                    unit: type === 'desktop' ? 'month' : 'year',
                },
                suggestedMax: Date.now(),
                suggestedMin: moment(Date.now()).subtract(5, 'years'),
                grid: {
                    drawBorder: true,
                    display: true,
                    color: variables.gray100,
                    borderColor: variables.gray100,
                },
                ticks: {
                    beginAtZero: true,
                    color: variables.gray600,
                    font: {
                        size: type === 'desktop' ? 12 : 9,
                        weight: 400,
                    },
                    minRotation: 20,
                },
                beginAtZero: true,
            },
            yAxes: {
                display: type === 'desktop' ? true : false,
                grid: {
                    drawBorder: true,
                    display: true,
                    color: variables.gray100,
                    borderColor: variables.gray100,
                },
                ticks: {
                    beginAtZero: true,
                    color: '#000',
                    font: {
                        size: type === 'desktop' ? 12 : 9,
                        weight: 400,
                    },
                },
                afterDataLimits(scale) {
                    if (type === 'desktop') {
                        scale.max += 1
                        scale.min -= 0
                    } else {
                        scale.max += 0.5
                        scale.min -= 0.5
                    }
                },
                beginAtZero: true,
                position: 'left',
                type: 'linear',
                title: {
                    display: true,
                    // align: 'start',
                    text: 'MG',
                    padding: 1,
                    font: {
                        size: 12,
                        weight: 700,
                    },
                },
            },
            y1: {
                display: type === 'desktop' ? true : false,
                grid: {
                    color: variables.gray100,
                    borderColor: variables.gray100,
                    drawOnChartArea: false,
                },
                ticks: {
                    beginAtZero: true,
                    color: '#000',
                    font: {
                        size: type === 'desktop' ? 12 : 9,
                        weight: 400,
                    },
                },
                beginAtZero: true,
                position: 'right',
                type: 'linear',
                afterDataLimits(scale) {
                    if (type === 'desktop') {
                        scale.max += 1
                        scale.min -= 0
                    } else {
                        scale.max += 0.5
                        scale.min -= 0.5
                    }
                },
                title: {
                    display: true,
                    text: `${
                        !!measurementName
                            ? ['bmi', 'bsa', 'bp', 'hr'].includes(measurementName[0]?.name)
                                ? measurementName[0]?.name?.toUpperCase() ?? ''
                                : ['height', 'currentWeight', 'dryWeight', 'diffWeight'].includes(
                                      measurementName[0]?.name ?? ''
                                  )
                                ? capitalizeFirstLetter(measurementName[0]?.name)
                                : measurementName[0]?.name ?? ''
                            : ''
                    } ${!!measurementName ? measurementName[0]?.type ?? '' : ''}`,
                    padding: 1,
                    font: {
                        size: 12,
                        weight: 700,
                    },
                },
            },
        },
        plugins: {
            title: {
                display: false,
                text: 'Dose-effect Analysis',
            },
            tooltip: {
                displayColors: false,
                padding: 8,
                backgroundColor: variables.gray700,
                cornerRadius: 5,
                bodySpacing: 10,
                titleAlign: 'center',
                callbacks: {
                    label: function (ctx) {
                        const data = moment(ctx.label).format(DATE_FORMAT.MMM_DD_YYYY)
                        return data === 'Invalid date' ? '0' : data
                    },
                    title: function (ctx) {
                        const data = ctx[0]?.formattedValue
                        return data
                    },
                },
            },
            legend: {
                position: 'top',
                align: 'center',
                display: true,
                // padding: 10,
                labels: { pointStyle: 'circle', usePointStyle: true },
            },
        },
    }

    const sortDateArrayHandler = (arr1, arr2) => {
        const combinedArray = arr1.concat(arr2)
        const sortedArray = combinedArray.sort((a, b) => new Date(a) - new Date(b)) // Sort the combined array in ascending order
        return sortedArray
    }

    const data = {
        labels: sortDateArrayHandler(labels, measurementLabels),
        datasets: [
            {
                label: cardiacMedicationName,
                data: doseData,
                pointBackgroundColor:
                    type === 'desktop'
                        ? (ctx) => getParsedValue(ctx, variables.white100, variables.purple100)
                        : variables.purple100,
                hoverRadius: type === 'desktop' ? 12 : 8,
                pointRadius:
                    type === 'desktop'
                        ? (ctx) => getParsedValue(ctx, 12, 6)
                        : (ctx) => getParsedValue(ctx, 6, 3),
                pointBorderWidth: type === 'desktop' ? 1 : (ctx) => getParsedValue(ctx, 4, 1),
                pointHoverBackgroundColor: variables.white100,
                pointHoverBorderColor: type === 'desktop' ? variables.purple200 : variables.purple,
                pointBorderColor:
                    type === 'desktop'
                        ? (ctx) => getParsedValue(ctx, variables.purple200, variables.purple)
                        : variables.purple,
                pointHoverBorderWidth: type === 'desktop' ? 1 : 4,
                fill: true,
                stepped: true,
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx
                    const gradient = ctx.createLinearGradient(0, 0, 0, type === 'desktop' ? 300 : 100)
                    gradient.addColorStop(0, variables.purple500)
                    gradient.addColorStop(1, variables.whiteTransparent)
                    return gradient
                },
                yAxisID: 'yAxes',
                showLine: true,
            },
            //second measurement plot
            {
                label: ` ${
                    !!measurementName
                        ? ['bmi', 'bsa', 'bp', 'hr'].includes(measurementName[0]?.name)
                            ? measurementName[0]?.name?.toUpperCase() ?? 'Measurement'
                            : ['height', 'currentWeight', 'dryWeight', 'diffWeight'].includes(
                                  measurementName[0]?.name
                              )
                            ? capitalizeFirstLetter(measurementName[0]?.name)
                            : measurementName[0]?.name ?? 'Measurement'
                        : 'Measurement'
                }${!!measurementName ? measurementName[0]?.type ?? '' : ''}`,
                data: effectData,

                pointBackgroundColor:
                    type === 'desktop'
                        ? (ctx) => getParsedValue(ctx, variables.white100, variables.greenTransparent)
                        : variables.green500,
                hoverRadius: type === 'desktop' ? 12 : 8,
                pointRadius:
                    type === 'desktop'
                        ? (ctx) => getParsedValue(ctx, 12, 6)
                        : (ctx) => getParsedValue(ctx, 6, 3),
                pointBorderWidth: type === 'desktop' ? 1 : (ctx) => getParsedValue(ctx, 4, 1),
                pointHoverBackgroundColor: variables.white100,
                pointHoverBorderColor: type === 'desktop' ? variables.green500 : variables.green500,
                pointBorderColor:
                    type === 'desktop'
                        ? (ctx) => getParsedValue(ctx, variables.green500, variables.green500)
                        : variables.green500,
                pointHoverBorderWidth: type === 'desktop' ? 1 : 4,
                fill: true,
                // stepped: true,
                borderColor: variables.green500,
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx
                    const gradient = ctx.createLinearGradient(0, 0, 0, type === 'desktop' ? 300 : 100)
                    gradient.addColorStop(0, variables.green500)
                    gradient.addColorStop(1, variables.whiteTransparent)
                    return gradient
                },
                yAxisID: 'y1',
                showLine: true,
            },
        ],
    }
    return (
        <div>
            <div style={{ border: '1px solid ', borderColor: variables.gray200, borderRadius: '5px' }}>
                {cardiacMedLoading === true || measurementLoading === true ? (
                    <LoadingChart type={type} />
                ) : (
                    <div className={type === 'desktop' ? 'p-4' : 'pe-2 pb-2'}>
                        <Line options={options} data={data} />
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    cardiacMedicationName: state.cardiacList.cardiacData[0]?.name,
    cardiacMedicationDosages: state.cardiacList.cardiacData[0]?.dosages,
    cardiacMedLoading: state?.cardiacList?.cardiacMedLoading,
    measurementName: state?.cardiacList?.measurementData[0]?.module,
    measurementLoading: state?.cardiacList?.measurementLoading,
    measurementData: state?.cardiacList?.measurementData,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
