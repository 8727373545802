import React from 'react'
import { connect, useDispatch } from 'react-redux'
import variables from '../../../theme/variables.scss'
import _ from 'lodash'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import LinearProgress from '@mui/material/LinearProgress'
import moment from 'moment'
import AddIcon from '@mui/icons-material/Add'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import Chip from '@mui/material/Chip'
import {
    getCardiacHistoryListData,
    getPastCardiacHistoryListData,
    setCurrentCardiacHistory,
    setCurrentSubDiagnosis,
    setCardiacHistoryModalType,
} from '../../../redux/cardiacHistory/cardiacHistory.action'
import { toggleCardiacHistoryModal } from '../../../redux/modals/toggleModal.actions'

export const Index = ({
    patientId,
    token,
    detail,
    currentCardiacHistory,
    cardiacHistoryListData,
    cardiacHistoryListLoading,
    consultLetterFlag = false,
    pastCardiacHistoryListData,
    pastCardiacHistoryListLoading,
}) => {
    const dispatch = useDispatch()
    const [value, setValue] = React.useState(currentCardiacHistory ?? 'cardiac') // eslint-disable-line no-unused-vars

    const [expand, setExpand] = React.useState(false)
    const modifiedCardiacHistoryListData =
        !!expand || !!consultLetterFlag ? cardiacHistoryListData : cardiacHistoryListData.slice(0, 3)
    const modifiedPastCardiacHistoryListData =
        !!expand || !!consultLetterFlag ? pastCardiacHistoryListData : pastCardiacHistoryListData.slice(0, 3)

    const OpenModal = (subDiagnosis, val) => {
        switch (val) {
            case 'cardiac':
                const payload0 = {
                    patientId: patientId,
                    type: 'cardiac',
                    status: 'active',
                }
                dispatch(getCardiacHistoryListData(payload0, token))
                dispatch(toggleCardiacHistoryModal(true))
                dispatch(setCurrentCardiacHistory('cardiac'))
                dispatch(setCurrentSubDiagnosis(subDiagnosis))
                dispatch(setCardiacHistoryModalType('Details'))
                break
            case 'past':
                const payload1 = {
                    patientId: patientId,
                    type: 'cardiac',
                    status: 'past',
                }
                dispatch(getPastCardiacHistoryListData(payload1, token))
                dispatch(toggleCardiacHistoryModal(true))
                dispatch(setCurrentCardiacHistory('past'))
                dispatch(setCurrentSubDiagnosis(subDiagnosis))
                dispatch(setCardiacHistoryModalType('Details'))
                break

            case 'addNew':
                dispatch(toggleCardiacHistoryModal(true))
                dispatch(setCurrentCardiacHistory('cardiac'))
                dispatch(setCardiacHistoryModalType('Add'))
                break

            default:
                dispatch(toggleCardiacHistoryModal(true))
        }
    }

    const progressValueHandler = (riskClass, val) => {
        // more risk classes to be added
        switch (riskClass) {
            case 'CCS':
                return val * 25
            case 'NYHA':
                return val * 25
            case 'CHADS2':
                return Math.floor(val * 16.67)
            default:
                return val * 25
        }
    }
    return (
        <>
            <Grid rowSpacing={0.1} columnSpacing={0.1} container justifyContent="flex-start">
                {!!(currentCardiacHistory === 'cardiac' || !!consultLetterFlag
                    ? cardiacHistoryListLoading
                    : pastCardiacHistoryListLoading) ? (
                    <Grid item container direction="row" justifyContent="center" alignItems="center">
                        <Box className="p-1 mt-1">
                            <img style={{ width: '35px' }} src="/loader.gif" alt="loader" />
                        </Box>
                    </Grid>
                ) : _.isEmpty(
                      currentCardiacHistory === 'cardiac' || !!consultLetterFlag
                          ? cardiacHistoryListData
                          : pastCardiacHistoryListData
                  ) ? (
                    <Grid
                        item
                        container
                        direction="row"
                        justifyContent={consultLetterFlag ? 'flex-start' : 'center'}
                        alignItems={'center'}
                    >
                        <Box>
                            <Typography
                                className={
                                    !!consultLetterFlag
                                        ? 'gray-900 fw-light fs-9'
                                        : 'gray-900 fw-normal fs-8 py-2'
                                }
                            >
                                {`No ${
                                    detail === 'cardiac' || !!consultLetterFlag ? 'Active' : 'Past'
                                } Cardiac History found`}
                            </Typography>

                            {!consultLetterFlag && detail === 'cardiac' ? (
                                <Button
                                    size="small"
                                    onClick={() => {
                                        OpenModal(null, 'addNew')
                                    }}
                                    variant="contained"
                                    className="btn-secondary"
                                    fullWidth={true}
                                    startIcon={<AddIcon />}
                                >
                                    click here to add
                                </Button>
                            ) : null}
                        </Box>
                    </Grid>
                ) : (
                    <>
                        {(currentCardiacHistory === 'cardiac' || !!consultLetterFlag
                            ? modifiedCardiacHistoryListData
                            : modifiedPastCardiacHistoryListData
                        )?.map((diagnosisDetail, idx) => (
                            <>
                                {diagnosisDetail?.displayedDiagnosisCreateSource === 'flow' ? (
                                    <Grid
                                        item
                                        xs={12}
                                        key={idx}
                                        alignItems="center"
                                        sx={{
                                            background: variables.gray100,
                                            boxShadow: 'none',
                                            borderRadius: '0.25rem',
                                            padding: '0px 2px 0px !important',
                                            mt: 1,
                                            width: '100%',
                                            mx: '2px',
                                        }}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={1}
                                            columns={{ xs: 3, sm: 6, md: 12 }}
                                            sx={{
                                                padding: '8px 2px 2px !important',
                                                width: '100%',
                                                mx: '2px',
                                            }}
                                        >
                                            <Grid item xs={2} sm={4} md={7} alignItems="center">
                                                <Box sx={{ width: '100%' }}>
                                                    <Stack
                                                        spacing={1}
                                                        sx={{
                                                            maxWidth: !consultLetterFlag
                                                                ? '18rem !important'
                                                                : '90% !important',
                                                            whiteSpace: !consultLetterFlag
                                                                ? 'nowrap'
                                                                : 'normal',
                                                        }}
                                                    >
                                                        <Tooltip
                                                            title={
                                                                !consultLetterFlag &&
                                                                diagnosisDetail?.name?.length > 32 ? (
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: '11px',
                                                                            textTransform: 'capitalize',
                                                                        }}
                                                                    >
                                                                        {diagnosisDetail?.name}
                                                                    </Typography>
                                                                ) : null
                                                            }
                                                        >
                                                            <Typography
                                                                className="cursor-default gray-900 fs-8 fw-normal"
                                                                sx={{
                                                                    textOverflow: 'ellipsis',
                                                                    overflow: 'clip !important',
                                                                    textTransform: 'capitalize',
                                                                }}
                                                            >
                                                                {!!diagnosisDetail?.customLabel
                                                                    ? diagnosisDetail?.customLabel ?? '-'
                                                                    : diagnosisDetail?.name ?? '-'}
                                                            </Typography>
                                                        </Tooltip>
                                                    </Stack>

                                                    <Stack
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        spacing={1}
                                                        sx={{
                                                            whiteSpace: !consultLetterFlag
                                                                ? 'nowrap'
                                                                : 'normal',
                                                        }}
                                                    >
                                                        <Tooltip
                                                            title={
                                                                !consultLetterFlag &&
                                                                diagnosisDetail?.subDiagnosis?.length > 16
                                                                    ? diagnosisDetail?.subDiagnosis
                                                                    : null
                                                            }
                                                        >
                                                            <Typography
                                                                className="text-capitalize cursor-default gray-900 fs-9"
                                                                sx={{
                                                                    fontWeight: 400,
                                                                    width: !!consultLetterFlag
                                                                        ? '7rem !important'
                                                                        : '5.625rem !important',
                                                                    textAlign: 'left',
                                                                    hyphens: 'auto !important',
                                                                    textOverflow: 'ellipsis',
                                                                    overflow: 'clip !important',
                                                                }}
                                                            >
                                                                {diagnosisDetail?.subDiagnosis ?? '-'}
                                                            </Typography>
                                                        </Tooltip>
                                                        <Typography
                                                            className="cursor-default gray-900 fs-9"
                                                            sx={{
                                                                fontWeight: 400,
                                                                width: '3.15rem !important',
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            {!!diagnosisDetail?.diagnoseDate
                                                                ? `Dx ${moment
                                                                      .utc(diagnosisDetail?.diagnoseDate)
                                                                      .year()}`
                                                                : '-'}
                                                        </Typography>
                                                        <Tooltip
                                                            title={
                                                                !consultLetterFlag &&
                                                                diagnosisDetail?.treatment?.length > 8
                                                                    ? diagnosisDetail?.treatment
                                                                    : null
                                                            }
                                                        >
                                                            <Typography
                                                                className="text-capitalize cursor-default gray-900 fs-9"
                                                                sx={{
                                                                    fontWeight: 400,
                                                                    width: '4.25rem !important',
                                                                    textAlign: 'right',
                                                                    textOverflow: 'ellipsis',
                                                                    overflow: 'clip !important',
                                                                }}
                                                            >
                                                                {diagnosisDetail?.treatment ?? '-'}
                                                            </Typography>
                                                        </Tooltip>
                                                    </Stack>
                                                </Box>
                                            </Grid>

                                            <Divider
                                                sx={{ m: 1, background: 'black' }}
                                                orientation="vertical"
                                                flexItem
                                            />

                                            <Grid item xs={2} sm={2} md={4}>
                                                <Stack
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={2}
                                                    sx={{ width: '100%' }}
                                                >
                                                    <Stack
                                                        sx={{ width: '100%' }}
                                                        direction="column"
                                                        justifyContent="flex-start"
                                                        alignItems="center"
                                                        spacing={1}
                                                    >
                                                        <LinearProgress
                                                            sx={{
                                                                width: '100%',
                                                                height: 14,
                                                                borderRadius: '6px',
                                                                background: variables.white100,
                                                                border: '1px solid',
                                                                borderColor: variables.gray200,
                                                                '& .MuiLinearProgress-bar1Determinate': {
                                                                    background:
                                                                        'linear-gradient(45deg, #FFFFFF 5.7%, #B4B0FE 115.71%, #FFFFFF 10.18%)',
                                                                },
                                                            }}
                                                            variant="determinate"
                                                            value={
                                                                _.isFinite(
                                                                    diagnosisDetail?.cardiacState?.value
                                                                )
                                                                    ? progressValueHandler(
                                                                          diagnosisDetail?.cardiacState?.name,
                                                                          diagnosisDetail?.cardiacState?.value
                                                                      )
                                                                    : 0
                                                            }
                                                        />
                                                        <Typography className=" cursor-default gray-900 fs-8 fw-normal text-center ">
                                                            {diagnosisDetail?.cardiacState?.name ?? '-'}{' '}
                                                            {diagnosisDetail?.cardiacState?.value ?? '-'}
                                                        </Typography>
                                                    </Stack>
                                                    {!consultLetterFlag ? (
                                                        <img
                                                            className="cursor-pointer"
                                                            onClick={(event) => {
                                                                OpenModal(diagnosisDetail?.name, value)
                                                                event.stopPropagation()
                                                            }}
                                                            src="/images/right-top-arrow.svg"
                                                            alt="go to icon"
                                                        />
                                                    ) : null}
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid
                                        item
                                        xs={12}
                                        key={idx}
                                        alignItems="center"
                                        sx={{
                                            background: variables.gray100,
                                            boxShadow: 'none',
                                            borderRadius: '0.25rem',
                                            padding: '0px 2px 0px !important',
                                            mt: 1,
                                            width: '100%',
                                            mx: '2px',
                                        }}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={1}
                                            columns={{ xs: 3, sm: 6, md: 12 }}
                                            sx={{
                                                padding: '8px 2px 2px !important',
                                                width: '100%',
                                                mx: '2px',
                                            }}
                                        >
                                            <Grid item xs={2} sm={4} md={7} alignItems="center">
                                                <Box sx={{ width: '100%' }}>
                                                    <Stack
                                                        direction="row"
                                                        spacing={1}
                                                        alignItems={'center'}
                                                        sx={{
                                                            maxWidth: !consultLetterFlag
                                                                ? '18rem !important'
                                                                : '90% !important',
                                                            whiteSpace: !consultLetterFlag
                                                                ? 'nowrap'
                                                                : 'normal',
                                                        }}
                                                    >
                                                        <Tooltip
                                                            title={
                                                                !consultLetterFlag &&
                                                                diagnosisDetail?.name?.length > 32 ? (
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: '11px',
                                                                            textTransform: 'capitalize',
                                                                        }}
                                                                    >
                                                                        {diagnosisDetail?.name}
                                                                    </Typography>
                                                                ) : null
                                                            }
                                                        >
                                                            <Typography
                                                                className="cursor-default gray-900 fs-8 fw-normal"
                                                                sx={{
                                                                    textOverflow: 'ellipsis',
                                                                    overflow: 'clip !important',
                                                                    textTransform: 'capitalize',
                                                                }}
                                                            >
                                                                {!!diagnosisDetail?.customLabel
                                                                    ? diagnosisDetail?.customLabel ?? '-'
                                                                    : diagnosisDetail?.name ?? '-'}
                                                            </Typography>
                                                        </Tooltip>

                                                        <Tooltip
                                                            title={
                                                                !consultLetterFlag &&
                                                                diagnosisDetail?.displayedDiagnosisCreateSource ===
                                                                    'generatedletters' ? (
                                                                    <Box
                                                                        sx={{
                                                                            whiteSpace: 'pre-line',
                                                                        }}
                                                                    >
                                                                        This diagnosis is from a generated
                                                                        letter.
                                                                        {!!diagnosisDetail?.rawText
                                                                            ? `\n Cardiac History from the letter :- 
                                                                    ${diagnosisDetail?.rawText}`
                                                                            : ''}
                                                                    </Box>
                                                                ) : null
                                                            }
                                                        >
                                                            <Chip
                                                                label={
                                                                    diagnosisDetail?.displayedDiagnosisCreateSource ===
                                                                    'generatedletters'
                                                                        ? 'G'
                                                                        : 'Consult Note'
                                                                }
                                                                size="small"
                                                                sx={{
                                                                    height: '50%',
                                                                    borderRadius: '20%',
                                                                    fontSize: '10px',
                                                                    fontWeight: 600,
                                                                    color: variables.white100,
                                                                    backgroundColor: variables.purple,
                                                                    cursor: 'pointer',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </Stack>
                                                </Box>
                                            </Grid>

                                            <Divider
                                                sx={{ m: 1, background: 'black', visibility: 'hidden' }}
                                                orientation="vertical"
                                                flexItem
                                            />

                                            <Grid item xs={2} sm={2} md={4}>
                                                <Stack
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={2}
                                                    sx={{ width: '100%' }}
                                                >
                                                    <Stack
                                                        sx={{ width: '100%' }}
                                                        direction="column"
                                                        justifyContent="flex-start"
                                                        alignItems="center"
                                                        spacing={1}
                                                    >
                                                        <Tooltip
                                                            title={
                                                                !!diagnosisDetail?.diagnoseDateRaw
                                                                    ? `Date (as appearing on the letter) : ${diagnosisDetail?.diagnoseDateRaw}`
                                                                    : null
                                                            }
                                                        >
                                                            <Typography
                                                                className="cursor-default gray-900 fs-9"
                                                                sx={{
                                                                    fontWeight: 400,
                                                                    justify: 'center',
                                                                }}
                                                            >
                                                                Dx{' '}
                                                                {!!diagnosisDetail?.diagnoseYear
                                                                    ? diagnosisDetail?.diagnoseYear
                                                                    : diagnosisDetail?.diagnoseDateRaw}
                                                            </Typography>
                                                        </Tooltip>
                                                    </Stack>
                                                    {!consultLetterFlag ? (
                                                        <img
                                                            className="cursor-pointer"
                                                            onClick={(event) => {
                                                                OpenModal(diagnosisDetail?.name, value)
                                                                event.stopPropagation()
                                                            }}
                                                            src="/images/right-top-arrow.svg"
                                                            alt="go to icon"
                                                        />
                                                    ) : null}
                                                </Stack>
                                            </Grid>

                                            <Typography
                                                className="text-capitalize cursor-default gray-900 fs-9"
                                                sx={{
                                                    fontWeight: 400,
                                                    width: '100%',
                                                    position: 'relative',
                                                    pl: 1,
                                                    pr: 0.5,
                                                    textAlign: 'justify',
                                                }}
                                            >
                                                {diagnosisDetail?.subDiagnosis}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </>
                        ))}
                        {!!consultLetterFlag ||
                        !!(
                            !!(cardiacHistoryListData.length <= 3 && currentCardiacHistory === 'cardiac') ||
                            !!(pastCardiacHistoryListData.length <= 3 && currentCardiacHistory !== 'cardiac')
                        ) ? null : (
                            <Box sx={{ width: '100%', mt: 1 }}>
                                <Button
                                    onClick={() => setExpand((expand) => !expand)}
                                    size="small"
                                    variant="contained"
                                    className="btn-white-outline"
                                    fullWidth={true}
                                    startIcon={!!expand ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
                                >
                                    {!!expand ? 'Collapse Cardiac History' : 'Expand Cardiac History'}
                                </Button>
                            </Box>
                        )}
                    </>
                )}
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    currentCardiacHistory: state?.cardiacHistory?.currentCardiacHistory,
    cardiacHistoryListData: state?.cardiacHistory?.cardiacHistoryListData,
    cardiacHistoryListLoading: state?.cardiacHistory?.cardiacHistoryListLoading,
    pastCardiacHistoryListData: state?.cardiacHistory?.pastCardiacHistoryListData,
    pastCardiacHistoryListLoading: state?.cardiacHistory?.pastCardiacHistoryListLoading,
})

const mapDispatchToProps = (dispatch) => {
    return {
        toggleCardiacHistoryModal: (payload) => dispatch(toggleCardiacHistoryModal(payload)),
        getCardiacHistoryListData: (payload, token) => dispatch(getCardiacHistoryListData(payload, token)),
        setCurrentCardiacHistory: (payload) => dispatch(setCurrentCardiacHistory(payload)),
        setCurrentSubDiagnosis: (payload) => dispatch(setCurrentSubDiagnosis(payload)),
        getPastCardiacHistoryListData: (payload, token) =>
            dispatch(getPastCardiacHistoryListData(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
