import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import clsx from 'clsx'
import Grid from '@mui/material/Grid'
import moment from 'moment'
import { getGxtData } from '../../../redux/ECH/gxtList/gxtList.action'
import { setCurrentGtx } from '../../../redux/ECH/GXT/gxt.action'
import { DATE_FORMAT } from '../../../util/dateTimeFormats'

export const Index = ({ currentGxt, gxtData, patientId, token, name, time, consultLetterFlag = false }) => {
    const dispatch = useDispatch()

    const handleCurrentGxt = (current) => {
        dispatch(setCurrentGtx(current))
        updateGraphs(current)
    }

    const updateGraphs = (current) => {
        if (name === 'Modal-pills' && time !== '') {
            const Months = moment().subtract(time, 'months').calendar()
            const proposedDate = moment(Months).format(DATE_FORMAT.YYYY_MM_DD) + 'T00:00:00.000Z'
            const payload = {
                patientId: patientId,
                type: current,
                date: time !== 'lastvisit' ? proposedDate : 'lastvisit',
            }
            dispatch(getGxtData(payload, token))
        } else {
            const Months = moment().subtract(60, 'months').calendar()
            const proposedDate = moment(Months).format(DATE_FORMAT.YYYY_MM_DD) + 'T00:00:00.000Z'
            const payload = {
                patientId: patientId,
                type: current,
                date: proposedDate,
            }
            dispatch(getGxtData(payload, token))
        }
    }

    useEffect(() => {
        time !== '' && updateGraphs(currentGxt)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [time])

    function search(nameKey, myArray) {
        let temp = ''
        for (var i = 0; i < myArray?.length; i++) {
            if (myArray[i].name === nameKey) {
                temp = myArray[i].value
                return myArray[i].value
            }
        }
        if (temp === '') {
            return ''
        }
    }
    function searchDiaVal(nameKey, myArray) {
        let temp = ''
        for (var i = 0; i < myArray?.length; i++) {
            if (myArray[i].name === nameKey) {
                temp = myArray[i].diaValueL
                return myArray[i].diaValueL
            }
        }
        if (temp === '') {
            return ''
        }
    }

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={4}>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentGxt('Ex Dur')
                        }}
                        className={clsx(
                            currentGxt === 'Ex Dur' && !consultLetterFlag ? 'active' : null,
                            'action-pills'
                        )}
                    >
                        Ex Dur {search('Ex Dur', gxtData?.list)}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentGxt('METS')
                        }}
                        className={clsx(
                            currentGxt === 'METS' && !consultLetterFlag ? 'active' : null,
                            'action-pills'
                        )}
                    >
                        METS {search('METS', gxtData?.list)}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentGxt('Double Product')
                        }}
                        className={clsx(
                            currentGxt === 'Double Product' && !consultLetterFlag ? 'active' : null,
                            'action-pills'
                        )}
                    >
                        Dbl Prod {search('Double Product', gxtData?.list)}
                    </div>
                </Grid>
                <Grid item xs={3.5}>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentGxt('Rest HR')
                        }}
                        className={clsx(
                            currentGxt === 'Rest HR' && !consultLetterFlag ? 'active' : null,
                            'action-pills'
                        )}
                    >
                        Rest HR {search('Rest HR', gxtData?.list)}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentGxt('Peak HR')
                        }}
                        className={clsx(
                            currentGxt === 'Peak HR' && !consultLetterFlag ? 'active' : null,
                            'action-pills'
                        )}
                    >
                        Peak HR {search('Peak HR', gxtData?.list)}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentGxt('% THR')
                        }}
                        className={clsx(
                            currentGxt === '% THR' && !consultLetterFlag ? 'active' : null,
                            'action-pills'
                        )}
                    >
                        % THR {search('% THR', gxtData?.list)}
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentGxt('Rest BP')
                        }}
                        className={clsx(
                            currentGxt === 'Rest BP' && !consultLetterFlag ? 'active' : null,
                            'action-pills'
                        )}
                    >
                        Rest BP {search('Rest BP', gxtData?.list)}
                        {search('Rest BP', gxtData?.list) === '' ? null : '/'}
                        {searchDiaVal('Rest BP', gxtData?.list)}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentGxt('Peak BP')
                        }}
                        className={clsx(
                            currentGxt === 'Peak BP' && !consultLetterFlag ? 'active' : null,
                            'action-pills'
                        )}
                    >
                        Peak BP {search('Peak BP', gxtData?.list)}
                        {search('Peak BP', gxtData?.list) === '' ? null : '/'}
                        {searchDiaVal('Peak BP', gxtData?.list)}
                    </div>
                    <div
                        onClick={() => {
                            if (!consultLetterFlag) return handleCurrentGxt('DTS')
                        }}
                        className={clsx(
                            currentGxt === 'DTS' && !consultLetterFlag ? 'active' : null,
                            'action-pills'
                        )}
                    >
                        DTS {search('DTS', gxtData?.list)}
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => ({
    currentGxt: state.gxt.currentGxt,
    gxtData: state.gxtList,
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
})

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentGtx: (payload) => dispatch(setCurrentGtx(payload)),
        getGxtData: (payload, token) => dispatch(getGxtData(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
