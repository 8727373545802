import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import '../../interval-history/interval-modal/interval-components/inputs.scss'
import variables from '../../../theme/variables.scss'
import { connect, useDispatch } from 'react-redux'
import axios from 'axios'
import { Formik, Form, FieldArray } from 'formik'
import _ from 'lodash'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import TranscribeIcon from '@mui/icons-material/Transcribe'
import ToggleButton from '@mui/material/ToggleButton'
import VoiceOverOffIcon from '@mui/icons-material/VoiceOverOff'
import { BlinkingTranscribeIcon } from '../../icon-wrapper'
import {
    toggleDeactivateModal,
    toggleSessionModal,
    toggleRecommendationsModal,
} from '../../../redux/modals/toggleModal.actions'
import { setAlert } from '../../../redux/alert/alert.action'
import { getRecommendationsListData } from '../../../redux/recommendations/recommendations.action'
import TextareaAutosize from '@mui/base/TextareaAutosize'
import RecommendationForm from './recommendation-form'
import { TranscribeContext } from '../../../context/awsTranscribe'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        minHeight: '500px',
        minWidth: '500px',
        display: 'flex',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialogTitle-root': {
        background: variables.gray100,
        fontWeight: 600,
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export const RecommendationsModal = ({
    patientId,
    token,
    recommendationsModal,
    recommendationList,
    recommendationLoading,
    lastUpdatedRecommendations,
    isCreatedNowFlag,
}) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = React.useState(false) //for form loader
    const [recordingIndex, setRecordingIndex] = React.useState(false)
    const [partialTranscript, setPartialTranscript] = React.useState('')
    const { awsTranscribe } = React.useContext(TranscribeContext)
    const setFieldValueRef = React.useRef(null)
    const recommendationValuesRef = React.useRef(null)

    React.useEffect(() => {
        const handleTranscriptError = (event) => {
            const errorMessage = event.detail.error
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg: 'Error in AWS Medical Transcribe',
                    alertOpen: true,
                })
            )
            awsTranscribe.stop()
            setRecordingIndex(false)
            console.log('Error in AWS Transcribe: ', errorMessage)
        }

        document.addEventListener('transcript-error', handleTranscriptError)
        return () => {
            document.removeEventListener('transcript-error', handleTranscriptError)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        const handleTranscript = (event) => {
            const isFinal = event.detail.isFinal
            const transcript = event.detail.transcript
            const valuesRef = recommendationValuesRef.current[recordingIndex]
            if (isFinal) {
                const updatedText = valuesRef?.notes + transcript + '\n'
                setFieldValueRef.current(`recommendation[${recordingIndex}].notes`, updatedText)
                setPartialTranscript('')
            } else {
                // need to add logic for partial transcript
                const partialTranscript = transcript + '...' // Modify this based on your desired format
                setPartialTranscript(partialTranscript)
            }
        }

        if (setFieldValueRef.current) {
            document.addEventListener('transcript', handleTranscript)
        }
        return () => {
            document.removeEventListener('transcript', handleTranscript)
        }
    }, [recordingIndex])

    /* Recommendation object for initial form values  */
    function pickRecommendationAttributes(data) {
        const pickedAttributes = ['_id', 'paUid', 'diagnosis', 'assessment', 'plan', 'notes']
        const result = _.map(data, (obj) => {
            const pickedObj = _.pick(obj, pickedAttributes)
            return _.defaults(pickedObj, { diagnosis: null, assessment: null, plan: null, notes: '' })
        })
        return result
    }

    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick') return
        if (awsTranscribe.socket) {
            awsTranscribe.stop()
        }
        dispatch(toggleRecommendationsModal(false))
    }

    const handleRecording = (checker, index) => {
        if (checker === 'uncheck') {
            setRecordingIndex(index)
            awsTranscribe.start()
        } else {
            setRecordingIndex(false)
            awsTranscribe.stop()
        }
    }

    const onSumbitHandler = (values) => {
        setLoading(true)
        if (awsTranscribe.socket) {
            awsTranscribe.stop()
        }
        const payload = { recommendation: values?.recommendation }
        /* axios */
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_CARD_URL}/recommendation`,
            data: payload,
            headers: {
                accessToken: token,
            },
        })
            .then((response) => {
                if (response?.data?.status === 200) {
                    setLoading(false)
                    dispatch(
                        setAlert({
                            alertType: 'success',
                            alertMsg: `Recommendations Updated Successfully`,
                            alertOpen: true,
                        })
                    )
                    const payloadList = {
                        patientId: patientId,
                    }
                    dispatch(getRecommendationsListData(payloadList, token))
                    dispatch(toggleRecommendationsModal(false))
                } else if (response?.data?.status === 400) {
                    setLoading(false)
                    dispatch(
                        setAlert({
                            alertType: 'error',

                            alertMsg: `Error ${response?.data?.error?.errorCode ?? ' '} : ${
                                response?.data?.error?.message
                            }`,
                            alertOpen: true,
                        })
                    )
                } else if (response?.data?.status === 401) {
                    setLoading(false)
                    dispatch(toggleSessionModal(true, response?.data?.error))
                } else if (response?.data?.status === 403) {
                    setLoading(false)
                    dispatch(toggleDeactivateModal(true))
                }
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: err?.message ?? 'Something went wrong. Please! try again.',
                        alertOpen: true,
                    })
                )
                console.log(err)
            })
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{ recommendation: pickRecommendationAttributes(recommendationList) }}
            onSubmit={onSumbitHandler}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                isValid,
                setValues,
                setFieldValue,
                isSubmitting,
                setSubmitting,
                submitForm,
            }) => {
                // Assign the setFieldValue function to the useRef reference
                setFieldValueRef.current = setFieldValue
                recommendationValuesRef.current = values?.recommendation
                return (
                    <Form noValidate autoComplete="off">
                        <div>
                            <BootstrapDialog
                                onClose={handleClose}
                                maxWidth="md"
                                // maxWidth={false}
                                fullWidth={true}
                                aria-labelledby="small-modal"
                                open={recommendationsModal}
                            >
                                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                                    <Typography className="fs-5 fw-bold">Recommendations</Typography>
                                    <Typography
                                        className=" cursor-default gray-600 fs-9"
                                        sx={{
                                            fontWeight: 400,
                                        }}
                                    >
                                        {isCreatedNowFlag
                                            ? 'Update Recommendations'
                                            : `Last Updated: ${moment(lastUpdatedRecommendations).fromNow()}`}
                                    </Typography>
                                </BootstrapDialogTitle>

                                <DialogContent dividers sx={{ padding: '0 !important', overflow: 'auto' }}>
                                    <div className="w-60 p-3">
                                        <FieldArray name="recommendation">
                                            <>
                                                {values?.recommendation?.map((p, index) => {
                                                    // const _id = `recommendation[${index}]._id`
                                                    // const paUid = `recommendation[${index}].paUid`
                                                    const diagnosis = `recommendation[${index}].diagnosis`
                                                    const assessment = `recommendation[${index}].assessment`
                                                    const plan = `recommendation[${index}].plan`
                                                    return (
                                                        <Box sx={{ pb: 2 }} key={index}>
                                                            <RecommendationForm
                                                                diagnosisId={diagnosis}
                                                                diagnosisValue={p?.diagnosis}
                                                                planId={plan}
                                                                planValue={p?.plan}
                                                                assessmentId={assessment}
                                                                assessmentValue={p?.assessment}
                                                            />
                                                        </Box>
                                                    )
                                                })}
                                            </>
                                        </FieldArray>
                                    </div>
                                    <div
                                        className="w-30 bg-gray-100 px-2 pb-1 pt-3"
                                        style={{
                                            position: 'relative',
                                            minHeight: `${
                                                values?.recommendation.length > 2
                                                    ? `${values?.recommendation?.length * 12}rem`
                                                    : '100%'
                                            }`,
                                        }}
                                    >
                                        <FieldArray name="recommendation">
                                            <>
                                                {values?.recommendation?.map((p, index) => {
                                                    const notes = `recommendation[${index}].notes`

                                                    return (
                                                        <>
                                                            <Box key={index}>
                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="space-between"
                                                                    alignItems="center"
                                                                    spacing={2}
                                                                    sx={{ pb: 0.5 }}
                                                                >
                                                                    <Typography
                                                                        className="gray-600 fs-8 fw-normal"
                                                                        gutterBottom
                                                                    >
                                                                        Additional Notes
                                                                    </Typography>
                                                                    <ToggleButton
                                                                        sx={{ height: 1.5, width: 1.5 }}
                                                                        value={
                                                                            recordingIndex === index
                                                                                ? 'check'
                                                                                : 'uncheck'
                                                                        }
                                                                        selected={recordingIndex === index}
                                                                        onChange={(e, value) => {
                                                                            handleRecording(value, index)
                                                                        }}
                                                                        disabled={
                                                                            recordingIndex !== false &&
                                                                            recordingIndex !== index
                                                                        }
                                                                    >
                                                                        {recordingIndex !== false &&
                                                                        recordingIndex !== index ? (
                                                                            <VoiceOverOffIcon
                                                                                sx={{
                                                                                    objectFit: 'contain',
                                                                                    p: '4px',
                                                                                }}
                                                                            />
                                                                        ) : recordingIndex === index ? (
                                                                            <BlinkingTranscribeIcon
                                                                                sx={{
                                                                                    objectFit: 'contain',
                                                                                    p: '4px',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <TranscribeIcon
                                                                                sx={{
                                                                                    objectFit: 'contain',
                                                                                    p: '4px',
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </ToggleButton>
                                                                </Stack>

                                                                <Box
                                                                    sx={{
                                                                        maxWidth: '20.5rem',
                                                                        height: '9.5rem',
                                                                    }}
                                                                >
                                                                    <TextareaAutosize
                                                                        minRows={4}
                                                                        maxRows={6}
                                                                        placeholder="Add Additional Details..."
                                                                        name={notes}
                                                                        id={notes}
                                                                        value={`${p?.notes}${
                                                                            recordingIndex === index
                                                                                ? partialTranscript
                                                                                : ''
                                                                        }`}
                                                                        onChange={handleChange}
                                                                        style={{
                                                                            width: '100%',
                                                                            minHeight: '7rem',
                                                                            maxHeight: '8rem',
                                                                            fontSize: '12px',
                                                                            borderRadius: '6px',
                                                                            padding: '6px',
                                                                            borderColor: variables.lightGray1,
                                                                            background: variables.white100,
                                                                            overflow: 'auto',
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </Box>
                                                        </>
                                                    )
                                                })}
                                            </>
                                        </FieldArray>
                                    </div>
                                </DialogContent>

                                <DialogActions>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 1,
                                            justifyContent: 'end',
                                            padding: '2px',
                                        }}
                                    >
                                        <Button
                                            onClick={() => dispatch(toggleRecommendationsModal(false))}
                                            variant="contained"
                                            className="btn-white-outline"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="button"
                                            variant="contained"
                                            className="btn-primary"
                                            onClick={submitForm}
                                            disabled={!!loading}
                                        >
                                            {!!loading ? 'Processing...' : 'Save'}
                                        </Button>
                                    </Box>
                                </DialogActions>
                            </BootstrapDialog>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

const mapStateToProps = (state) => {
    return {
        patientId: state.patient.patientData.patientId,
        token: state.loginData.loginResponse.accessToken,
        recommendationsModal: state?.toggleModals?.recommendationsModal,
        recommendationList: state?.recommendations?.list?.recommendation,
        recommendationLoading: state?.recommendations?.recommendationLoading,
        lastUpdatedRecommendations: state?.recommendations?.lud,
        isCreatedNowFlag: state?.recommendations?.isCreatedNow,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSessionModal: (payload, data) => dispatch(toggleSessionModal(payload, data)),
        toggleDeactivateModal: (payload) => dispatch(toggleDeactivateModal(payload)),
        setAlert: (payload) => dispatch(setAlert(payload)),
        toggleRecommendationsModal: (payload) => dispatch(toggleRecommendationsModal(payload)),
        getRecommendationsListData: (payload, token) => dispatch(getRecommendationsListData(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecommendationsModal)
