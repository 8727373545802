import PropTypes from 'prop-types'
import clsx from 'clsx'
import moment from 'moment'
import React, { useEffect } from 'react'
import './interval-components/inputs.scss'
import variables from '../../../theme/variables.scss'
import { connect, useDispatch } from 'react-redux'
import axios from 'axios'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import TextareaAutosize from '@mui/base/TextareaAutosize'

import {
    toggleDeactivateModal,
    toggleSessionModal,
    toggleIntervalModal,
} from '../../../redux/modals/toggleModal.actions'
import { setAlert } from '../../../redux/alert/alert.action'
import { getIntervalListData } from '../../../redux/intervalHistory/intervalHistory.action'

import { summaryTextGenerator, generateSymptom } from '../../../util/index'
import NoToAllToggle from './interval-components/NoToAllToggle'

import PainAccordion from './interval-form/Pain'
import BreathAccordion from './interval-form/Breath'
import PalpitationsAccordion from './interval-form/Palpitations'
import PreSyncopeAccordion from './interval-form/PreSyncope'
import EdemaAccordion from './interval-form/Edema'
import AdditionalNotes from './interval-form/AdditionalNotes'
import { TranscribeContext } from '../../../context/awsTranscribe'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),

        minHeight: '500px',
        minWidth: '500px',
        display: 'flex',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialogTitle-root': {
        background: variables.gray100,
        fontWeight: 600,
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export const Index = ({ patientId, token, intervalModal, intervalHistoryData, lastUpdatedInterval }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = React.useState(false)
    const [expanded, setExpanded] = React.useState(false)
    const [summaryText, setSummaryText] = React.useState('')
    const [noToAll, setNoToAll] = React.useState(false)
    const [isRecording, setIsRecording] = React.useState(false)
    const [partialTranscript, setPartialTranscript] = React.useState('')
    const { awsTranscribe } = React.useContext(TranscribeContext)
    const setFieldValueRef = React.useRef(null)
    const additionalTextRef = React.useRef(null)

    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick') return
        if (awsTranscribe.socket) {
            awsTranscribe.stop()
        }
        dispatch(toggleIntervalModal(false))
    }

    /* accordian state handler */
    const handleAccordianChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }

    /* summary state handler */
    const summaryHandlerFunction = (values) => {
        setSummaryText(
            !!expanded ? summaryTextGenerator(_.find(values?.intervalHistory, ['symptom', expanded])) : ''
        )
    }

    //Validation
    const validationSchema = Yup.object().shape({
        intervalHistory: Yup.array().of(
            Yup.object().shape({
                active: Yup.boolean().required('Value Required').nullable(),
            })
        ),
    })

    //Initial form value
    const initialValue = {
        intervalHistory: [
            generateSymptom('Pain'),
            generateSymptom('Shortness of Breath', { nyhaClass: null, ccsClass: null }),
            generateSymptom('Palpitations'),
            generateSymptom('(Pre)syncope', { ccsClass: null }),
            generateSymptom('Edema', { ccsClass: null }),
            { symptom: 'Description', additionalNotes: '', active: false },
        ],
    }

    const onSumbitHandler = (values) => {
        setLoading(true)
        if (awsTranscribe.socket) {
            awsTranscribe.stop()
        }
        const payload = { patientId: patientId, intervalHistory: values?.intervalHistory }

        /* axios */
        axios({
            method: !!lastUpdatedInterval ? 'put' : 'post',
            url: `${process.env.REACT_APP_CARD_URL}/interval-history?patientId=${patientId}`,
            data: payload,
            headers: {
                accessToken: token,
            },
        }).then((response) => {
            if (response?.data?.status === 200) {
                setLoading(false)
                dispatch(
                    setAlert({
                        alertType: 'success',

                        alertMsg: `Interval History ${
                            !!lastUpdatedInterval ? 'Updated' : 'Added'
                        } Successfully`,
                        alertOpen: true,
                    })
                )
                const payloadList = {
                    patientId: patientId,
                }

                dispatch(getIntervalListData(payloadList, token))
                dispatch(toggleIntervalModal(false))
            } else if (response?.data?.status === 400) {
                setLoading(false)
                dispatch(
                    setAlert({
                        alertType: 'error',

                        alertMsg: `Error ${response?.data?.error?.errorCode ?? ' '} : ${
                            response?.data?.error?.message
                        }`,
                        alertOpen: true,
                    })
                )
            } else if (response?.data?.status === 401) {
                setLoading(false)
                dispatch(toggleSessionModal(true, response?.data?.error))
            } else if (response?.data?.status === 403) {
                setLoading(false)
                dispatch(toggleDeactivateModal(true))
            }
            setLoading(false)
        })
    }

    const handleRecording = (checker) => {
        if (checker === 'uncheck') {
            setIsRecording(true)
            awsTranscribe.start()
        } else {
            setIsRecording(false)
            awsTranscribe.stop()
        }
    }

    useEffect(() => {
        const handleTranscriptError = (event) => {
            const errorMessage = event.detail.error
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg: 'Error in AWS Medical Transcribe',
                    alertOpen: true,
                })
            )
            awsTranscribe.stop()
            setIsRecording(false)
            console.log('Error in AWS Transcribe: ', errorMessage)
        }

        document.addEventListener('transcript-error', handleTranscriptError)
        return () => {
            document.removeEventListener('transcript-error', handleTranscriptError)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const handleTranscript = (event) => {
            const isFinal = event.detail.isFinal
            const transcript = event.detail.transcript
            const valuesRef = additionalTextRef.current
            if (isFinal) {
                const updatedText = valuesRef + transcript + '\n'
                setFieldValueRef.current('intervalHistory[5].additionalNotes', updatedText)
                setPartialTranscript('')
            } else {
                const partialTranscript = transcript + '...' // Modify this based on your desired format
                setPartialTranscript(partialTranscript)
            }
        }
        if (setFieldValueRef.current) {
            document.addEventListener('transcript', handleTranscript)
        }
        return () => {
            document.removeEventListener('transcript', handleTranscript)
        }
    }, [isRecording])

    useEffect(() => {
        if (!!noToAll && !!setExpanded) {
            setNoToAll(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded])

    return (
        <Formik
            enableReinitialize={true}
            initialValues={
                !!lastUpdatedInterval
                    ? { intervalHistory: intervalHistoryData?.intervalHistory }
                    : initialValue
            }
            validationSchema={validationSchema}
            onSubmit={onSumbitHandler}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                isValid,
                setValues,
                setFieldValue,
                isSubmitting,
                setSubmitting,
                submitForm,
            }) => {
                // Assign the setFieldValue function to the useRef reference
                setFieldValueRef.current = setFieldValue
                additionalTextRef.current = values?.intervalHistory[5]?.additionalNotes
                return (
                    <Form noValidate autoComplete="off">
                        <div name="intervalHistory">
                            <BootstrapDialog
                                onClose={handleClose}
                                maxWidth="lg"
                                aria-labelledby="small-modal"
                                open={intervalModal}
                            >
                                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                                    <Typography className="fs-5 fw-bold">Interval History</Typography>
                                    <Typography
                                        className=" cursor-default gray-600 fs-9"
                                        sx={{
                                            fontWeight: 400,
                                        }}
                                    >
                                        {!!lastUpdatedInterval
                                            ? `Last Updated: ${moment(lastUpdatedInterval).fromNow()}`
                                            : 'Add Interval History'}
                                    </Typography>
                                </BootstrapDialogTitle>

                                {/* tracks values and updates summary text */}
                                {summaryHandlerFunction(values)}

                                <DialogContent dividers sx={{ padding: '0 !important' }}>
                                    <div className={clsx('w-61 overflow-auto p-2')}>
                                        <Stack
                                            sx={{ width: '100%', pr: 2, pl: 1 }}
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            spacing={4}
                                        >
                                            <Typography className="fs-5 fw-bold" gutterBottom>
                                                {!!lastUpdatedInterval ? 'Update Symptoms Details' : ''}
                                            </Typography>
                                        </Stack>

                                        {/* No to all Toggle */}
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            spacing={2}
                                            sx={{ px: 1 }}
                                        >
                                            <NoToAllToggle
                                                standaloneSelected={noToAll}
                                                setStandaloneSelected={setNoToAll}
                                                setExpanded={setExpanded}
                                                values={values}
                                            />
                                        </Stack>

                                        {/* Pain */}
                                        <PainAccordion
                                            values={values}
                                            expanded={expanded}
                                            setExpanded={setExpanded}
                                            handleAccordianChange={handleAccordianChange}
                                        />

                                        {/* Shortness of Breath */}
                                        <BreathAccordion
                                            values={values}
                                            expanded={expanded}
                                            setExpanded={setExpanded}
                                            handleAccordianChange={handleAccordianChange}
                                        />

                                        {/* Palpitations */}
                                        <PalpitationsAccordion
                                            values={values}
                                            expanded={expanded}
                                            setExpanded={setExpanded}
                                            handleAccordianChange={handleAccordianChange}
                                        />

                                        {/* (Pre)syncope */}
                                        <PreSyncopeAccordion
                                            values={values}
                                            expanded={expanded}
                                            setExpanded={setExpanded}
                                            handleAccordianChange={handleAccordianChange}
                                        />

                                        {/* Edema */}
                                        <EdemaAccordion
                                            values={values}
                                            expanded={expanded}
                                            setExpanded={setExpanded}
                                            handleAccordianChange={handleAccordianChange}
                                        />
                                    </div>
                                    <div
                                        className={clsx('w-39 bg-gray-100 p-2')}
                                        style={{
                                            position: 'relative',
                                        }}
                                    >
                                        <Typography className="gray-600 fs-8 fw-normal" gutterBottom>
                                            Summary
                                        </Typography>

                                        <Box>
                                            <TextareaAutosize
                                                className="interval-summary-input"
                                                aria-label="minimum height"
                                                minRows={6}
                                                maxRows={10}
                                                placeholder="Summary of the expanded interval tab will appear here."
                                                value={summaryText}
                                                style={{
                                                    width: '100%',
                                                    maxHeight: '300px',
                                                    cursor: 'not-allowed',
                                                    fontSize: '14px',
                                                    borderRadius: '6px',
                                                    padding: '6px',
                                                    borderColor: variables.lightGray1,
                                                    background: variables.white100,
                                                }}
                                                disabled
                                            />
                                        </Box>
                                        <AdditionalNotes
                                            title="Additional Notes"
                                            fieldName={`intervalHistory[5].additionalNotes`}
                                            fieldVal={`${values?.intervalHistory[5]?.additionalNotes}${
                                                isRecording ? partialTranscript : ''
                                            }`}
                                            isRecording={isRecording}
                                            handleRecording={handleRecording}
                                        />
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 1,
                                            justifyContent: 'end',
                                            padding: '2px',
                                        }}
                                    >
                                        <Button
                                            onClick={() => dispatch(toggleIntervalModal(false))}
                                            variant="contained"
                                            className="btn-white-outline"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="button"
                                            variant="contained"
                                            className="btn-primary"
                                            onClick={submitForm}
                                            disabled={!!loading}
                                        >
                                            {!!loading ? 'Processing...' : 'Save'}
                                        </Button>
                                    </Box>
                                </DialogActions>
                            </BootstrapDialog>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

const mapStateToProps = (state) => {
    return {
        patientId: state.patient.patientData.patientId,
        token: state.loginData.loginResponse.accessToken,
        intervalModal: state?.toggleModals?.intervalModal,
        intervalHistoryData: state?.intervalHistory?.list,
        lastUpdatedInterval: state?.intervalHistory?.lud,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSessionModal: (payload, data) => dispatch(toggleSessionModal(payload, data)),
        toggleDeactivateModal: (payload) => dispatch(toggleDeactivateModal(payload)),
        setAlert: (payload) => dispatch(setAlert(payload)),
        toggleIntervalModal: (payload) => dispatch(toggleIntervalModal(payload)),
        getIntervalListData: (payload, token) => dispatch(getIntervalListData(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
