import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Formik, Form, FieldArray, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import variables from '../../../theme/variables.scss'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import axios from 'axios'
import {
    toggleDeactivateModal,
    toggleSessionModal,
    toggleAdverseEventModal,
} from '../../../redux/modals/toggleModal.actions'
import Button from '@mui/material/Button'
import { setAlert } from '../../../redux/alert/alert.action'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import {
    getAdverseEventListData,
    getAdverseEventSubstanceData,
    setCurrentAdverseEvent,
} from '../../../redux/adverseEvent/adverseEvent.action'
import { adverseReactionFormData } from '../../../util/formData'

export const Index = ({
    // submit,
    token,
    patientId,
    setAlignment,
    alignment,
    searchValue,
    setSearchValue,
    advFormData,
    advType,
    currentAdverseEvent,
}) => {
    const dispatch = useDispatch()

    const [loading, setLoading] = React.useState(false)

    //Validation
    const validationSchema = Yup.object().shape({
        adverseEvent: Yup.object().shape({
            reaction: Yup.string().required('Reaction is required').nullable(),
            presentations: Yup.array().of(
                Yup.object().shape({
                    name: Yup.string().required('Presentation is required').nullable(),
                })
            ),
        }),
    })

    /* onSubmit Handler */
    const onSumbitHandler = (values) => {
        setLoading(true)
        const payload =
            alignment === 'Add'
                ? { patientId: patientId, adverseEvent: values?.adverseEvent }
                : alignment === 'Update'
                ? { patientId: patientId, adverseEvent: values?.adverseEvent }
                : null

        /* axios */
        axios({
            method: alignment === 'Update' ? 'put' : 'post',

            url: `${process.env.REACT_APP_CARD_URL}/adverse-event`,
            data: payload,
            headers: {
                accessToken: token,
            },
        }).then((response) => {
            if (response?.data?.status === 200) {
                setLoading(false)
                dispatch(
                    setAlert({
                        alertType: 'success',
                        alertMsg: `Adverse Event ${
                            alignment === 'Update' ? 'Updated' : 'Added'
                        } Successfully`,
                        alertOpen: true,
                    })
                )

                const payloadAdverseAllergyList = {
                    patientId: patientId,
                    type: 'allergy',
                }
                dispatch(getAdverseEventListData(payloadAdverseAllergyList, token))
                dispatch(setCurrentAdverseEvent('allergy'))
                dispatch(toggleAdverseEventModal(false))
            } else if (response?.data?.status === 400) {
                setLoading(false)
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: `Error: ${response?.data?.error?.message}`,
                        alertOpen: true,
                    })
                )
            } else if (response?.data?.status === 401) {
                setLoading(false)
                dispatch(toggleSessionModal(true, response?.data?.error))
            } else if (response?.data?.status === 403) {
                setLoading(false)
                dispatch(toggleDeactivateModal(true))
            }
            setLoading(false)
        })
    }

    return (
        <div className="medication-class adverse-class">
            {!!searchValue?.id || alignment === 'Update' ? (
                <Typography className="fs-5 mt-2 mb-2 fw-bold " gutterBottom>
                    {alignment === 'Add'
                        ? `${searchValue?.id ?? ''}`
                        : alignment === 'Update'
                        ? advType
                        : null}
                </Typography>
            ) : null}
            <Formik
                enableReinitialize={true}
                initialValues={
                    alignment === 'Add'
                        ? {
                              adverseEvent: {
                                  reaction: null,
                                  diagnoseDate: null,
                                  treatments: '',
                                  severity: '',
                                  substance: searchValue?.id,
                                  type: currentAdverseEvent,
                                  presentations: [{ name: '', date: null, treatment: '' }],
                              },
                          }
                        : alignment === 'Update'
                        ? { adverseEvent: advFormData }
                        : {}
                }
                validationSchema={validationSchema}
                onSubmit={onSumbitHandler}
                validateOnChange={false} //added to reduce renders ,bottlenecks for field array
            >
                {({
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    isValid,
                    setValues,
                    setFieldValue,
                    isSubmitting,
                    setSubmitting,
                    setTouched,
                }) => (
                    <Form noValidate autoComplete="off">
                        {!!searchValue?.id || alignment === 'Update' ? (
                            <>
                                <Grid container direction="row" rowSpacing={2} columnSpacing={4}>
                                    <Grid item>
                                        <Typography className="gray-600 fs-8 fw-normal" gutterBottom>
                                            Reaction
                                        </Typography>
                                        <div className="d-flex">
                                            <Autocomplete
                                                getOptionLabel={(option) => option?.id ?? option}
                                                value={values?.adverseEvent?.reaction}
                                                onChange={(event, newValue) => {
                                                    setFieldValue(
                                                        'adverseEvent.reaction',
                                                        newValue?.val ?? null
                                                    )
                                                }}
                                                size="small"
                                                disablePortal
                                                id="combo-box-demo"
                                                options={adverseReactionFormData}
                                                sx={{
                                                    width: '14.5rem',
                                                }}
                                                fullWidth
                                                onBlur={handleBlur}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            placeholder: 'Select Reaction',
                                                            // style: { fontSize: '0.75rem' },
                                                        }}
                                                        error={
                                                            touched?.adverseEvent?.reaction &&
                                                            Boolean(errors?.adverseEvent?.reaction)
                                                        }
                                                        helperText={
                                                            touched?.adverseEvent?.reaction &&
                                                            errors?.adverseEvent?.reaction
                                                        }
                                                        name="adverseEvent.reaction"
                                                    />
                                                )}
                                                isOptionEqualToValue={(option, value) => {
                                                    // if (alignment === 'Update')
                                                    return option?.val === value
                                                    // return option?.val === value?.id
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div>
                                            <Typography className="gray-600 fs-8 fw-normal" gutterBottom>
                                                Dx Date
                                            </Typography>

                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    disableFuture={true}
                                                    inputFormat="MMM, YYYY"
                                                    views={['year', 'month']}
                                                    openTo="month"
                                                    disableMaskedInput
                                                    name={'adverseEvent.diagnoseDate'}
                                                    value={values?.adverseEvent?.diagnoseDate}
                                                    onChange={(newValue) => {
                                                        setFieldValue(
                                                            'adverseEvent.diagnoseDate',
                                                            new Date(newValue)
                                                        )
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            size="small"
                                                            sx={{
                                                                maxWidth: '12.18rem !important',
                                                            }}
                                                            // className="myDatePicker"
                                                            // error={false}
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                placeholder: '',
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <Typography className="gray-600 fs-8 fw-normal" gutterBottom>
                                            Treatments
                                        </Typography>
                                        <div className="d-flex">
                                            <ToggleButtonGroup
                                                orientation="vertical"
                                                exclusive
                                                fullWidth={true}
                                                name={'adverseEvent.treatments'}
                                                value={values?.adverseEvent?.treatments}
                                                onChange={(e, nextView) =>
                                                    setFieldValue('adverseEvent.treatments', nextView)
                                                }
                                            >
                                                {['Monitor', 'Medications', 'Surgery'].map((btn, idx) => (
                                                    <ToggleButton
                                                        key={idx}
                                                        name={btn}
                                                        value={btn}
                                                        aria-label={btn}
                                                        sx={{
                                                            textTransform: 'capitalize !important',
                                                            minWidth: '100px !important',
                                                        }}
                                                    >
                                                        {btn}
                                                    </ToggleButton>
                                                ))}
                                            </ToggleButtonGroup>
                                        </div>
                                        {
                                            <ErrorMessage
                                                name={'adverseEvent?.treatments'}
                                                render={(msg) => (
                                                    <div className="form-error-message">{msg}</div>
                                                )}
                                            />
                                        }
                                    </Grid>
                                    <Grid item>
                                        <Typography className="gray-600 fs-8 fw-normal" gutterBottom>
                                            Severity
                                        </Typography>
                                        <div className="d-flex">
                                            <ToggleButtonGroup
                                                orientation="vertical"
                                                exclusive
                                                fullWidth={true}
                                                name={'adverseEvent.severity'}
                                                value={values?.adverseEvent?.severity}
                                                onChange={(e, nextView) =>
                                                    setFieldValue('adverseEvent.severity', nextView)
                                                }
                                            >
                                                {['Mild', 'Moderate', 'Severe'].map((btn, idx) => (
                                                    <ToggleButton
                                                        key={idx}
                                                        name={btn}
                                                        value={btn}
                                                        aria-label={btn}
                                                        sx={{
                                                            textTransform: 'capitalize !important',
                                                            minWidth: '100px !important',
                                                        }}
                                                    >
                                                        {btn}
                                                    </ToggleButton>
                                                ))}
                                            </ToggleButtonGroup>
                                        </div>
                                        {
                                            <ErrorMessage
                                                name={'adverseEvent.severity'}
                                                render={(msg) => (
                                                    <div className="form-error-message">{msg}</div>
                                                )}
                                            />
                                        }
                                    </Grid>
                                </Grid>

                                <FieldArray name="adverseEvent.presentations">
                                    {({ push, remove, insert }) => (
                                        <div>
                                            <Button
                                                size="small"
                                                onClick={() => {
                                                    push({
                                                        name: '',
                                                        date: null,
                                                        treatment: '',
                                                    })
                                                }}
                                                variant="contained"
                                                className="btn-light"
                                                fullWidth={true}
                                                startIcon={<AddIcon />}
                                                sx={{ width: 'fit-content', mt: 1, mb: 2 }}
                                            >
                                                Add New Presentation
                                            </Button>

                                            {values?.adverseEvent?.presentations?.map((p, index) => {
                                                const name = `adverseEvent.presentations[${index}].name`
                                                const date = `adverseEvent.presentations[${index}].date`
                                                const treatment = `adverseEvent.presentations[${index}].treatment`

                                                return (
                                                    <div key={index}>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            rowSpacing={2}
                                                            columnSpacing={2}
                                                            sx={{ padding: 1 }}
                                                        >
                                                            <Grid
                                                                item
                                                                sx={{
                                                                    py: '1px !important',
                                                                }}
                                                            >
                                                                <Typography
                                                                    className="gray-600 fs-8 mt-1 fw-normal"
                                                                    gutterBottom
                                                                >
                                                                    Presentation
                                                                </Typography>

                                                                <TextField
                                                                    id={name}
                                                                    name={name}
                                                                    value={p.name}
                                                                    onChange={handleChange}
                                                                    size="small"
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        touched?.adverseEvent
                                                                            ?.presentations?.[index]?.name &&
                                                                        Boolean(
                                                                            errors?.adverseEvent
                                                                                ?.presentations?.[index]?.name
                                                                        )
                                                                    }
                                                                    helperText={
                                                                        !!(
                                                                            touched?.adverseEvent
                                                                                ?.presentations?.[index]
                                                                                ?.name &&
                                                                            errors?.adverseEvent
                                                                                ?.presentations?.[index]?.name
                                                                        )
                                                                            ? errors?.adverseEvent
                                                                                  ?.presentations?.[index]
                                                                                  ?.name
                                                                            : ' '
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                sx={{
                                                                    py: '1px !important',
                                                                }}
                                                            >
                                                                <Typography
                                                                    className="gray-600 fs-8 mt-1 fw-normal"
                                                                    gutterBottom
                                                                >
                                                                    Date
                                                                </Typography>

                                                                <LocalizationProvider
                                                                    dateAdapter={AdapterMoment}
                                                                >
                                                                    <DatePicker
                                                                        disableFuture={true}
                                                                        inputFormat="MMM DD, YYYY"
                                                                        disableMaskedInput
                                                                        name={date}
                                                                        value={p?.date}
                                                                        onChange={(newValue) =>
                                                                            setFieldValue(
                                                                                date,
                                                                                new Date(newValue)
                                                                            )
                                                                        }
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                size="small"
                                                                                sx={{
                                                                                    maxWidth:
                                                                                        '12.18rem !important',
                                                                                }}
                                                                                // className="myDatePicker"
                                                                                // error={false}
                                                                                inputProps={{
                                                                                    ...params.inputProps,
                                                                                    placeholder: '',
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                sx={{
                                                                    py: '1px !important',
                                                                }}
                                                            >
                                                                <Typography
                                                                    className="gray-600 fs-8 mt-1 fw-normal"
                                                                    gutterBottom
                                                                >
                                                                    Treatment
                                                                </Typography>
                                                                <TextField
                                                                    name={treatment}
                                                                    value={p.treatment}
                                                                    onChange={handleChange}
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    py: '1px !important',
                                                                }}
                                                            >
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    onClick={() => remove(index)}
                                                                >
                                                                    <DeleteIcon
                                                                        sx={{
                                                                            color: variables.red600,
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )}
                                </FieldArray>
                            </>
                        ) : null}
                        {!!values?.adverseEvent && (
                            <>
                                {!!searchValue?.id || alignment === 'Update' ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 1,
                                            justifyContent: 'end',
                                            padding: 4,
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            className="btn-primary"
                                            disabled={!!loading}
                                        >
                                            {!!loading ? 'Processing...' : 'Save'}
                                        </Button>
                                        <Button
                                            onClick={() => setAlignment('Details')}
                                            variant="contained"
                                            className="btn-white-outline"
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                ) : null}
                            </>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.loginData.loginResponse.accessToken,
        patientId: state.patient.patientData.patientId,
        currentAdverseEvent: state?.adverseEvent?.currentAdverseEvent,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSessionModal: (payload, data) => dispatch(toggleSessionModal(payload, data)),
        toggleDeactivateModal: (payload) => dispatch(toggleDeactivateModal(payload)),
        setAlert: (payload) => dispatch(setAlert(payload)),
        getAdverseEventListData: (payload, token) => dispatch(getAdverseEventListData(payload, token)),
        getAdverseEventSubstanceData: (payload, token) =>
            dispatch(getAdverseEventSubstanceData(payload, token)),
        toggleAdverseEventModal: (payload) => dispatch(toggleAdverseEventModal(payload)),
        setCurrentAdverseEvent: (payload) => dispatch(setCurrentAdverseEvent(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
