import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Formik, Form, FieldArray, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import variables from '../../../theme/variables.scss'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import axios from 'axios'
import {
    toggleDeactivateModal,
    toggleSessionModal,
    toggleFamilyModal,
} from '../../../redux/modals/toggleModal.actions'
import Button from '@mui/material/Button'
import { setAlert } from '../../../redux/alert/alert.action'
import { getFamilyListData, getFamilyViewData } from '../../../redux/familyList/familyList.action'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import Divider from '@mui/material/Divider'
import Inputs from '../../interval-history/interval-modal/interval-components/Inputs'

export const Index = ({
    // submit,
    token,
    patientId,
    setAlignment,
    alignment,
    searchValue,
    setSearchValue,
    famFormData,
    diseaseType,
}) => {
    const dispatch = useDispatch()

    const [loading, setLoading] = React.useState(false)

    // React.useEffect(() => {
    //     submit.current = OnSubmit
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    //Validation
    const validationSchema = Yup.object().shape({
        familyHistory: Yup.array().of(
            Yup.object().shape({
                relative: Yup.string().required('Relative is required').nullable(),
            })
        ),
    })

    /* onSubmit Handler */
    const onSumbitHandler = (values) => {
        setLoading(true)
        const payload =
            alignment === 'Add'
                ? { patientId: patientId, familyHistory: values?.familyHistory }
                : alignment === 'Update'
                ? { patientId: patientId, type: diseaseType, familyHistory: values?.familyHistory }
                : null

        /* axios */
        axios({
            method: alignment === 'Update' ? 'put' : 'post',

            url: `${process.env.REACT_APP_CARD_URL}/family-info`,
            data: payload,
            headers: {
                accessToken: token,
            },
        }).then((response) => {
            if (response?.data?.status === 200) {
                setLoading(false)
                dispatch(
                    setAlert({
                        alertType: 'success',
                        alertMsg: `Family History ${
                            alignment === 'Update' ? 'Updated' : 'Added'
                        } Successfully`,
                        alertOpen: true,
                    })
                )
                const payloadList = {
                    patientId: patientId,
                }

                /* additonal refetching */
                dispatch(getFamilyListData(payloadList, token))

                dispatch(toggleFamilyModal(false))
            } else if (response?.data?.status === 400) {
                setLoading(false)
                dispatch(
                    setAlert({
                        alertType: 'error',

                        alertMsg: `Error ${response?.data?.error?.errorCode ?? ' '} : ${
                            response?.data?.error?.message
                        }`,
                        alertOpen: true,
                    })
                )
            } else if (response?.data?.status === 401) {
                setLoading(false)
                dispatch(toggleSessionModal(true, response?.data?.error))
            } else if (response?.data?.status === 403) {
                setLoading(false)
                dispatch(toggleDeactivateModal(true))
            }
            setLoading(false)
        })
    }

    return (
        <div className="medication-class">
            {!!searchValue?.id || alignment === 'Update' ? (
                <Typography className="fs-5 mt-2 mb-2 fw-bold " gutterBottom>
                    {alignment === 'Add'
                        ? `${searchValue?.id ?? ''}`
                        : alignment === 'Update'
                        ? diseaseType
                        : null}
                </Typography>
            ) : null}
            <Formik
                enableReinitialize={true}
                initialValues={
                    alignment === 'Add'
                        ? {
                              familyHistory: [
                                  {
                                      // id: Math.random(),
                                      disease: searchValue?.id,
                                      relative: '',
                                      diagnosisAge: 0,
                                      status: {
                                          alive: false,
                                          age: 0,
                                      },
                                  },
                              ],
                          }
                        : alignment === 'Update'
                        ? { familyHistory: famFormData }
                        : {}
                }
                validationSchema={validationSchema}
                onSubmit={onSumbitHandler}
            >
                {({
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    isValid,
                    setValues,
                    setFieldValue,
                    isSubmitting,
                    setSubmitting,
                }) => (
                    <Form noValidate autoComplete="off">
                        {!!searchValue?.id || alignment === 'Update' ? (
                            <FieldArray name="familyHistory">
                                {({ push, remove }) => (
                                    <div>
                                        <Button
                                            size="small"
                                            onClick={() =>
                                                push({
                                                    // id: Math.random(),
                                                    disease:
                                                        alignment === 'Update'
                                                            ? diseaseType
                                                            : searchValue?.id,
                                                    relative: '',
                                                    diagnosisAge: 0,
                                                    status: {
                                                        alive: false,
                                                        age: 0,
                                                    },
                                                })
                                            }
                                            variant="contained"
                                            className="btn-light"
                                            fullWidth={true}
                                            startIcon={<AddIcon />}
                                            sx={{ width: 'fit-content' }}
                                        >
                                            Add New Relative
                                        </Button>
                                        {values?.familyHistory?.map((p, index) => {
                                            const relative = `familyHistory[${index}].relative`
                                            const diagnosisAge = `familyHistory[${index}].diagnosisAge`
                                            const alive = `familyHistory[${index}].status.alive`
                                            const age = `familyHistory[${index}].status.age`

                                            return (
                                                // key={p.id}
                                                <div key={index} className="pt-4">
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        rowSpacing={2}
                                                        columnSpacing={8}
                                                    >
                                                        <Grid item>
                                                            <Typography
                                                                className="gray-600 fs-8 fw-normal"
                                                                gutterBottom
                                                            >
                                                                Relative
                                                            </Typography>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                rowSpacing={2}
                                                                columnSpacing={2}
                                                            >
                                                                <Grid item>
                                                                    <div className="d-flex">
                                                                        <ToggleButtonGroup
                                                                            orientation="vertical"
                                                                            exclusive
                                                                            fullWidth={true}
                                                                            name={relative}
                                                                            value={p.relative}
                                                                            onChange={(e, nextView) =>
                                                                                setFieldValue(
                                                                                    relative,
                                                                                    nextView
                                                                                )
                                                                            }
                                                                        >
                                                                            {[
                                                                                'Mother',
                                                                                'Father',
                                                                                'Brother',
                                                                            ].map((btn, idx) => (
                                                                                <ToggleButton
                                                                                    key={idx}
                                                                                    name={btn}
                                                                                    value={btn}
                                                                                    aria-label={btn}
                                                                                    sx={{
                                                                                        textTransform:
                                                                                            'capitalize !important',
                                                                                        minWidth:
                                                                                            '72px !important',
                                                                                    }}
                                                                                >
                                                                                    {btn}
                                                                                </ToggleButton>
                                                                            ))}
                                                                        </ToggleButtonGroup>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item>
                                                                    <div className="d-flex ">
                                                                        <ToggleButtonGroup
                                                                            orientation="vertical"
                                                                            exclusive
                                                                            fullWidth={true}
                                                                            name={relative}
                                                                            value={p.relative}
                                                                            onChange={(e, nextView) =>
                                                                                setFieldValue(
                                                                                    relative,
                                                                                    nextView
                                                                                )
                                                                            }
                                                                        >
                                                                            {['Sister', 'Other'].map(
                                                                                (btn, idx) => (
                                                                                    <ToggleButton
                                                                                        key={idx}
                                                                                        name={btn}
                                                                                        value={btn}
                                                                                        aria-label={btn}
                                                                                        sx={{
                                                                                            textTransform:
                                                                                                'capitalize !important',
                                                                                            minWidth:
                                                                                                '72px !important',
                                                                                        }}
                                                                                    >
                                                                                        {btn}
                                                                                    </ToggleButton>
                                                                                )
                                                                            )}
                                                                        </ToggleButtonGroup>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                            {
                                                                <ErrorMessage
                                                                    name={relative}
                                                                    render={(msg) => (
                                                                        <div className="form-error-message">
                                                                            {msg}
                                                                        </div>
                                                                    )}
                                                                />
                                                            }
                                                        </Grid>

                                                        <Grid item>
                                                            <Typography
                                                                className="gray-600 fs-8 fw-normal"
                                                                gutterBottom
                                                            >
                                                                Dx age
                                                            </Typography>
                                                            <Inputs
                                                                inputType={'counter'}
                                                                inputName={diagnosisAge}
                                                                inputVal={p.diagnosisAge ?? 0}
                                                            />
                                                            <div className="py-1">
                                                                <ToggleButton
                                                                    value={null}
                                                                    aria-label="start"
                                                                    selected={p.diagnosisAge === null}
                                                                    // onChange={handleStartChange}
                                                                    onChange={() =>
                                                                        setFieldValue(diagnosisAge, null)
                                                                    }
                                                                    sx={{
                                                                        textTransform:
                                                                            'capitalize !important',
                                                                        minWidth: '72px !important',
                                                                    }}
                                                                >
                                                                    Unknown
                                                                </ToggleButton>
                                                            </div>
                                                        </Grid>

                                                        <Grid item>
                                                            <Typography
                                                                className="gray-600 fs-8 fw-normal"
                                                                gutterBottom
                                                            >
                                                                Status
                                                            </Typography>

                                                            <div className="">
                                                                <ToggleButton
                                                                    value={!!p.status?.alive}
                                                                    aria-label="start"
                                                                    selected={p?.status?.alive === true}
                                                                    onChange={() =>
                                                                        setFieldValue(alive, !p.status.alive)
                                                                    }
                                                                    sx={{
                                                                        textTransform:
                                                                            'capitalize !important',
                                                                        minWidth: '72px !important',
                                                                    }}
                                                                >
                                                                    Alive
                                                                </ToggleButton>
                                                            </div>

                                                            <div className="d-flex justify-content-start align-items-start ">
                                                                <div className="pe-2">
                                                                    <ToggleButton
                                                                        value={!!p?.status?.alive}
                                                                        aria-label="start"
                                                                        selected={p?.status?.alive === false}
                                                                        onChange={() =>
                                                                            setFieldValue(
                                                                                alive,
                                                                                !p.status.alive
                                                                            )
                                                                        }
                                                                        sx={{
                                                                            textTransform:
                                                                                'capitalize !important',
                                                                            minWidth: '72px !important',
                                                                        }}
                                                                    >
                                                                        Deceased
                                                                    </ToggleButton>
                                                                </div>
                                                                <Inputs
                                                                    inputType={'counter'}
                                                                    inputName={age}
                                                                    inputVal={p?.status?.age ?? 0}
                                                                />
                                                                <Box>
                                                                    <Typography className=" gray-900 fs-8 px-2 py-1 fw-normal">
                                                                        year(s)
                                                                    </Typography>{' '}
                                                                </Box>
                                                            </div>
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'flex-start',
                                                            }}
                                                        >
                                                            <IconButton
                                                                aria-label="delete"
                                                                onClick={() => remove(index)}
                                                            >
                                                                <DeleteIcon
                                                                    sx={{
                                                                        color: variables.red600,
                                                                        cursor: 'pointer',
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                    <Divider
                                                        sx={{
                                                            borderColor: variables.black100,
                                                            borderStyle: 'dotted',
                                                        }}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                            </FieldArray>
                        ) : null}
                        {!!values?.familyHistory?.length > 0 && (
                            <>
                                {!!searchValue?.id || alignment === 'Update' ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 1,
                                            justifyContent: 'end',
                                            padding: 4,
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            className="btn-primary"
                                            disabled={!!loading}
                                        >
                                            {!!loading ? 'Processing...' : 'Save'}
                                        </Button>
                                        <Button
                                            onClick={() => setAlignment('Details')}
                                            variant="contained"
                                            className="btn-white-outline"
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                ) : null}
                            </>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.loginData.loginResponse.accessToken,
        patientId: state.patient.patientData.patientId,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSessionModal: (payload, data) => dispatch(toggleSessionModal(payload, data)),
        toggleDeactivateModal: (payload) => dispatch(toggleDeactivateModal(payload)),
        setAlert: (payload) => dispatch(setAlert(payload)),

        getFamilyListData: (payload, token) => dispatch(getFamilyListData(payload, token)),
        getFamilyViewData: (payload, token) => dispatch(getFamilyViewData(payload, token)),
        toggleFamilyModal: (payload) => dispatch(toggleFamilyModal(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
