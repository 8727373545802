import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import clsx from 'clsx'
import { styled } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Ecg from '../ecg-component/ecg'
import Holter from '../holter-component/holter'
import Gxt from '../gxt-component/gxt'
import { toggleEcgModal, toggleGxtModal, toggleHolterModal } from '../../redux/modals/toggleModal.actions'
import { getEcgsData, getEcgsDocument, getEcgsListData } from '../../redux/ECH/ecgList/ecgList.action'
import {
    getHolterData,
    getHolterDocument,
    getHolterListData,
} from '../../redux/ECH/holterList/holterList.action'
import { getGxtData, getGxtDocument, getGxtListData } from '../../redux/ECH/gxtList/gxtList.action'
import { DATE_FORMAT } from '../../util/dateTimeFormats'
import InfoBox from '../vital-component/vitals/InfoBox'

const AntTabs = styled(Tabs)({
    borderBottom: 'none',
    '& .MuiTabs-indicator': {
        backgroundColor: '#4F46E5',
    },
})

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: 500,
    marginRight: theme.spacing(2),
    padding: 0,
    color: '#4B5563',
    fontFamily: 'Inter',
    '&:hover': {
        color: '#4F46E5',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#4F46E5',
        fontWeight: 600,
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },
}))

export const Index = ({
    ecgLUD,
    patientId,
    token,
    holterLUD,
    currentHolter,
    currentGxt,
    currentEcgs,
    gxtLUD,
    ecgLoading,
    gxtLoading,
    holterLoading,
    stressEchoGxtTrigger,
}) => {
    const dispatch = useDispatch()
    const [value, setValue] = React.useState(0)

    // const [expanded, setExpanded] = React.useState(false);

    // const handleChange = (panel) => (event, isExpanded) => {
    //   // // console.log("event", isExpanded)
    //   setExpanded(isExpanded ? panel : false);
    // };

    const handleChange = (event, newValue) => {
        setValue(newValue)
        getInitialValue(newValue)
    }

    const getInitialValue = (val) => {
        const months = moment().subtract(60, 'months').calendar()
        const proposedDate = moment(months).format(DATE_FORMAT.YYYY_MM_DD) + 'T00:00:00.000Z'

        switch (val) {
            case 0:
                const dataEcgsList = {
                    patientId: patientId,
                }
                const payloadEcg = {
                    patientId: patientId,
                    type: currentEcgs,
                    date: proposedDate,
                }
                dispatch(getEcgsData(payloadEcg, token))
                dispatch(getEcgsListData(dataEcgsList, token))
                break
            case 1:
                const payload = {
                    patientId: patientId,
                    type: currentHolter,
                    date: proposedDate,
                }
                const dataHolterList = {
                    patientId: patientId,
                }
                dispatch(getHolterData(payload, token))
                dispatch(getHolterListData(dataHolterList, token))
                break
            case 3:
                const dataGxtList = {
                    patientId: patientId,
                }
                const payloadGxt = {
                    patientId: patientId,
                    type: currentGxt,
                    date: proposedDate,
                }
                dispatch(getGxtData(payloadGxt, token))
                dispatch(getGxtListData(dataGxtList, token))
                break
            default:
                return null
        }
    }
    const GetLastUpdated = (val) => {
        switch (val) {
            case 0:
                return ecgLUD !== '' ? `Refreshed ${moment.parseZone(ecgLUD).format('MMM DD, YYYY')}` : null
            case 1:
                return holterLUD !== ''
                    ? `Refreshed ${moment.parseZone(holterLUD).format('MMM DD, YYYY')}`
                    : null
            case 2:
                return gxtLUD !== '' ? `Refreshed ${moment.parseZone(gxtLUD).format('MMM DD, YYYY')}` : null
            default:
                return null
        }
    }

    const OpenModal = (val) => {
        switch (val) {
            case 0:
                const sixtyMonth = moment().subtract(60, 'months').calendar()
                const proposedDate = moment(sixtyMonth).format(DATE_FORMAT.YYYY_MM_DD) + 'T00:00:00.000Z'
                const payload0 = {
                    patientId: patientId,
                    date: proposedDate,
                }
                dispatch(getEcgsDocument(payload0, token))
                dispatch(toggleEcgModal(true))
                break
            case 1:
                const payload1 = {
                    patientId: patientId,
                }
                dispatch(getHolterDocument(payload1, token))
                dispatch(toggleHolterModal(true))
                break
            case 2:
                const payload2 = {
                    patientId: patientId,
                }
                dispatch(getGxtDocument(payload2, token))
                dispatch(toggleGxtModal(true))
                break
            default:
                dispatch(toggleEcgModal(true))
        }
    }

    const modalExpandHelper = (val) => {
        switch (val) {
            case 0:
                if (!!ecgLoading) return true
                else return false
            case 1:
                if (!!gxtLoading) return true
                else return false
            case 2:
                if (!!holterLoading) return true
                else return false
            default:
                return false
        }
    }
    useEffect(() => {
        if (!!stressEchoGxtTrigger) {
            handleChange(null, 2)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stressEchoGxtTrigger])

    return (
        <div>
            <Accordion
                className="dashboard-accordion"
                sx={{ boxShadow: 'none' }}
                expanded={true}
                // onChange={handleChange('panel1')}
            >
                <AccordionSummary
                    sx={{ background: '#F3F4F6', boxShadow: 'none', borderRadius: '0.25rem' }}
                    // expandIcon={<ExpandMoreIcon  />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography
                        className="gray-600 cursor-default"
                        sx={{
                            width: '100%',
                            color: 'text.secondary',
                            textAlign: 'right',
                            fontSize: '0.875rem',
                        }}
                    >
                        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                            <AntTab label="ECG" />
                            <AntTab label="Holter" />
                            <AntTab label="GXT" />
                        </AntTabs>
                    </Typography>

                    <Box
                        className="d-flex justify-content-end align-items-center gray-600 cursor-default"
                        sx={{
                            width: '100%',
                            color: 'text.secondary',
                            textAlign: 'right',
                            fontSize: '0.85rem',
                        }}
                    >
                        <Box className="me-1 cursor-pointer" sx={{ mb: '2px' }}>
                            <InfoBox />
                        </Box>
                        {GetLastUpdated(value)}
                        <span
                            className={clsx(
                                'ms-2 me-1 cursor-pointer',
                                modalExpandHelper(value) ? 'cursor-not-allowed' : 'cursor-pointer'
                            )}
                        >
                            <img
                                onClick={() => (modalExpandHelper(value) ? null : OpenModal(value))}
                                style={{
                                    filter: modalExpandHelper(value) ? 'grayscale(80)' : 'grayscale(0%)',
                                }}
                                src="/images/right-top-arrow.svg"
                                alt="go to icon"
                            />
                        </span>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    {value === 0 && (
                        <>
                            <Ecg />
                        </>
                    )}
                    {value === 1 && (
                        <>
                            <Holter />
                        </>
                    )}
                    {value === 2 && (
                        <>
                            <Gxt />
                        </>
                    )}
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    ecgLUD: state.ecgLists.lud,
    holterLUD: state.holterList.lud,
    gxtLUD: state.gxtList.lud,
    currentHolter: state.holter.currentHolter,
    currentGxt: state.gxt.currentGxt,
    currentEcgs: state.ecg.currentEcgs,
    ecgLoading: state.ecgLists.ecgLoading,
    gxtLoading: state.gxtList.gxtLoading,
    holterLoading: state.holterList.holterLoading,
    stressEchoGxtTrigger: state.stressEcho.stressEchoGxtTrigger,
})

const mapDispatchToProps = (dispatch) => {
    return {
        getEcgsDocument: (payload, token) => dispatch(getEcgsDocument(payload, token)),
        toggleHolterModal: (payload) => dispatch(toggleHolterModal(payload)),
        toggleGxtModal: (payload) => dispatch(toggleGxtModal(payload)),
        getEcgsListData: (payload, token) => dispatch(getEcgsListData(payload, token)),
        getHolterListData: (payload, token) => dispatch(getHolterListData(payload, token)),
        getGxtListData: (payload, token) => dispatch(getGxtListData(payload, token)),
        getGxtDocument: (payload, token) => dispatch(getGxtDocument(payload, token)),
        getHolterData: (payload, token) => dispatch(getHolterData(payload, token)),
        getGxtData: (payload, token) => dispatch(getGxtData(payload, token)),
        getEcgsData: (payload, token) => dispatch(getEcgsData(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
