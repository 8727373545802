import PropTypes from 'prop-types'
import clsx from 'clsx'
import axios from 'axios'
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import variables from '../../../theme/variables.scss'
import { styled } from '@mui/material/styles'
import _ from 'lodash'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import CardiacHistoryTable from './CardiacHistoryTable'
import MedicalForm from '../cardiac-form/index'
import DeleteIcon from '@mui/icons-material/Delete'
import DeleteDialog from '../../adverse-event/adverse-modal/DeleteDialog'
import {
    toggleCardiacHistoryModal,
    toggleDeactivateModal,
    toggleSessionModal,
} from '../../../redux/modals/toggleModal.actions'
import { setAlert } from '../../../redux/alert/alert.action'
import {
    getCardiacHistoryListData,
    getPastCardiacHistoryListData,
    getCardiacHistoryDiagnosisData,
    setCurrentCardiacHistory,
    setCurrentSubDiagnosis,
    deleteCardiaclHistoryDiagnosis,
    setCardiacHistoryModalType,
} from '../../../redux/cardiacHistory/cardiacHistory.action'
import { getRecommendationsListData } from '../../../redux/recommendations/recommendations.action'
import { sleep } from '../../../util'
const filter = createFilterOptions()

const AntTabs = styled(Tabs)({
    borderBottom: 'solid',
    borderWidth: '1px',
    borderColor: variables.gray200,
    '& .MuiTabs-indicator': {
        backgroundColor: variables.purple,
    },
})

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: 500,
    marginRight: theme.spacing(2),
    padding: 0,
    color: variables.gray600,
    fontFamily: 'Inter',
    '&:hover': {
        color: variables.purple,
        opacity: 1,
    },
    '&.Mui-selected': {
        color: variables.purple,
        fontWeight: 600,
    },
    '&.Mui-focusVisible': {
        backgroundColor: variables.blue100,
    },
}))

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),
        borderBottom: 'none',
        minHeight: '500px',
        display: 'flex',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(0),
        position: 'absolute',
        bottom: 20,
        right: 25,
    },
    '& .MuiDialogTitle-root': {
        background: variables.gray100,
        fontWeight: 600,
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export const Index = ({
    patientId,
    token,
    cardiacHistoryModal,
    currentCardiacHistory,
    currentSubDiagnosis,
    cardiacHistoryListData,
    cardiacHistoryListLoading,
    cardiacHistoryDiagnosisData,
    cardiacHistoryDiagnosisLoading,
    pastCardiacHistoryListData,
    pastCardiacHistoryListLoading,
    cardiacHistoryModalType,
}) => {
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false) // eslint-disable-line no-unused-vars
    const [alignment, setAlignment] = React.useState(cardiacHistoryModalType ?? 'Details')
    const [showTable, setShowTable] = React.useState(false)
    const [eventID, setEventID] = React.useState(currentSubDiagnosis ?? '')
    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)
    const [deleteLoader, setDeleteLoader] = React.useState(false)

    const [value, setValue] = React.useState(currentCardiacHistory ?? 'cardiac') //Tab value

    /* search States */
    const [searchOptions, setSearchOptions] = React.useState([])
    const [searchValue, setSearchValue] = React.useState(searchOptions[0] ?? null)
    const [inputSearchValue, setInputSearchValue] = React.useState('')
    const [searchLoading, setSearchLoading] = React.useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                setSearchLoading(true)
                const result = await axios.get(
                    `${process.env.REACT_APP_CARD_URL}/diagnosis-history/search?type=cardiac&searchTerm=${inputSearchValue}`,
                    {
                        headers: {
                            accessToken: token,
                        },
                    }
                )
                if (result?.data?.status === 200) {
                    setSearchOptions(result?.data?.output ?? [])
                    setSearchLoading(false)
                } else if (result?.data?.status === 401) {
                    dispatch(toggleSessionModal(true, result?.data?.error))
                    setSearchLoading(false)
                } else if (result?.data?.status === 403) {
                    dispatch(toggleDeactivateModal(true))
                    setSearchLoading(false)
                }
            } catch (err) {
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: `${
                            err?.message ?? 'Something went wrong!'
                        }. Unable to fetch search results. Please! try again.`,
                        alertOpen: true,
                    })
                )
                setSearchLoading(false)
            }
        }

        const cleanTimeout = setTimeout(() => {
            if (inputSearchValue?.length > 0) {
                fetchData()
            }
        }, 2000)
        return () => {
            clearTimeout(cleanTimeout)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputSearchValue])

    //when tabs changes resets table values
    useEffect(() => {
        setShowTable(false)
        setEventID('')
        setValue(currentCardiacHistory)
    }, [currentCardiacHistory])

    const handleTabChange = (event, newValue) => {
        setValue(newValue)
        getInitialValue(newValue)
    }

    const getInitialValue = (val) => {
        switch (val) {
            case 'cardiac':
                const payloadCardiacHistoryList = {
                    patientId: patientId,
                    type: 'cardiac',
                    status: 'active',
                }

                dispatch(setCurrentCardiacHistory('cardiac'))
                dispatch(getCardiacHistoryListData(payloadCardiacHistoryList, token))
                break
            case 'past':
                const payloadPastCardiacHistoryList = {
                    patientId: patientId,
                    type: 'cardiac',
                    status: 'past',
                }

                dispatch(setCurrentCardiacHistory('past'))
                dispatch(getPastCardiacHistoryListData(payloadPastCardiacHistoryList, token))
                break

            default:
                return null
        }
    }

    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick') return

        dispatch(setCardiacHistoryModalType('Details'))
        dispatch(toggleCardiacHistoryModal(false))
    }

    const handleCardiacEvent = (diagnosis) => {
        setShowTable(true)
        setEventID(diagnosis)
        const payload = {
            patientId: patientId,
            name: diagnosis,
            type: 'cardiac',
        }
        dispatch(getCardiacHistoryDiagnosisData(payload, token))
    }

    useEffect(() => {
        if (currentSubDiagnosis) {
            handleCardiacEvent(currentSubDiagnosis)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /* delete cardiac diagnosis */
    const handleDelete = async () => {
        setDeleteLoader(true)
        const deletePayload = {
            patientId: patientId,
            type: 'cardiac',
            diagnosis: {
                _id: cardiacHistoryDiagnosisData?._id,
                name: cardiacHistoryDiagnosisData?.name,
            },
        }
        dispatch(deleteCardiaclHistoryDiagnosis(deletePayload, token))

        await sleep(800)
        setShowTable(false)
        setEventID('')
        const payloadCardiacHistoryRefresh = {
            patientId: patientId,
            type: 'cardiac',
            status: currentCardiacHistory === 'cardiac' ? 'active' : 'past',
        }
        const payloadRecommendationList = {
            patientId: patientId,
        }
        if (currentCardiacHistory === 'cardiac') {
            dispatch(getCardiacHistoryListData(payloadCardiacHistoryRefresh, token))
        } else {
            dispatch(getPastCardiacHistoryListData(payloadCardiacHistoryRefresh, token))
        }
        dispatch(getRecommendationsListData(payloadRecommendationList, token))
        setDeleteModalOpen(false)
        setDeleteLoader(false)
        clearTimeout(sleep)
    }
    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth="lg"
                aria-labelledby="small-modal"
                open={cardiacHistoryModal}
            >
                <DialogContent dividers style={{ minHeight: '80vh' }}>
                    <div
                        className="bg-gray-100 w-25 p-3 overflow-auto"
                        style={{
                            position: 'relative',
                        }}
                    >
                        <Typography className="fw-bold cursor-default gray-900 fs-5 mb-2">
                            Cardiac History
                        </Typography>

                        {/* tabs */}
                        {alignment === 'Details' && (
                            <Typography
                                className="gray-600 cursor-default"
                                sx={{
                                    width: '100%',
                                    color: 'text.secondary',
                                    fontSize: '0.85rem',
                                }}
                            >
                                <AntTabs value={value} onChange={handleTabChange} aria-label="ant example">
                                    <AntTab label="Active" value="cardiac" />
                                    <AntTab label="Past" value="past" />
                                </AntTabs>
                            </Typography>
                        )}

                        {alignment === 'Details' ? (
                            <div
                                className="overflow-auto mt-2"
                                style={{
                                    maxHeight: '540px',
                                    backgroundColor: variables.white100,
                                    borderRadius: '5px',
                                    border: '1px solid',
                                    borderColor: variables.lightGray1,
                                    minHeight: '420px',
                                    overflow: 'auto',
                                }}
                            >
                                {(currentCardiacHistory === 'cardiac'
                                    ? cardiacHistoryListLoading
                                    : pastCardiacHistoryListLoading) === false ? (
                                    (currentCardiacHistory === 'cardiac'
                                        ? cardiacHistoryListData
                                        : pastCardiacHistoryListData
                                    )?.length > 0 ? (
                                        (currentCardiacHistory === 'cardiac'
                                            ? cardiacHistoryListData
                                            : pastCardiacHistoryListData
                                        )?.map((diagnosisDetail, index) => {
                                            return (
                                                <p
                                                    key={index}
                                                    className={clsx(
                                                        eventID === diagnosisDetail?.name &&
                                                            'bg-indigo-50 fw-bold',
                                                        'fs-8 ps-2 py-2 mb-0 gray-900 cursor-pointer text-truncate text-capitalize'
                                                    )}
                                                    onClick={() => handleCardiacEvent(diagnosisDetail?.name)}
                                                >
                                                    {!!diagnosisDetail?.customLabel
                                                        ? diagnosisDetail?.customLabel ?? '-'
                                                        : diagnosisDetail?.name ?? '-'}
                                                </p>
                                            )
                                        })
                                    ) : (
                                        <p className="mt-3 text-center p-1 fs-8 gray-900">No records found</p>
                                    )
                                ) : (
                                    <div className="loader">
                                        <img src="/loader.gif" alt="loader" />
                                    </div>
                                )}
                            </div>
                        ) : null}

                        {/* add/update Buttons */}
                        {alignment === 'Details' && (
                            <Stack
                                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                                sx={{
                                    position: 'absolute',
                                    bottom: '5%',
                                    width: '.9',
                                }}
                                spacing={1}
                            >
                                <Button
                                    onClick={() => setAlignment('Update')}
                                    size="small"
                                    variant="contained"
                                    className="btn-secondary"
                                    fullWidth={true}
                                    disabled={
                                        eventID === '' ||
                                        !!cardiacHistoryDiagnosisLoading ||
                                        !!(
                                            cardiacHistoryDiagnosisData?.displayedDiagnosisCreateSource ===
                                            'generatedletters'
                                        )
                                            ? true
                                            : false
                                    }
                                >
                                    Update
                                </Button>
                                <Button
                                    size="small"
                                    onClick={() => {
                                        setAlignment('Add')
                                        setSearchValue(null)
                                    }}
                                    variant="contained"
                                    className="btn-light"
                                    fullWidth={true}
                                    startIcon={<AddIcon />}
                                >
                                    Add new
                                </Button>
                            </Stack>
                        )}
                    </div>

                    <div className="w-75 p-3 overflow-auto">
                        <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={4}
                            >
                                <Typography className="gray-900 cursor-default fw-bold fs-5 mt-2 mb-2">
                                    {alignment === 'Details' && !!eventID
                                        ? 'Details'
                                        : alignment === 'Update'
                                        ? 'Update'
                                        : alignment === 'Add'
                                        ? 'Add New'
                                        : 'Details'}
                                </Typography>
                                {alignment === 'Add' ? (
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <Autocomplete
                                            size="small"
                                            // disablePortal
                                            id="free-solo-with-text-demo"
                                            value={searchValue}
                                            onChange={(event, newValue) => {
                                                if (typeof newValue === 'string') {
                                                    setSearchValue({ name: newValue })
                                                    setOpen(false)
                                                } else if (newValue && newValue.inputValue) {
                                                    // Create a new value from the user input
                                                    setSearchValue({ name: newValue.inputValue })
                                                    setOpen(false)
                                                } else {
                                                    setSearchValue(newValue)
                                                    setOpen(false)
                                                }
                                                setOpen(false)
                                            }}
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params)
                                                const { inputValue } = params
                                                // flag for the creation of a new value
                                                const isExisting = options.some(
                                                    (option) => inputValue === option.name
                                                )

                                                if (inputValue !== '' && !isExisting && !searchLoading) {
                                                    filtered.push({
                                                        inputValue,
                                                        name: `Add "${inputValue}"`,
                                                    })
                                                }
                                                return filtered
                                            }}
                                            selectOnFocus
                                            clearOnBlur
                                            handleHomeEndKeys
                                            options={searchOptions}
                                            getOptionLabel={(option) => {
                                                // Value selected with enter, right from the input
                                                if (typeof option === 'string') {
                                                    return option
                                                }
                                                // Add "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.inputValue
                                                }
                                                return ` ${option?.code ? `${option?.code} - ` : ''}${
                                                    option?.name
                                                }`
                                            }}
                                            renderOption={(props, option) => (
                                                <li {...props}>{option.name}</li>
                                            )}
                                            sx={{ width: 300 }}
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField {...params} label="Search Diagnosis" />
                                            )}
                                            inputValue={inputSearchValue}
                                            onInputChange={(event, newInputValue) => {
                                                setInputSearchValue(newInputValue)
                                            }}
                                            loading={searchLoading}
                                            loadingText={'Loading...'}
                                            isOptionEqualToValue={(option, value) =>
                                                option?._id === value?._id
                                            }
                                        />
                                    </Stack>
                                ) : null}{' '}
                            </Stack>

                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                variant="contained"
                                sx={{
                                    float: 'right',
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                        {alignment === 'Details' ? (
                            <>
                                {cardiacHistoryDiagnosisLoading === true ? (
                                    <div
                                        className="d-flex justify-content-center align-items-start"
                                        style={{
                                            maxHeight: '550px',
                                        }}
                                    >
                                        <div className="loader">
                                            <img src="/loader.gif" alt="loader" />
                                        </div>
                                    </div>
                                ) : !_.isEmpty(cardiacHistoryDiagnosisData) && !!showTable && !!eventID ? (
                                    <div className="mt-1">
                                        <Typography
                                            className="gray-900 cursor-default fw-bold fs-5 mb-2 text-capitalize"
                                            sx={{
                                                width: '95%',
                                            }}
                                        >
                                            {cardiacHistoryDiagnosisData?.name ?? '-'}{' '}
                                            {cardiacHistoryDiagnosisData?.icd10Code && (
                                                <span className="text-uppercase">
                                                    ({cardiacHistoryDiagnosisData?.icd10Code})
                                                </span>
                                            )}
                                            {!!(
                                                cardiacHistoryDiagnosisData?.displayedDiagnosisCreateSource ===
                                                'generatedletters'
                                            ) ? (
                                                <Tooltip
                                                    title={
                                                        <Box
                                                            sx={{
                                                                whiteSpace: 'pre-line',
                                                            }}
                                                        >
                                                            This diagnosis is from a generated letter.
                                                            {!!cardiacHistoryDiagnosisData?.rawText
                                                                ? `\n Cardiac History from the letter :- 
                                                                    ${cardiacHistoryDiagnosisData?.rawText}`
                                                                : ''}
                                                        </Box>
                                                    }
                                                >
                                                    <Chip
                                                        label={'G'}
                                                        size="small"
                                                        sx={{
                                                            height: '50%',
                                                            borderRadius: '20%',
                                                            fontSize: '10px',
                                                            fontWeight: 600,
                                                            color: variables.white100,
                                                            backgroundColor: variables.purple,
                                                            cursor: 'pointer',
                                                            mx: 1,
                                                        }}
                                                    />
                                                </Tooltip>
                                            ) : null}
                                            {!!cardiacHistoryDiagnosisData?.customLabel ? (
                                                <>
                                                    <Chip
                                                        label={
                                                            cardiacHistoryDiagnosisData?.customLabel ?? null
                                                        }
                                                        sx={{
                                                            minWidth: '60px',
                                                            mx: 1,
                                                            textTransform: 'capitalize',
                                                            fontSize: '12px',
                                                            fontWeight: 600,
                                                            color: variables.white100,
                                                            backgroundColor: variables.purple,
                                                        }}
                                                    />
                                                </>
                                            ) : null}
                                            <IconButton
                                                sx={{ mt: -0.5 }}
                                                size="small"
                                                aria-label="delete"
                                                onClick={() => setDeleteModalOpen(true)}
                                                disabled={
                                                    !!(
                                                        cardiacHistoryDiagnosisData?.displayedDiagnosisCreateSource ===
                                                        'generatedletters'
                                                    )
                                                }
                                            >
                                                <DeleteIcon
                                                    sx={{
                                                        color: !!(
                                                            cardiacHistoryDiagnosisData?.displayedDiagnosisCreateSource ===
                                                            'generatedletters'
                                                        )
                                                            ? variables.lightGray1
                                                            : variables.red600,
                                                        cursor: !!(
                                                            cardiacHistoryDiagnosisData?.displayedDiagnosisCreateSource ===
                                                            'generatedletters'
                                                        )
                                                            ? 'not-allowed'
                                                            : 'pointer',
                                                        pointerEvents: 'auto',
                                                    }}
                                                />
                                            </IconButton>
                                        </Typography>
                                        <CardiacHistoryTable
                                            tableData={cardiacHistoryDiagnosisData}
                                            eventID={eventID}
                                        />

                                        <DeleteDialog
                                            open={deleteModalOpen}
                                            setOpen={setDeleteModalOpen}
                                            handleDelete={handleDelete}
                                            handleClose={() => setDeleteModalOpen(false)}
                                            loading={deleteLoader}
                                        />
                                    </div>
                                ) : !!showTable && !!eventID ? (
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                            border: '1px solid',
                                            borderColor: variables.gray200,
                                            borderRadius: '5px',
                                            height: '550px',
                                            fontSize: '12px',
                                        }}
                                    >
                                        <p style={{ fontSize: '12px' }}>No data found</p>
                                    </div>
                                ) : (
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                            border: '1px solid',
                                            borderColor: variables.gray200,
                                            borderRadius: '5px',
                                            height: '550px',
                                            fontSize: '12px',
                                        }}
                                    >
                                        <div>No Data Selected</div>
                                    </div>
                                )}
                            </>
                        ) : alignment === 'Add' || alignment === 'Update' ? (
                            <MedicalForm
                                setAlignment={setAlignment}
                                alignment={alignment}
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                                advFormData={alignment === 'Update' ? cardiacHistoryDiagnosisData : null}
                                advType={alignment === 'Update' ? eventID : null}
                                handleCardiacEvent={handleCardiacEvent}
                            />
                        ) : null}
                    </div>
                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        patientId: state.patient.patientData.patientId,
        token: state.loginData.loginResponse.accessToken,
        cardiacHistoryModal: state.toggleModals.cardiacHistoryModal,
        currentCardiacHistory: state?.cardiacHistory?.currentCardiacHistory,
        currentSubDiagnosis: state?.cardiacHistory?.currentSubDiagnosis,
        cardiacHistoryListData: state?.cardiacHistory?.cardiacHistoryListData,
        cardiacHistoryListLoading: state?.cardiacHistory?.cardiacHistoryListLoading,
        cardiacHistoryDiagnosisData: state?.cardiacHistory?.cardiacHistoryDiagnosisData,
        cardiacHistoryDiagnosisLoading: state?.cardiacHistory?.cardiacHistoryDiagnosisLoading,
        pastCardiacHistoryListData: state?.cardiacHistory?.pastCardiacHistoryListData,
        pastCardiacHistoryListLoading: state?.cardiacHistory?.pastCardiacHistoryListLoading,
        cardiacHistoryModalType: state?.cardiacHistory?.cardiacHistoryModalType,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleCardiacHistoryModal: (payload) => dispatch(toggleCardiacHistoryModal(payload)),
        setAlert: (payload) => dispatch(setAlert(payload)),
        setCurrentCardiacHistory: (payload) => dispatch(setCurrentCardiacHistory(payload)),
        getCardiacHistoryListData: (payload, token) => dispatch(getCardiacHistoryListData(payload, token)),
        getPastCardiacHistoryListData: (payload, token) =>
            dispatch(getPastCardiacHistoryListData(payload, token)),
        getCardiacHistoryDiagnosisData: (payload, token) =>
            dispatch(getCardiacHistoryDiagnosisData(payload, token)),
        setCurrentSubDiagnosis: (payload) => dispatch(setCurrentSubDiagnosis(payload)),
        getRecommendationsListData: (payload, token) => dispatch(getRecommendationsListData(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
