import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import '../../components/interval-history/interval-modal/interval-components/inputs.scss'
import variables from '../../theme/variables.scss'
import { connect, useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import PatientListTable from './PatientListTable'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { toggleAppointmentModal, togglePatientProfileModal } from '../../redux/modals/toggleModal.actions'
import ClearIcon from '@mui/icons-material/Clear'

import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import { setAlert } from '../../redux/alert/alert.action'
import { getAppointmentPdf } from '../../redux/searchFlow/screenFlow/screenFlow.action'
import { blobToBase64, getS3File, useQuery } from '../../util'
import Tooltip from '@mui/material/Tooltip'
import Dashboard from '../../components/dashboard/index'
import { initialValueFetchHandler } from '../appointment-list/index'
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        minHeight: '500px',
        minWidth: '500px',
        display: 'flex',
        // background: variables.gray100,
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialogTitle-root': {
        background: variables.gray100,
        fontWeight: 600,
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export const AppointmentListModal = ({
    token,
    appointmentModal,
    upcomingPatientAppointmentDetails,
    appointmentPdf,
    appointmentPdfLoading,
}) => {
    const dispatch = useDispatch()
    let query = useQuery()
    const [searchValue, setSearchValue] = React.useState('')
    const [downloadPdfFlag, setDownloadPdfFlag] = React.useState(false)

    const tableHeaderData = [
        {
            name: 'Name',
            align: null,
        },
        { name: 'Upcoming Appointment', align: 'right' },
        { name: 'Phone Number', align: 'right' },
        { name: 'office provider', align: 'right' },
    ]

    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick') return
        dispatch(toggleAppointmentModal(false))
    }

    const upcomingApppointmentData = (
        upcomingPatientAppointmentDetails?.list?.length === 0 ? [] : upcomingPatientAppointmentDetails?.list
    )?.map((item) => {
        return {
            patientId: item?.id,
            name: item?.name,
            date: item?.date,
            phNo: item?.phone ?? 'N/A',
            officeProvider: item?.providerDetails?.firstName + ' ' + item?.providerDetails?.lastName,
            showPopup: true,
            popupFn: () => {
                dispatch(togglePatientProfileModal(true))
                initialValueFetchHandler(item?.patientId, item?.id, dispatch, token)
            },
        }
    })

    const [filteredData, setFilteredData] = React.useState(upcomingApppointmentData ?? [])

    const handleSearch = (searchTerm) => {
        if (searchTerm?.length > 0) {
            const filteredData = upcomingApppointmentData?.filter((item) => {
                return item?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
            })

            setFilteredData(filteredData)
        } else {
            setFilteredData([])
        }
    }

    useEffect(() => {
        const pdfPayload = {
            collectionName: query.get('trial_name'),
            partialMatch: !!(upcomingPatientAppointmentDetails?.listType === 'matches') ? false : true,
        }
        dispatch(getAppointmentPdf(pdfPayload, token))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handlePdfDownload = () => {
        setDownloadPdfFlag(true)
        getS3File(appointmentPdf?.key)
            .then((res) => {
                blobToBase64(res?.Body).then((usablePdf) => {
                    let a = document.createElement('a')
                    a.href = usablePdf
                    a.download = `${
                        !!(upcomingPatientAppointmentDetails?.listType === 'matches')
                            ? `upcoming-${upcomingPatientAppointmentDetails?.listType}-appointment-list.pdf`
                            : !!(upcomingPatientAppointmentDetails?.listType === 'partial matches')
                            ? `upcoming-partial-matches-appointment-list.pdf`
                            : 'upcoming-appointment-list.pdf'
                    }`
                    a.click()
                })
            })
            .catch((err) => {
                console.log('ERROR in Handle document PDF', err)
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: `Error in downloading PDF. Please try again.`,
                        alertOpen: true,
                    })
                )
            })
            .finally(() => {
                setDownloadPdfFlag(false)
            })
    }

    return (
        <div>
            <Dashboard />
            <BootstrapDialog
                onClose={handleClose}
                maxWidth="lg"
                fullWidth={true}
                open={!!appointmentModal}
                PaperProps={{
                    style: {
                        minHeight: '90%',
                        maxHeight: '90%',
                    },
                }}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Typography className="fs-7 gray-600 fw-light">
                        {upcomingPatientAppointmentDetails?.title} Upcoming Appointments
                    </Typography>
                    <Typography className="fs-6 fw-bold text-uppercase">
                        {upcomingPatientAppointmentDetails?.listType} Patient List
                    </Typography>
                </BootstrapDialogTitle>
                <DialogContent dividers sx={{ padding: '0 !important', overflow: 'auto' }}>
                    <Box sx={{ px: 2, pb: 2, width: '100%' }}>
                        <Box className="pb-4 mt-1">
                            <Box
                                sx={{
                                    width: '100%',

                                    pt: 2,
                                    py: 1,
                                    display: 'flex',
                                    justifyContent: 'start',
                                    alignItems: 'center',
                                    gap: 1,
                                }}
                            >
                                <TextField
                                    fullWidth
                                    id="appointment-search"
                                    placeholder="Search Patient"
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            py: '3.5px !important',
                                            '& fieldset': {
                                                borderColor: variables.gray200,
                                                borderRadius: '8px',
                                                borderWidth: '2px !important',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: variables.indigo300,
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: variables.indigo500,
                                            },
                                        },
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <>
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            </>
                                        ),
                                        endAdornment: !!searchValue ? (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="delete search value"
                                                    onClick={() => {
                                                        setSearchValue('')
                                                        setFilteredData(upcomingApppointmentData ?? [])
                                                    }}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ) : null,
                                    }}
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    onKeyUp={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSearch(searchValue)
                                        }
                                        if (e.target.value === '') {
                                            if (e.key === 'Backspace') {
                                                setFilteredData(upcomingApppointmentData ?? [])
                                            }
                                        }
                                    }}
                                />

                                <Box>
                                    <Box sx={{ pl: 1 }}>
                                        <Button
                                            size="large"
                                            onClick={() => {
                                                handleSearch(searchValue)
                                            }}
                                            variant="contained"
                                            className="btn-secondary"
                                        >
                                            Search
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        my: 0.2,
                                        p: 0.5,
                                        background: variables.gray100,
                                        maxWidth: 'fit-content',
                                        borderRadius: '4px',
                                    }}
                                >
                                    <InfoOutlinedIcon fontSize="small" sx={{ color: variables.indigo400 }} />
                                    <Typography variant="span" className="px-1 fs-8 gray-600 fw-normal ">
                                        The table below shows the upcoming appointments for patients in the
                                        next 30 days.
                                    </Typography>
                                </Box>

                                <Tooltip
                                    sx={{ textTransform: 'capitalize' }}
                                    title={`Download ${upcomingPatientAppointmentDetails?.listType} appointment list`}
                                >
                                    <Button
                                        sx={{ width: '9rem !important' }}
                                        size="small"
                                        disabled={!!downloadPdfFlag || !!appointmentPdfLoading}
                                        onClick={handlePdfDownload}
                                        variant="contained"
                                        className="btn-secondary"
                                        startIcon={
                                            !!downloadPdfFlag ? (
                                                <HourglassBottomIcon />
                                            ) : (
                                                <DownloadForOfflineIcon />
                                            )
                                        }
                                    >
                                        {!!downloadPdfFlag ? 'Processing ...' : 'Download PDF'}
                                    </Button>
                                </Tooltip>
                            </Box>
                            <Divider className="my-2" sx={{ backgroundColor: variables.gray600 }} />

                            <PatientListTable
                                tableHeaderData={tableHeaderData}
                                tableBodyData={filteredData}
                                appointmentKeyFlag={true}
                            />
                        </Box>
                    </Box>
                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.loginData.loginResponse.accessToken,
        appointmentModal: state?.toggleModals?.appointmentModal,
        upcomingPatientAppointmentDetails:
            state?.screenFlowPortalClinicalTrial?.upcomingPatientAppointmentListData,
        appointmentPdf: state?.screenFlowPortalClinicalTrial?.appointmentPdf,
        appointmentPdfLoading: state?.screenFlowPortalClinicalTrial?.appointmentPdfLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleAppointmentModal: (payload) => dispatch(toggleAppointmentModal(payload)),
        setAlert: (payload) => dispatch(setAlert(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentListModal)
