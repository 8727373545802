import React from 'react'
import { connect, useDispatch } from 'react-redux'
import clsx from 'clsx'
import variables from '../../../theme/variables.scss'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Menu from '@mui/material/Menu'
import Tooltip from '@mui/material/Tooltip'
import LabelIcon from '@mui/icons-material/Label'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import axios from 'axios'

import {
    toggleDeactivateModal,
    toggleSessionModal,
    toggleCardiacHistoryModal,
} from '../../../redux/modals/toggleModal.actions'
import { setAlert } from '../../../redux/alert/alert.action'

import {
    getCardiacHistoryListData,
    getPastCardiacHistoryListData,
    setCurrentCardiacHistory,
    getCardiacHistoryDiagnosisData,
    setCurrentSubDiagnosis,
} from '../../../redux/cardiacHistory/cardiacHistory.action'
import { getRecommendationsListData } from '../../../redux/recommendations/recommendations.action'
import { cardiacTreatments } from '../../../util/formData'

const filter = createFilterOptions()

export const Index = ({
    token,
    patientId,
    setAlignment,
    alignment,
    searchValue,
    setSearchValue,
    advFormData,
    advType,
    currentCardiacHistory,
    handleCardiacEvent,
}) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = React.useState(false)
    /* Update alignments fetch options */
    /* commented till updated list of sub diagnosis is not available */
    // const [subDiagnosisOptions, setSubDiagnosisOptions] = React.useState([])

    /* custom label */
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [labelVal, setLabelVal] = React.useState(advFormData?.customLabel ?? '')
    const open = Boolean(anchorEl)

    // Label Popup
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    // Label Popup
    const handleClose = () => {
        setAnchorEl(null)
    }

    //Validation
    const validationSchema = Yup.object().shape({
        /* commented to skip validation and allow for direct submission */
        // diagnosis: Yup.object().shape({
        //     subDiagnosis: Yup.string().required('Sub-diagnosis is required').nullable(),
        // }),
    })

    /* onSubmit Handler */
    const onSumbitHandler = (values) => {
        setLoading(true)
        const payload = { patientId: patientId, type: 'cardiac', diagnosis: values?.diagnosis }

        axios({
            method: alignment === 'Update' ? 'put' : 'post',

            url: `${process.env.REACT_APP_CARD_URL}/diagnosis-history`,
            data: payload,
            headers: {
                accessToken: token,
            },
        })
            .then((response) => {
                if (response?.data?.status === 200) {
                    setLoading(false)
                    dispatch(
                        setAlert({
                            alertType: 'success',
                            alertMsg: `Cardiac History ${
                                alignment === 'Update' ? 'Updated' : 'Added'
                            } Successfully`,
                            alertOpen: true,
                        })
                    )

                    const payloadCardiacHistoryList = {
                        patientId: patientId,
                        type: 'cardiac',
                        status: !!payload?.diagnosis?.isPast ? 'past' : 'active',
                    }
                    const payloadRecommendationList = {
                        patientId: patientId,
                    }

                    if (!!payload?.diagnosis?.isPast) {
                        dispatch(getPastCardiacHistoryListData(payloadCardiacHistoryList, token))
                    } else {
                        dispatch(getCardiacHistoryListData(payloadCardiacHistoryList, token))
                    }
                    setAlignment('Details')
                    setCurrentSubDiagnosis(values?.diagnosis?.name)
                    handleCardiacEvent(values?.diagnosis?.name)
                    dispatch(setCurrentCardiacHistory(!!payload?.diagnosis?.isPast ? 'past' : 'cardiac'))
                    dispatch(getRecommendationsListData(payloadRecommendationList, token))
                } else if (response?.data?.status === 400) {
                    setLoading(false)
                    dispatch(
                        setAlert({
                            alertType: 'error',
                            alertMsg: `Error ${response?.data?.error?.errorCode ?? ' '} : ${
                                response?.data?.error?.message
                            }`,
                            alertOpen: true,
                        })
                    )
                } else if (response?.data?.status === 401) {
                    setLoading(false)
                    dispatch(toggleSessionModal(true, response?.data?.error))
                } else if (response?.data?.status === 403) {
                    setLoading(false)
                    dispatch(toggleDeactivateModal(true))
                }
            })
            .catch((err) => {
                setLoading(false)
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: `${err?.message ?? 'Something went wrong'}. Unable to ${
                            alignment === 'Update' ? 'update' : 'add'
                        } Cardiac History data. Please! try again.`,
                        alertOpen: true,
                    })
                )
            })
    }

    /* fetch form options on update button */
    /* -------commented till updated sub-diagnosis is not available------- */
    /*   const fetchUpdatedOptions = async () => {
        try {
            const result = await axios.get(
                `${process.env.REACT_APP_CARD_URL}/diagnosis-history/sub-diagnoses`,
                {
                    headers: {
                        accessToken: token,
                    },
                }
            )
            if (result?.data?.status === 200) {
                setSubDiagnosisOptions(result?.data?.output ?? [])
            } else if (result?.data?.status === 401) {
                dispatch(toggleSessionModal(true, result?.data?.error))
            } else if (result?.data?.status === 403) {
                dispatch(toggleDeactivateModal(true))
            }
        } catch (err) {
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg:
                        err?.message ??
                        'Something went wrong! Unable to fetch form options. Please! try again.',
                    alertOpen: true,
                })
            )
        }
    } */
    /* -------x------- */

    /* for dynamic options for sub diagnosis */
    /* -------commented till updated sub-diagnosis is not available------- */
    /* useEffect(() => {
        if (alignment === 'Update' || alignment === 'Add') fetchUpdatedOptions()
        // eslint-disable-next-line
    }, [alignment]) */

    /* -------x------- */

    // conditional class according to diagnosis
    const classOptionHandler = (diagnosisName) => {
        switch (diagnosisName) {
            case 'CAD':
                return ['CCS']

            case 'HFrEF':
                return ['NYHA']

            case 'AF':
                return ['CHADS']

            default:
                return ['CCS', 'NYHA', 'CHADS2']
        }
    }

    return (
        <div className="medication-class adverse-class">
            <Formik
                enableReinitialize={true}
                initialValues={
                    alignment === 'Add'
                        ? {
                              diagnosis: {
                                  name: searchValue?.name,
                                  icd10Code: searchValue?.code ?? '',
                                  subDiagnosis: null,
                                  diagnoseDate: null,
                                  treatment: null,
                                  riskFactor: [],
                                  cardiacState: {
                                      name: '',
                                      value: null,
                                  },
                                  customLabel: '',
                                  isPast: false,
                              },
                          }
                        : alignment === 'Update'
                        ? { diagnosis: advFormData }
                        : {}
                }
                validationSchema={validationSchema}
                onSubmit={onSumbitHandler}
                validateOnChange={false}
            >
                {({
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    isValid,
                    setValues,
                    setFieldValue,
                    isSubmitting,
                    setSubmitting,
                    setTouched,
                }) => (
                    <Form noValidate autoComplete="off">
                        {!!searchValue?.name || alignment === 'Update' ? (
                            <Stack
                                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                                justifyContent="flex-start"
                                alignItems="center"
                                sx={{ flexWrap: 'wrap' }}
                            >
                                <Box
                                    className="fs-5 mt-2 mb-2 fw-bold text-capitalize"
                                    sx={{ pr: 2 }}
                                    gutterBottom
                                >
                                    {alignment === 'Add'
                                        ? `${searchValue?.name ?? ''} ${
                                              !!searchValue?.code ? `(${searchValue?.code})` : ''
                                          }`
                                        : alignment === 'Update'
                                        ? `${advType} ${
                                              !!advFormData?.icd10Code ? `(${advFormData?.icd10Code})` : ''
                                          }`
                                        : null}
                                    <Tooltip
                                        title={
                                            !!values?.diagnosis?.customLabel ? 'Update Label' : 'Add Label'
                                        }
                                    >
                                        <Button
                                            id="basic-button"
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClick}
                                            sx={{ color: variables.purple }}
                                        >
                                            {!!values?.diagnosis?.customLabel ? 'Update' : 'Add '}
                                            <LabelIcon />
                                        </Button>
                                    </Tooltip>
                                    {!!values?.diagnosis?.customLabel ? (
                                        <Chip
                                            label={values?.diagnosis?.customLabel}
                                            sx={{
                                                minWidth: '60px',
                                                mx: 1,
                                                textTransform: 'capitalize',
                                                fontSize: '12px',
                                                fontWeight: 600,
                                                color: variables.white100,
                                                backgroundColor: variables.purple,
                                            }}
                                        />
                                    ) : null}
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                gap: 1,
                                                padding: 2,
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <TextField
                                                id={'diagnosis.customLabel'}
                                                name={'diagnosis.customLabel'}
                                                onChange={(e) => setLabelVal(e.target.value)}
                                                onBlur={handleBlur}
                                                autoComplete="off"
                                                placeholder={
                                                    !!values?.diagnosis?.customLabel
                                                        ? 'Update Label'
                                                        : 'Add Label'
                                                }
                                                InputProps={{
                                                    style: {
                                                        fontSize: '14px',
                                                        height: '30px',
                                                        borderColor: variables.lightGray1,
                                                    },
                                                }}
                                                value={labelVal}
                                            />
                                            <Stack
                                                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                                                justifyContent="space-evenly"
                                                alignItems="center"
                                                spacing={1}
                                            >
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    className="btn-primary"
                                                    onClick={() => {
                                                        setFieldValue('diagnosis.customLabel', labelVal)
                                                        handleClose()
                                                    }}
                                                >
                                                    {!!values?.diagnosis?.customLabel
                                                        ? 'Update Label'
                                                        : 'Add Label'}
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    size="small"
                                                    onClick={() => {
                                                        setFieldValue('diagnosis.customLabel', '')
                                                        setLabelVal('')
                                                        handleClose()
                                                    }}
                                                >
                                                    Reset
                                                </Button>
                                            </Stack>
                                        </Box>
                                    </Menu>
                                </Box>
                            </Stack>
                        ) : null}

                        {!!searchValue?.name || alignment === 'Update' ? (
                            <>
                                <Grid container direction="row" rowSpacing={3} columnSpacing={4}>
                                    <Grid item xs={4}>
                                        <Typography className="gray-600 fs-8 fw-normal" gutterBottom>
                                            Sub-Diagnosis
                                        </Typography>
                                        <div className="d-flex">
                                            <Autocomplete
                                                getOptionLabel={(option) => option}
                                                value={values?.diagnosis?.subDiagnosis}
                                                onChange={(event, newValue) => {
                                                    setFieldValue('diagnosis.subDiagnosis', newValue ?? null)
                                                }}
                                                size="small"
                                                disablePortal
                                                id="combo-box-demo"
                                                /* options commented till updated list of sub diagnosis is not available */
                                                // options={subDiagnosisOptions}
                                                options={[]}
                                                sx={{
                                                    width: '16rem',
                                                }}
                                                fullWidth
                                                freeSolo
                                                autoSelect
                                                filterOptions={(options, params) => {
                                                    const filtered = filter(options, params)
                                                    const { inputValue } = params
                                                    // flag for the creation of a new value
                                                    const isExisting = options.some(
                                                        (option) => inputValue === option
                                                    )

                                                    if (inputValue !== '' && !isExisting) {
                                                        filtered.push(`${inputValue}`)
                                                    }
                                                    return filtered
                                                }}
                                                onBlur={handleBlur}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            placeholder: 'Select sub-diagnosis',
                                                        }}
                                                        error={
                                                            touched?.diagnosis?.subDiagnosis &&
                                                            Boolean(errors?.diagnosis?.subDiagnosis)
                                                        }
                                                        helperText={
                                                            touched?.diagnosis?.subDiagnosis &&
                                                            errors?.diagnosis?.subDiagnosis
                                                        }
                                                        name="diagnosis?.subDiagnosis"
                                                    />
                                                )}
                                                isOptionEqualToValue={(option, value) => {
                                                    return option === value
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                    {/* Dx Date */}
                                    <Grid item xs={3}>
                                        <div>
                                            <Typography className="gray-600 fs-8 fw-normal" gutterBottom>
                                                Dx Date
                                            </Typography>

                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    disableFuture={true}
                                                    inputFormat="MMM, YYYY"
                                                    views={['year', 'month']}
                                                    openTo="month"
                                                    disableMaskedInput
                                                    name={'diagnosis.diagnoseDate'}
                                                    value={values?.diagnosis?.diagnoseDate}
                                                    onChange={(newValue) => {
                                                        setFieldValue(
                                                            'diagnosis.diagnoseDate',
                                                            new Date(newValue)
                                                        )
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            size="small"
                                                            sx={{
                                                                maxWidth: '12.18rem !important',
                                                            }}
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                placeholder: '',
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </Grid>

                                    {/* Treatments*/}
                                    <Grid item xs={4}>
                                        <Typography className="gray-600 fs-8 fw-normal" gutterBottom>
                                            Treatment
                                        </Typography>
                                        <div className="d-flex">
                                            <Autocomplete
                                                getOptionLabel={(option) => option?.id || option}
                                                value={values?.diagnosis?.treatment}
                                                onChange={(event, newValue) => {
                                                    setFieldValue('diagnosis.treatment', newValue?.id ?? null)
                                                }}
                                                size="small"
                                                disablePortal
                                                id="combo-box-demo"
                                                options={cardiacTreatments}
                                                sx={{
                                                    width: '16rem',
                                                }}
                                                fullWidth
                                                onBlur={handleBlur}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            placeholder: 'Select Treatment',
                                                        }}
                                                        error={
                                                            touched?.diagnosis?.treatment &&
                                                            Boolean(errors?.diagnosis?.treatment)
                                                        }
                                                        helperText={
                                                            touched?.diagnosis?.treatment &&
                                                            errors?.diagnosis?.treatment
                                                        }
                                                        name="diagnosis.treatment"
                                                    />
                                                )}
                                                isOptionEqualToValue={(option, value) => {
                                                    return option?.id === value
                                                }}
                                            />
                                        </div>
                                    </Grid>

                                    {/* Class */}
                                    <Grid item xs={4}>
                                        <Typography className="gray-600 fs-8 fw-normal" gutterBottom>
                                            Class
                                        </Typography>
                                        <div className="d-flex">
                                            <ToggleButtonGroup
                                                orientation="vertical"
                                                exclusive
                                                fullWidth={true}
                                                name={'diagnosis.cardiacState.name'}
                                                value={values?.diagnosis?.cardiacState?.name}
                                                onChange={(e, nextView) =>
                                                    setFieldValue('diagnosis.cardiacState.name', nextView)
                                                }
                                            >
                                                {classOptionHandler(
                                                    alignment === 'Add'
                                                        ? searchValue?.name
                                                        : alignment === 'Update'
                                                        ? advType
                                                        : null
                                                )?.map((btn, idx) => (
                                                    <ToggleButton
                                                        key={idx}
                                                        name={btn}
                                                        value={btn}
                                                        aria-label={btn}
                                                        sx={{
                                                            textTransform: 'capitalize !important',
                                                            minWidth: '100px !important',
                                                        }}
                                                    >
                                                        {btn}
                                                    </ToggleButton>
                                                ))}
                                            </ToggleButtonGroup>
                                        </div>
                                        {
                                            <ErrorMessage
                                                name={'diagnosis.cardiacState.name'}
                                                render={(msg) => (
                                                    <div className="form-error-message">{msg}</div>
                                                )}
                                            />
                                        }
                                    </Grid>

                                    {/* Score */}
                                    <Grid item xs={4}>
                                        <Typography className="gray-600 fs-8 fw-normal" gutterBottom>
                                            Class Severity/Score
                                        </Typography>
                                        <Grid container direction="row" rowSpacing={2} columnSpacing={2}>
                                            <Grid item>
                                                <div className="d-flex">
                                                    <ToggleButtonGroup
                                                        orientation="horizontal"
                                                        exclusive
                                                        fullWidth={true}
                                                        name={'diagnosis.cardiacState.value'}
                                                        value={values?.diagnosis?.cardiacState?.value}
                                                        onChange={(e, nextView) =>
                                                            setFieldValue(
                                                                'diagnosis.cardiacState.value',
                                                                nextView
                                                            )
                                                        }
                                                    >
                                                        {(values?.diagnosis?.cardiacState?.name === 'CCS'
                                                            ? _.range(0, 5)
                                                            : values?.diagnosis?.cardiacState?.name === 'NYHA'
                                                            ? _.range(1, 5)
                                                            : values?.diagnosis?.cardiacState?.name ===
                                                              'CHADS2'
                                                            ? _.range(7)
                                                            : _.range(5)
                                                        ).map((btn, idx) => (
                                                            <ToggleButton
                                                                key={idx}
                                                                name={btn}
                                                                value={btn}
                                                                aria-label={btn}
                                                                sx={{
                                                                    textTransform: 'capitalize !important',
                                                                    minWidth: '40px !important',
                                                                    margin: '1px 2px 1px !important',
                                                                }}
                                                            >
                                                                {btn}
                                                            </ToggleButton>
                                                        ))}
                                                    </ToggleButtonGroup>
                                                </div>
                                            </Grid>
                                        </Grid>

                                        {
                                            <ErrorMessage
                                                name={'diagnosis.cardiacState.value'}
                                                render={(msg) => (
                                                    <div className="form-error-message">{msg}</div>
                                                )}
                                            />
                                        }
                                    </Grid>
                                </Grid>
                                {/* mark as past checkbox */}
                                <div>
                                    <FormGroup sx={{ mt: 1, maxWidth: 'fit-content !important' }}>
                                        <FormControlLabel
                                            label={
                                                <Typography
                                                    sx={{
                                                        pointerEvents: 'auto !important',
                                                    }}
                                                    className={clsx(
                                                        'fs-8 fw-normal',
                                                        !!(currentCardiacHistory === 'past') ||
                                                            !!(alignment === 'Add')
                                                            ? 'gray-400 cursor-not-allowed'
                                                            : 'gray-600'
                                                    )}
                                                >
                                                    Mark as past
                                                </Typography>
                                            }
                                            control={
                                                <Checkbox
                                                    name={'diagnosis.isPast'}
                                                    value={!!values?.diagnosis?.isPast}
                                                    checked={!!values?.diagnosis?.isPast}
                                                    size="small"
                                                    onChange={(e, nextView) =>
                                                        setFieldValue('diagnosis.isPast', nextView)
                                                    }
                                                    disabled={
                                                        !!(currentCardiacHistory === 'past') ||
                                                        !!(alignment === 'Add')
                                                    }
                                                    disableRipple={true}
                                                    sx={{
                                                        color: variables.gray400,
                                                        '&.Mui-checked': {
                                                            color: variables.purple,
                                                        },
                                                        '&.Mui-disabled': {
                                                            color: variables.gray200,
                                                            pointerEvents: 'auto !important',
                                                            cursor: 'not-allowed !important',
                                                        },
                                                    }}
                                                />
                                            }
                                        />
                                    </FormGroup>
                                </div>

                                <div>
                                    <Button
                                        size="small"
                                        onClick={() => {}}
                                        variant="contained"
                                        className="btn-secondary"
                                        fullWidth={true}
                                        startIcon={<AddIcon />}
                                        sx={{
                                            width: 'fit-content',
                                            mt: 3,
                                            mb: 2,
                                        }}
                                        disabled={true}
                                    >
                                        Add New Presentation
                                    </Button>
                                </div>
                            </>
                        ) : null}
                        {!!values?.diagnosis && (
                            <>
                                {!!searchValue?.name || alignment === 'Update' ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 1,
                                            justifyContent: 'end',
                                            padding: 4,
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            className="btn-primary"
                                            disabled={!!loading}
                                        >
                                            {!!loading ? 'Processing...' : 'Save'}
                                        </Button>
                                        <Button
                                            onClick={() => setAlignment('Details')}
                                            variant="contained"
                                            className="btn-white-outline"
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                ) : null}
                            </>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.loginData.loginResponse.accessToken,
        patientId: state.patient.patientData.patientId,
        currentCardiacHistory: state?.cardiacHistory?.currentCardiacHistory,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSessionModal: (payload, data) => dispatch(toggleSessionModal(payload, data)),
        toggleDeactivateModal: (payload) => dispatch(toggleDeactivateModal(payload)),
        setAlert: (payload) => dispatch(setAlert(payload)),
        getCardiacHistoryListData: (payload, token) => dispatch(getCardiacHistoryListData(payload, token)),
        getPastCardiacHistoryListData: (payload, token) =>
            dispatch(getPastCardiacHistoryListData(payload, token)),
        setCurrentCardiacHistory: (payload) => dispatch(setCurrentCardiacHistory(payload)),
        toggleCardiacHistoryModal: (payload) => dispatch(toggleCardiacHistoryModal(payload)),
        getRecommendationsListData: (payload, token) => dispatch(getRecommendationsListData(payload, token)),
        getCardiacHistoryDiagnosisData: (payload, token) =>
            dispatch(getCardiacHistoryDiagnosisData(payload, token)),
        setCurrentSubDiagnosis: (payload) => dispatch(setCurrentSubDiagnosis(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
