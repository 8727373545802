import React from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import variables from '../../theme/variables.scss'
import Typography from '@mui/material/Typography'
import { base64ToArrayBuffer } from '../../util'

function Index({ url, pdfWidthFlag = false, isGenerated, isTif }) {
    const [numPages, setNumPages] = React.useState(null)
    const viewerRef = React.useRef(null)

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages)
    }
    React.useEffect(() => {
        if (isGenerated || isTif) {
            base64ToArrayBuffer(url).then((buffer) => {
                const tiffData = new window.Tiff({ buffer: buffer })
                const pages = tiffData.countDirectory()
                for (let i = 0; i < pages; i++) {
                    if (i === 0) {
                        viewerRef.current.innerHTML = ''
                    }
                    tiffData.setDirectory(i)
                    const canvas = tiffData.toCanvas()
                    canvas.style.width = '90%'
                    canvas.style.margin = '0 5%'
                    if (canvas) {
                        viewerRef.current.appendChild(canvas)
                    }
                }
            })
        }
        setNumPages(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url])

    return (
        <div
            style={{
                border: '1px solid ',
                borderColor: variables.gray200,
                borderRadius: '5px',
                height: '90%',
                position: 'relative',
                overflow: 'auto',
            }}
            ref={viewerRef}
        >
            {isGenerated || isTif ? null : (
                <Document
                    file={{
                        url,
                        withCredentials: true,
                    }}
                    loading={
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ height: '540px' }}
                        >
                            <img style={{ width: '35px' }} src="/loader.gif" alt="loader" />
                            <Typography
                                className=" cursor-default gray-600 fs-9"
                                sx={{
                                    fontWeight: 400,
                                }}
                            >
                                Processing pdf...
                            </Typography>
                        </div>
                    }
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <div className="pdf-page">
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page
                                scale={1.25}
                                width={pdfWidthFlag ? 600 : ''}
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                            />
                        ))}
                    </div>
                </Document>
            )}
        </div>
    )
}

export default Index
