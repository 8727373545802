import PropTypes from 'prop-types'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import variables from '../../../theme/variables.scss'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import { toggleFamilyModal } from '../../../redux/modals/toggleModal.actions'

import {
    getFamilyListData,
    getFamilyViewData,
    getFamilyEventsData,
} from '../../../redux/familyList/familyList.action'
import FamilyTable from './FamilyTable'
import { cardiacEvents } from '../../../util/formData'
import FamilyForm from '../family-form'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),
        borderBottom: 'none',
        minHeight: '500px',
        display: 'flex',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(0),
        position: 'absolute',
        bottom: 20,
        right: 25,
    },
    '& .MuiDialogTitle-root': {
        background: variables.gray100,
        fontWeight: 600,
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export const Index = ({
    patientId,
    token,
    familyModal,
    familyListData,
    familyListLoading,
    familyViewData,
    familyViewLoading,
    familyEvents,
    familyEventsLoading,
}) => {
    const [alignment, setAlignment] = React.useState('Details')
    const [showTable, setShowTable] = React.useState(false)
    const [eventID, setEventID] = React.useState('')
    const [tableData, setTableData] = React.useState([])

    const [searchValue, setSearchValue] = React.useState(null)

    const dispatch = useDispatch()
    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick') return
        dispatch(toggleFamilyModal(false))
    }

    const handleCardiacEvent = (type) => {
        setShowTable(true)
        setEventID(type)
        const payload = {
            patientId: patientId,
            type: type,
        }
        dispatch(getFamilyViewData(payload, token))
    }

    useEffect(() => {
        const rows = familyViewData?.map((data, idx) => {
            return {
                relative: data?.relative,
                diagnosisAge: data?.diagnosisAge,
                status: !!data?.status?.alive
                    ? 'Alive'
                    : `Deceased ${!!data?.status?.age ? data?.status?.age + ' yr(s)' : ''}`,
                _id: data?._id,
            }
        })
        setTableData(rows)
    }, [familyViewData])
    useEffect(() => {
        const eventPayload = {
            patientId: patientId,
        }
        dispatch(getFamilyEventsData(eventPayload, token))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth="lg"
                aria-labelledby="small-modal"
                open={familyModal}
            >
                <DialogContent dividers style={{ minHeight: '80vh' }}>
                    <div
                        className="bg-gray-100 w-25 p-3 overflow-auto"
                        style={{
                            position: 'relative',
                        }}
                    >
                        <Typography className="fw-bold cursor-default gray-900 fs-5 mb-2">
                            Family History
                        </Typography>
                        {alignment === 'Details' ? (
                            <div
                                className="overflow-auto mt-2"
                                style={{
                                    maxHeight: '372px',
                                    backgroundColor: variables.white100,
                                    borderRadius: '5px',
                                    border: '1px solid',
                                    borderColor: variables.lightGray1,
                                    minHeight: '336px',
                                    overflow: 'auto',
                                }}
                            >
                                {familyListLoading === false && familyEventsLoading === false ? (
                                    familyEvents?.length > 0 ? (
                                        familyEvents?.map((cardiacEvent, index) => {
                                            return (
                                                <p
                                                    key={index}
                                                    className={clsx(
                                                        eventID === cardiacEvent && 'bg-indigo-50 fw-bold',
                                                        'fs-8 ps-2 py-2 mb-0 gray-900 cursor-pointer text-capitalize'
                                                    )}
                                                    onClick={() => handleCardiacEvent(cardiacEvent)}
                                                >
                                                    {cardiacEvent}
                                                </p>
                                            )
                                        })
                                    ) : (
                                        <p className="mt-3 text-center p-1 fs-8 gray-900">No records found</p>
                                    )
                                ) : (
                                    <div className="loader">
                                        <img src="/loader.gif" alt="loader" />
                                    </div>
                                )}
                            </div>
                        ) : null}
                        {/* add/update Buttons */}
                        {alignment === 'Details' && (
                            <Stack
                                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                                sx={{
                                    position: 'absolute',
                                    bottom: '5%',
                                    width: '.9',
                                }}
                                spacing={1}
                            >
                                <Button
                                    onClick={() => setAlignment('Update')}
                                    size="small"
                                    variant="contained"
                                    className="btn-secondary"
                                    fullWidth={true}
                                    disabled={eventID === '' || !!familyViewLoading ? true : false}
                                >
                                    Update
                                </Button>
                                <Button
                                    size="small"
                                    onClick={() => {
                                        setAlignment('Add')
                                        setSearchValue(null)
                                    }}
                                    variant="contained"
                                    className="btn-light"
                                    fullWidth={true}
                                    startIcon={<AddIcon />}
                                >
                                    Add new
                                </Button>
                            </Stack>
                        )}
                    </div>

                    <div className="w-75 p-3 overflow-auto">
                        <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={4}
                            >
                                <Typography className="gray-900 cursor-default fw-bold fs-5 mt-2 mb-2">
                                    {alignment === 'Details' && !!eventID
                                        ? eventID
                                        : alignment === 'Update'
                                        ? 'Update'
                                        : alignment === 'Add'
                                        ? 'Add New'
                                        : 'Family History'}
                                </Typography>
                                {alignment === 'Add' ? (
                                    <Autocomplete
                                        getOptionLabel={(option) => option?.id}
                                        value={searchValue}
                                        onChange={(event, newValue) => {
                                            setSearchValue(newValue)
                                        }}
                                        size="small"
                                        disablePortal
                                        id="combo-box-demo"
                                        options={cardiacEvents}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Search Cardiac Event" />
                                        )}
                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                    />
                                ) : null}{' '}
                            </Stack>
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                variant="contained"
                                sx={{
                                    float: 'right',

                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Stack>

                        {alignment === 'Details' ? (
                            <>
                                {familyViewLoading === true ? (
                                    <div
                                        className="d-flex justify-content-center align-items-start"
                                        style={{
                                            height: '550px',
                                        }}
                                    >
                                        <div className="loader">
                                            <img src="/loader.gif" alt="loader" />
                                        </div>
                                    </div>
                                ) : familyViewData?.length > 0 && !!showTable && !!eventID ? (
                                    <div className="mt-3">
                                        <FamilyTable tableData={tableData} eventID={eventID} />
                                    </div>
                                ) : !!showTable && !!eventID ? (
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                            border: '1px solid',
                                            borderColor: variables.gray200,
                                            borderRadius: '5px',
                                            height: '550px',
                                            fontSize: '12px',
                                        }}
                                    >
                                        <p style={{ fontSize: '12px' }}>No data found</p>
                                    </div>
                                ) : (
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                            border: '1px solid',
                                            borderColor: variables.gray200,
                                            borderRadius: '5px',
                                            height: '550px',
                                            fontSize: '12px',
                                        }}
                                    >
                                        <div>No Data Selected</div>
                                    </div>
                                )}
                            </>
                        ) : alignment === 'Add' || alignment === 'Update' ? (
                            <FamilyForm
                                setAlignment={setAlignment}
                                alignment={alignment}
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                                famFormData={alignment === 'Update' ? familyViewData : null}
                                diseaseType={alignment === 'Update' ? eventID : null}
                            />
                        ) : null}
                    </div>
                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        patientId: state.patient.patientData.patientId,
        token: state.loginData.loginResponse.accessToken,
        familyModal: state?.toggleModals?.familyModal,
        familyListData: state?.familyHistory?.familyListData,
        familyListLoading: state?.familyHistory?.familyListLoading,
        familyViewData: state?.familyHistory?.familyViewData,
        familyViewLoading: state?.familyHistory?.familyViewLoading,
        familyEvents: state?.familyHistory?.familyEventsData,
        familyEventsLoading: state?.familyHistory?.familyEventsLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleFamilyModal: (payload) => dispatch(toggleFamilyModal(payload)),
        getFamilyListData: (payload, token) => dispatch(getFamilyListData(payload, token)),
        getFamilyViewData: (payload, token) => dispatch(getFamilyViewData(payload, token)),
        getFamilyEventsData: (payload, token) => dispatch(getFamilyEventsData(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
