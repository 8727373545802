import React from 'react'
import { connect } from 'react-redux'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ScreenFlowDetails from '../screen-flow-details'

export const Index = ({ patientId, token }) => {
    const [expanded, setExpanded] = React.useState(true)
    return (
        <div>
            <Accordion
                className="dashboard-accordion"
                sx={{ boxShadow: 'none', padding: '8px !important' }}
                expanded={expanded}
            >
                <AccordionSummary
                    sx={{
                        background: '#F3F4F6',
                        boxShadow: 'none',
                        borderRadius: '0.25rem',
                        borderBottomLeftRadius: '0.25rem',
                        borderBottomRightRadius: '0.25rem',
                        padding: '0px 2px 0px !important',
                        cursor: 'unset !important',
                    }}
                    expandIcon={
                        <ExpandMoreIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => setExpanded(!expanded)}
                        />
                    }
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography
                        className=" cursor-default gray-900 fs-8 fw-normal "
                        sx={{
                            width: '100%',
                            color: 'text.secondary',
                            // textAlign: 'right',
                            fontSize: '0.85rem',
                            background: '#F3F4F6',
                            boxShadow: 'none',
                            padding: '4px 2px 4px !important',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            fontWeight: 600,
                        }}
                    >
                        ScreenFLOW
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        padding: '6px 0px 6px !important',
                    }}
                >
                    <Box sx={{ paddingLeft: 1 }}>
                        <ScreenFlowDetails />
                    </Box>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
})

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
