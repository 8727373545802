import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import AppBar from '../components/app-bar'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import StepperTabs from '../components/portal-components/stepper-tabs/StepperTabs'
import ClinicalTrialTable from '../components/portal-components/ClinicalTrialTable'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import variables from './../theme/variables.scss'
import TablePagination from '@mui/material/TablePagination'
import { TablePaginationActions } from '../components/appointment-list/index'
import Typography from '@mui/material/Typography'
import {
    // getTrialListData,
    clearTrial,
    getTrialListData_v1,
} from '../redux/searchFlow/screenFlow/screenFlow.action'

const tableHeaderData = [
    {
        name: 'Study Title',
        align: null,
    },
    { name: 'Sponsor', align: 'right' },
    { name: 'Eligibility Criteria', align: 'right' },
    { name: 'ARM', align: 'right' },
]

export const ScreenFlow = ({ login, token, clinicalTrialList, clinicalTrialListLoading }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [togglePopUp, setTogglePopUp] = React.useState(false)
    const [stepvalue, setStepValue] = React.useState('one')

    useEffect(() => {
        if (login.hasOwnProperty('accessToken') && login.accessToken === '') {
            navigate('/')
        }
    })

    useEffect(() => {
        const payloadData = {
            // page: 1,
            // pageLimit: 10,
            // searchKey: '',
            searchTerm: '',
        }
        dispatch(getTrialListData_v1(payloadData, token))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div style={{ background: '#F3F4F6', minHeight: '100vH' }}>
                <AppBar
                    searchPlaceholder={'Search Clinical Trials'}
                    searchType={'screenflow'}
                    hideSearch={false}
                />

                <Container maxWidth="lg">
                    <Box className="my-1">
                        <StepperTabs value={stepvalue} setValue={setStepValue} />
                    </Box>
                    <Box className="my-1 d-flex align-items-center justify-content-start px-1 my-3">
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                                navigate('/portals')
                                dispatch(clearTrial())
                            }}
                            aria-label="close"
                        >
                            <KeyboardBackspaceIcon sx={{ color: variables.purple }} />
                        </IconButton>
                        <Typography className="fs-5 fw-bold">Clinical Trial Screener</Typography>
                    </Box>
                    <Box className="pb-4 mt-1">
                        <ClinicalTrialTable
                            tableHeaderData={tableHeaderData}
                            loading={clinicalTrialListLoading}
                            tableBodyData={clinicalTrialList}
                            togglePopUp={togglePopUp}
                            setTogglePopUp={setTogglePopUp}
                        />
                        <TablePagination
                            labelDisplayedRows={(from = 1) =>
                                `Displaying ${clinicalTrialList?.length ?? 0}
                                 out of ${clinicalTrialList?.length ?? 0} results`
                            }
                            component="div"
                            count={10}
                            rowsPerPageOptions={[]}
                            rowsPerPage={10}
                            page={0}
                            labelRowsPerPage="Displaying"
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'Displaying',
                                },
                                native: true,
                            }}
                            // onPageChange={handleChangePage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </Box>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    login: state.loginData.loginResponse,
    token: state.loginData.loginResponse.idToken,
    clinicalTrialList: state.screenFlowPortalClinicalTrial.clinicalTrialList,
    clinicalTrialListLoading: state.screenFlowPortalClinicalTrial.clinicalTrialListLoading,
})

const mapDispatchToProps = (dispatch) => {
    return {
        getTrialListData_v1: (payload, token) => dispatch(getTrialListData_v1(payload, token)),
        clearTrial: () => dispatch(clearTrial()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenFlow)
