import React from 'react'
import { connect, useDispatch } from 'react-redux'
import _ from 'lodash'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Autocomplete from '@mui/material/Autocomplete'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import {
    toggleDeactivateModal,
    toggleSessionModal,
    toggleSocialModal,
} from '../../../redux/modals/toggleModal.actions'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { setAlert } from '../../../redux/alert/alert.action'
import './social-form.scss'
import {
    occupationData,
    dietData,
    activityData,
    languageData,
    homeResidenceData,
} from '../../../util/formData'
import { getSocialListData, clearSocial } from '../../../redux/socialList/socialList.action'

export const Index = ({ submit, token, patientId, setAlignment, alignment, searchValue, socialHistory }) => {
    const fillValHelper = (objectValue) => {
        if (alignment === 'Update' && !_.isNil(socialHistory)) {
            switch (objectValue) {
                case 'occupation':
                    return socialHistory?.occupation ?? null
                case 'maritalStatus':
                    return socialHistory?.maritalStatus ?? null
                case 'totalKids':
                    return socialHistory?.totalKids ?? null
                case 'diet':
                    return socialHistory?.diet ?? null
                case 'activity':
                    return socialHistory?.activity ?? null
                case 'residence':
                    return socialHistory?.residence ?? null
                case 'language':
                    return socialHistory?.languages ?? null
                case 'alcohol':
                    return (
                        {
                            number: socialHistory?.alcoholUse?.number ?? '',
                            unit: socialHistory?.alcoholUse?.unit ?? '',
                        } ?? null
                    )
                case 'smoking':
                    return (
                        {
                            number: socialHistory?.smokeUse?.number ?? '',
                            unit: socialHistory?.smokeUse?.unit ?? '',
                            // since: socialHistory?.smokeUse?.since?.split(' ')[0] ?? '',
                            since: socialHistory?.smokeUse?.since ?? '',
                        } ?? null
                    )

                default:
                    return null
            }
        }
        return null
    }

    const [occupation, setOccupation] = React.useState(fillValHelper('occupation') ?? '')
    const [maritalStatus, setMaritalStatus] = React.useState(fillValHelper('maritalStatus') ?? '')
    const [totalKids, setTotalKids] = React.useState(fillValHelper('totalKids') ?? 0)
    const [diet, setDiet] = React.useState(fillValHelper('diet') ?? '')
    const [activity, setActivity] = React.useState(fillValHelper('activity') ?? '')
    const [residence, setResidence] = React.useState(fillValHelper('residence') ?? '')
    const [language, setLanguage] = React.useState(fillValHelper('language') ?? [])
    const [alcohol, setAlcohol] = React.useState(fillValHelper('alcohol') ?? { number: '0', unit: 'week' })
    const [smoking, setSmoking] = React.useState(
        fillValHelper('smoking') ?? { number: '0', unit: 'week', since: '0' }
    )

    const dispatch = useDispatch()

    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        submit.current = OnSubmit
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const OnSubmit = () => {
        // setLoading(true)

        if (
            occupation === '' ||
            maritalStatus === '' ||
            totalKids === '' ||
            totalKids === null ||
            diet === '' ||
            activity === '' ||
            residence === '' ||
            alcohol?.number === '' ||
            alcohol?.unit === '' ||
            smoking?.number === '' ||
            smoking?.unit === '' ||
            smoking?.since === ''

            //  ||indications === []
        ) {
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg: 'Please enter missing values!',
                    alertOpen: true,
                })
            )
        } else {
            setLoading(false)

            const filterItem = !!_.isNil(socialHistory)
                ? {
                      patientId: patientId,
                      socialHistory: {
                          occupation: occupation,
                          maritalStatus: maritalStatus,
                          totalKids: totalKids,
                          diet: diet,
                          activity: activity,
                          languages: language,
                          residence: residence,
                          alcoholUse: {
                              number: alcohol?.number,
                              unit: alcohol?.unit,
                          },
                          smokeUse: {
                              number: smoking?.number,
                              unit: smoking?.unit,
                              since: smoking?.since,
                          },
                      },
                  }
                : {
                      id: socialHistory?._id,
                      socialHistory: {
                          occupation: occupation,
                          maritalStatus: maritalStatus,
                          totalKids: totalKids,
                          diet: diet,
                          activity: activity,
                          languages: language,
                          residence: residence,
                          alcoholUse: {
                              number: alcohol?.number,
                              unit: alcohol?.unit,
                          },
                          smokeUse: {
                              number: smoking?.number,
                              unit: smoking?.unit,
                              since: smoking?.since,
                          },
                      },
                  }

            processMedications(filterItem)
        }
    }

    const processMedications = (filterItem) => {
        setLoading(true)
        axios({
            method: !!_.isNil(socialHistory) ? 'post' : 'put',
            url: `${process.env.REACT_APP_CARD_URL}/social-info`,
            data: filterItem,
            headers: {
                accessToken: token,
            },
        }).then((response) => {
            if (response?.data?.status === 200) {
                setLoading(false)
                dispatch(
                    setAlert({
                        alertType: 'success',
                        alertMsg: `Social History ${
                            alignment === 'Update' ? 'Updated' : 'Added'
                        } Successfully`,
                        alertOpen: true,
                    })
                )
                const payloadList = {
                    patientId: patientId,
                }

                /* additonal refetching list and view */
                dispatch(getSocialListData(payloadList, token))
                dispatch(toggleSocialModal(false))
            } else if (response?.data?.status === 401) {
                setLoading(false)
                dispatch(toggleSessionModal(true, response?.data?.error))
            } else if (response?.data?.status === 403) {
                setLoading(false)
                dispatch(toggleDeactivateModal(true))
            }
        })
    }

    const handleOccupationChange = (event) => {
        setOccupation(event.target.value)
    }

    const handleMaritalChange = (event, nextView) => {
        setMaritalStatus(nextView ?? '')
    }
    const handleKidsChange = (event, nextView) => {
        setTotalKids(nextView ?? '')
    }

    const handleDietChange = (event) => {
        setDiet(event.target.value)
    }
    const handleActivityChange = (event) => {
        setActivity(event.target.value)
    }
    const handleResidenceChange = (event) => {
        setResidence(event.target.value)
    }

    const handleAlcoholChange = (event) => {
        const regex = /^[0-9\b]+$/
        // eslint-disable-next-line eqeqeq
        if (event.target.value == '' || regex.test(event.target.value)) {
            setAlcohol((prevAlcohol) => {
                return {
                    ...prevAlcohol,
                    number: event.target.value,
                }
            })
        }
    }
    const handleAlcoholTypeChange = (event) => {
        setAlcohol((prevtype) => {
            return {
                ...prevtype,
                unit: event.target.value,
            }
        })
    }

    /* smoking */
    const handleSmokingChange = (event) => {
        const regex = /^[0-9\b]+$/
        // eslint-disable-next-line eqeqeq
        if (event.target.value == '' || regex.test(event.target.value)) {
            setSmoking((prev) => {
                return {
                    ...prev,
                    number: event.target.value,
                }
            })
        }
    }

    const handleSmokingTypeChange = (event) => {
        setSmoking((prev) => {
            return {
                ...prev,
                unit: event.target.value,
            }
        })
    }

    const handleSmokingSinceChange = (event) => {
        setSmoking((prev) => {
            return {
                ...prev,
                since: event.target.value,
            }
        })
    }

    return (
        <div className="social-class">
            {alignment === 'Update' ? (
                <Grid container direction="row" spacing={0.5}>
                    <Grid item xs={4} md={3}>
                        <Typography className="gray-600 fs-8 mt-3 fw-normal" gutterBottom>
                            Occupation
                        </Typography>
                        <div className="d-flex">
                            <Select
                                value={occupation}
                                onChange={handleOccupationChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{ maxHeight: '40px' }}
                            >
                                <MenuItem value="">None</MenuItem>
                                {occupationData?.map((occ, idx) => {
                                    return (
                                        <MenuItem key={idx} value={occ?.val}>
                                            {occ?.id}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </div>
                    </Grid>

                    <Grid item xs={8} md={9}>
                        <Typography className="gray-600 fs-8 mt-3 fw-normal" gutterBottom>
                            Marital Status
                        </Typography>
                        <div className="d-flex">
                            <ToggleButtonGroup
                                orientation="horizontal"
                                value={maritalStatus}
                                exclusive
                                onChange={handleMaritalChange}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: '6px',
                                    padding: '4px',
                                }}
                            >
                                {['Single', 'Married', 'Divorced', 'Widow'].map((btn, idx) => (
                                    <ToggleButton
                                        key={idx}
                                        value={btn}
                                        aria-label={btn}
                                        sx={{ textTransform: 'capitalize !important' }}
                                    >
                                        {btn}
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                        </div>
                    </Grid>

                    <Grid item xs={8} md={6}>
                        <Typography className="gray-600 fs-8 mt-3 fw-normal" gutterBottom>
                            Kids
                        </Typography>
                        <div className="d-flex">
                            <ToggleButtonGroup
                                orientation="horizontal"
                                value={totalKids}
                                exclusive
                                onChange={handleKidsChange}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: '6px',
                                    padding: '4px',
                                }}
                            >
                                {_.range(5).map((btn, idx) => (
                                    <ToggleButton
                                        key={idx}
                                        value={btn}
                                        aria-label={btn}
                                        sx={{ textTransform: 'capitalize !important' }}
                                    >
                                        {btn === 0 ? 'None' : btn === 4 ? '> 4' : btn}
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                        </div>
                    </Grid>
                    <Grid item xs={4} md={6}>
                        <Typography className="gray-600 fs-8 mt-3 fw-normal" gutterBottom>
                            Diet
                        </Typography>
                        <div className="d-flex">
                            <Select
                                value={diet}
                                onChange={handleDietChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{ maxHeight: '40px', minWidth: '250px' }}
                            >
                                <MenuItem value="">None</MenuItem>
                                {dietData?.map((d, idx) => {
                                    return (
                                        <MenuItem key={idx} value={d?.val}>
                                            {d?.id}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </div>
                    </Grid>

                    <Grid item xs={6} md={6}>
                        <Typography className="gray-600 fs-8 mt-3 fw-normal" gutterBottom>
                            Activity
                        </Typography>
                        <div className="d-flex">
                            <Select
                                value={activity}
                                onChange={handleActivityChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{ maxHeight: '40px', minWidth: '250px' }}
                            >
                                <MenuItem value="">None</MenuItem>
                                {activityData?.map((ad, idx) => {
                                    return (
                                        <MenuItem key={idx} value={ad?.val}>
                                            {ad?.id}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </div>
                    </Grid>
                    <Grid item xs={4} md={6}>
                        <Typography className="gray-600 fs-8 mt-3 fw-normal" gutterBottom>
                            Residence
                        </Typography>
                        <div className="d-flex">
                            <Select
                                value={residence}
                                onChange={handleResidenceChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{ maxHeight: '40px', minWidth: '250px' }}
                            >
                                <MenuItem value="">None</MenuItem>
                                {homeResidenceData?.map((homeRes, idx) => {
                                    return (
                                        <MenuItem key={idx} value={homeRes?.val}>
                                            {homeRes?.id}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={9}>
                        <Typography className="gray-600 fs-8 mt-3 fw-normal" gutterBottom>
                            Language
                        </Typography>
                        <div className="d-flex" sx={{ padding: 2 }}>
                            <Autocomplete
                                limitTags={3}
                                value={language}
                                onChange={(event, newValue) => {
                                    setLanguage(newValue)
                                }}
                                freeSolo
                                sx={{ maxHeight: 140, overflow: 'auto' }}
                                className="medication-multiselect"
                                multiple
                                id="tags-outlined"
                                options={languageData}
                                getOptionLabel={(option) => option}
                                defaultValue={[languageData[0]]}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Language"
                                        sx={{ minWidth: '400px' }}
                                    />
                                )}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={2} md={3}>
                        <div className="d-flex"></div>
                    </Grid>

                    <Grid item xs={6} md={4}>
                        <Typography className="gray-600 fs-8 mt-3 fw-normal" gutterBottom>
                            Alcohol
                        </Typography>
                        <div className="d-flex justify-content-start align-items-center">
                            <TextField
                                value={alcohol?.number}
                                onChange={handleAlcoholChange}
                                type="number"
                                min="0"
                                id="outlined-size-small"
                                size="small"
                                sx={{ maxWidth: '50px' }}
                            />
                            <Typography className="gray-600 fs-8 mt-2 m-1 fw-normal" gutterBottom>
                                drink(s)
                            </Typography>
                            <Select
                                value={alcohol?.unit ?? ''}
                                onChange={handleAlcoholTypeChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{ maxHeight: '40px', marginLeft: '2px' }}
                            >
                                <MenuItem value="">None</MenuItem>
                                <MenuItem value={'day'}> per day</MenuItem>
                                <MenuItem value={'week'}>per week</MenuItem>
                                <MenuItem value={'month'}>per month</MenuItem>
                                <MenuItem value={'year'}>per year</MenuItem>
                            </Select>
                        </div>
                    </Grid>

                    <Grid item xs={6} md={6}>
                        <Typography className="gray-600 fs-8 mt-3 fw-normal" gutterBottom>
                            Smoking
                        </Typography>
                        <div className="d-flex justify-content-start align-items-center">
                            <TextField
                                value={smoking?.number}
                                onChange={handleSmokingChange}
                                type="number"
                                min="0"
                                id="outlined-size-small"
                                size="small"
                                sx={{ maxWidth: '50px' }}
                            />
                            <Typography className="gray-600 fs-8 mt-2 m-1 fw-normal" gutterBottom>
                                pack(s)
                            </Typography>
                            <Select
                                value={smoking?.unit ?? ''}
                                onChange={handleSmokingTypeChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{ maxHeight: '40px', marginLeft: '2px' }}
                            >
                                <MenuItem value="">None</MenuItem>
                                <MenuItem value={'day'}> per day</MenuItem>
                                <MenuItem value={'week'}>per week</MenuItem>
                                <MenuItem value={'month'}>per month</MenuItem>
                                <MenuItem value={'year'}>per year</MenuItem>
                            </Select>
                            <Typography className="gray-600 fs-8 mt-2 m-1 fw-normal" gutterBottom>
                                for
                            </Typography>
                            <TextField
                                value={smoking?.since}
                                onChange={handleSmokingSinceChange}
                                type="number"
                                min="0"
                                id="outlined-size-small"
                                size="small"
                                sx={{ maxWidth: '50px' }}
                            />
                            <Typography className="gray-600 fs-8 mt-2 m-1 fw-normal" gutterBottom>
                                year(s)
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            ) : null}

            <DialogActions>
                <Button
                    onClick={() => dispatch(toggleSocialModal(false))}
                    variant="contained"
                    className="btn-white-outline"
                    type="submit"
                >
                    Cancel
                </Button>
                {searchValue?._id?.brandName || alignment === 'Update' ? (
                    <Button
                        disabled={
                            loading ||
                            !(
                                occupation !== '' &&
                                maritalStatus !== '' &&
                                totalKids !== '' &&
                                totalKids !== null &&
                                diet !== '' &&
                                activity !== '' &&
                                residence !== '' &&
                                alcohol?.number !== '' &&
                                alcohol?.unit !== '' &&
                                smoking?.number !== '' &&
                                smoking?.unit !== '' &&
                                smoking?.since !== ''
                            )
                        }
                        onClick={() => OnSubmit()}
                        variant="contained"
                        className="btn-primary"
                    >
                        {!!loading
                            ? 'Processing...'
                            : alignment === 'Add'
                            ? 'Save'
                            : alignment === 'Update'
                            ? 'Update'
                            : 'Save'}
                    </Button>
                ) : null}
            </DialogActions>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.loginData.loginResponse.accessToken,
        patientId: state.patient.patientData.patientId,
        vitalData: state.vitalList.list,
        socialHistory: state?.socialList?.list,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSessionModal: (payload, data) => dispatch(toggleSessionModal(payload, data)),
        toggleDeactivateModal: (payload) => dispatch(toggleDeactivateModal(payload)),
        setAlert: (payload) => dispatch(setAlert(payload)),
        toggleSocialModal: (payload) => dispatch(toggleSocialModal(payload)),
        clearSocial: () => dispatch(clearSocial()),
        getSocialListData: (payload, token) => dispatch(getSocialListData(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
