import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import AppBar from '../components/app-bar'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import StepperTabs from '../components/portal-components/stepper-tabs/StepperTabs'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import variables from './../theme/variables.scss'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import TablePagination from '@mui/material/TablePagination'
import { TablePaginationActions } from '../components/appointment-list/index'
import { Divider, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import '../components/social-history/social-form/social-form.scss'
import Autocomplete from '@mui/material/Autocomplete'
import Slider from '@mui/material/Slider'
import TrialCriteriaSummary from '../components/portal-components/TrialCriteriaSummary'
import PatientListTable from '../components/portal-components/PatientListTable'
import TroubleshootIcon from '@mui/icons-material/Troubleshoot'
import HowToRegIcon from '@mui/icons-material/HowToReg'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import { blobToBase64, getS3File, useQuery, ageToDob } from '../util'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import Tooltip from '@mui/material/Tooltip'
import { setAlert } from '../redux/alert/alert.action'
import ClearIcon from '@mui/icons-material/Clear'
import {
    getMatchedPatientList,
    getProviderList,
    setCurrentProviders,
    setPatientAppointment,
    setPatientInfoData,
    getMatchedPatientPdf,
    clearMatchesPdf,
    clearMatchedPatient,
    clearPartiallyMatchedPatient,
    clearPartialMatchesPdf,
    clearPatientAppointment,
    clearAppointmentPdf,
    clearPatientInfoData,
    getMatchedPatientTrialCount,
    clearMatchedPatientTrialCount,
    clearPartiallyMatchedPatientTrialCount,
    getTrialGoldListData_v1,
    clearGoldPatient,
    getGoldListSummary_v1,
    clearGoldPatientSummary,
} from '../redux/searchFlow/screenFlow/screenFlow.action'
import { toggleAppointmentModal, togglePatientInfoModal } from '../redux/modals/toggleModal.actions'
import Loading from '../components/Loading'

export const ScreenFlow = ({
    login,
    token,
    matchedPatientList,
    matchedPatientListLoading,
    providerList,
    providerListLoading,
    currentProviders,
    matchesPdf,
    matchesPdfLoading,
    matchedPatientTrialCount,
    matchedPatientTrialCountLoading,
    goldPatientList,
    goldPatientListLoading,
    goldPatientSummary,
    goldPatientSummaryLoading,
}) => {
    const navigate = useNavigate()
    let query = useQuery()

    const dispatch = useDispatch()

    useEffect(() => {
        if (login.hasOwnProperty('accessToken') && login.accessToken === '') {
            navigate('/')
        }
    })
    /* pagination states */
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)

    /* Search bar values */
    const [patientSearch, setPatientSearch] = React.useState('')
    const [inputSearch, setInputSearch] = React.useState('')
    const handleMatchesPatientSearch = (e) => {
        if (e.key === 'Enter') {
            setPage(0)
            setPatientSearch(e.target.value)
            setInputSearch(e.target.value)
            // const payloadData = {
            //     collectionName: query.get('trial_name'),
            //     page: 1,
            //     pageLimit: 10,
            //     patientName: e.target.value,
            //     partialMatch: false,
            // }
            // dispatch(getMatchedPatientList(payloadData, token))
            const goldPayloadData = {
                trial_id: query.get('trial_name'),
                searchTerm: e.target.value,
                page: 1,
                pageLimit: 10,
            }
            dispatch(getTrialGoldListData_v1(goldPayloadData, token))
        }
        if (e.target.value === '') {
            if (e.key === 'Backspace') {
                setPage(0)
                setPatientSearch(e.target.value)
                setInputSearch(e.target.value)
                // const payloadData = {
                //     collectionName: query.get('trial_name'),
                //     page: 1,
                //     pageLimit: 10,
                //     patientName: '',
                //     partialMatch: false,
                // }
                // dispatch(getMatchedPatientList(payloadData, token))

                const goldPayloadData = {
                    trial_id: query.get('trial_name'),
                    searchTerm: '',
                    page: 1,
                    pageLimit: 10,
                }
                dispatch(getTrialGoldListData_v1(goldPayloadData, token))
            }
        }
    }

    /* pagination handler */
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
        const payloadData = {
            collectionName: query.get('trial_name'),
            page: newPage + 1,
            pageLimit: 10,
            patientName: !!patientSearch ? patientSearch : inputSearch,
            partialMatch: false,
        }

        dispatch(getMatchedPatientList(payloadData, token))
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    // on page render scroll to top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [durationValue, setDurationValue] = React.useState([2022, 2024])
    const [downloadPdfFlag, setDownloadPdfFlag] = React.useState(false)

    //time tick placeholder
    function valuetext(value) {
        return `${value}`
    }
    const minDistance = 1

    //duration marks
    const marks = [
        { label: '2014', value: 2014 },
        { label: '2015', value: 2015 },
        { label: '2016', value: 2016 },
        { label: '2017', value: 2017 },
        { label: '2018', value: 2018 },
        { label: '2019', value: 2019 },
        { label: '2020', value: 2020 },
        { label: '2021', value: 2021 },
        { label: '2022', value: 2022 },
        { label: '2023', value: 2023 },
        { label: '2024', value: 2024 },
    ]
    const handleDurationChange = (event, newValue, activeThumb) => {
        // setDurationValue(newValue)
        if (!Array.isArray(newValue)) {
            return
        }
        if (activeThumb === 0) {
            setDurationValue([Math.min(newValue[0], durationValue[1] - minDistance), durationValue[1]])
        } else {
            setDurationValue([durationValue[0], Math.max(newValue[1], durationValue[0] + minDistance)])
        }
    }

    const [stepValue, setStepValue] = React.useState('two')

    /* Initial fetch for matched patient list */
    useEffect(() => {
        if (query.get('trial_name') === 'null' || _.isEmpty(query.get('trial_name'))) {
            navigate('/portals')
        } else {
            dispatch(clearGoldPatientSummary())
            dispatch(clearMatchesPdf())
            dispatch(clearPartialMatchesPdf())
            dispatch(clearPatientAppointment())
            dispatch(clearAppointmentPdf())
            dispatch(clearMatchedPatientTrialCount())
            dispatch(clearPartiallyMatchedPatientTrialCount())
            const matchesPayloadData = {
                // providerIds: query.getAll('providerIds'),
                collectionName: query.get('trial_name'),
                // startDate: durationValue[0],
                // endDate: durationValue[1],
                // patientName: patientSearchName,
                partialMatch: false,
            }
            // dispatch(getMatchedPatientList(matchesPayloadData, token))
            // dispatch(getMatchedPatientTrialCount(matchesPayloadData, token))
            /* provider list WIP */
            // dispatch(getProviderList(null, token))
            const pdfPayload = {
                collectionName: query.get('trial_name'),
                partialMatch: false,
            }
            /* pdf api WIP */
            // dispatch(getMatchedPatientPdf(pdfPayload, token))

            /* gold list */
            const goldPayloadData = {
                trial_id: query.get('trial_name'),
                searchTerm: '',
            }
            dispatch(getTrialGoldListData_v1(goldPayloadData, token))

            /* gold list summary */
            const goldSummaryPayloadData = {
                trial_id: query.get('trial_name'),
            }
            dispatch(getGoldListSummary_v1(goldSummaryPayloadData, token))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const tableHeaderData = [
        {
            name: 'Name',
            align: null,
        },
        { name: 'DOB', align: 'right' },
        { name: 'Phone Number', align: 'right' },
        { name: 'office provider', align: 'right' },
    ]

    const cardData = [
        {
            title: 'Screened Patients',
            subTitle: goldPatientSummary?.total_patients ?? 0,
            description: null,
            color: variables.rose400,
            popup: false,
            popupFn: null,
            icon: <TroubleshootIcon sx={{ color: variables.rose400 }} />,
        },
        {
            title: 'Eligible Patients',
            subTitle: goldPatientSummary?.num_eligible_patients ?? 0,
            description: null,
            color: variables.green500,
            popup: false,
            popupFn: null,
            icon: <HowToRegIcon sx={{ color: variables.green500 }} />,
        },
        {
            title: 'Upcoming Appointments',
            subTitle: goldPatientSummary?.appointmentsCount ?? 0,
            description: null,
            color: variables.purple,
            popup: false,
            popupFn: () => {
                dispatch(clearPatientAppointment())
                dispatch(clearAppointmentPdf())
                dispatch(
                    setPatientAppointment({
                        title: matchedPatientList?.studyName,
                        listType: 'matches',
                        list: matchedPatientList?.appointments,
                    })
                )
                dispatch(toggleAppointmentModal(true))
            },
            icon: <EventAvailableIcon sx={{ color: variables.purple }} />,
        },
    ]

    const patientMatchListData = goldPatientList?.gold_patients?.map((item) => {
        return {
            patientId: item?.patient_id,
            name: item?.name,
            dob: item?.birthdate,
            phNo: item?.phone_number,
            officeProvider: item?.office_provider,
            showPopup: true,
            popupFn: () => {
                dispatch(clearPatientInfoData())
                dispatch(togglePatientInfoModal(true))
                dispatch(
                    setPatientInfoData({
                        ...item,
                        title: `${goldPatientList?.trial_name} Matching Trial Criteria`,
                    })
                )
            },
        }
    })

    const handlePdfDownload = () => {
        setDownloadPdfFlag(true)
        getS3File(matchesPdf?.key)
            .then((res) => {
                setDownloadPdfFlag(false)
                blobToBase64(res?.Body).then((usablePdf) => {
                    let a = document.createElement('a')
                    a.href = usablePdf
                    a.download = `${
                        matchedPatientList?.studyName
                            ? `${matchedPatientList?.studyName}-matches-list.pdf`
                            : 'matches-list.pdf'
                    }`
                    a.click()
                })
            })
            .catch((err) => {
                console.log('ERROR in Handle document PDF', err)
                dispatch(
                    setAlert({
                        alertType: 'error',
                        alertMsg: `Error in downloading PDF. Please try again.`,
                        alertOpen: true,
                    })
                )
            })
            .finally(() => {
                setDownloadPdfFlag(false)
            })
    }

    return (
        <>
            <div style={{ background: '#F3F4F6', minHeight: '100vH' }}>
                <AppBar hideSearch={true} />
                <Container maxWidth="lg">
                    <Box className="my-1">
                        <StepperTabs setValue={setStepValue} value={stepValue} />
                    </Box>
                    <Box className="d-flex justify-content-between align-items-center">
                        <Box className="my-1 d-flex align-items-center justify-content-start px-1">
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => {
                                    dispatch(clearMatchedPatient())
                                    dispatch(clearPartiallyMatchedPatient())
                                    dispatch(clearMatchesPdf())
                                    dispatch(clearPartialMatchesPdf())
                                    dispatch(clearPatientAppointment())
                                    dispatch(clearAppointmentPdf())
                                    dispatch(clearMatchedPatientTrialCount())
                                    dispatch(clearPartiallyMatchedPatientTrialCount())
                                    dispatch(clearGoldPatient())
                                    dispatch(clearGoldPatientSummary())
                                    navigate('/screenflow')
                                }}
                                aria-label="close"
                            >
                                <KeyboardBackspaceIcon sx={{ color: variables.purple }} />
                            </IconButton>
                            <Typography className="fs-5 fw-bold">Find Eligible Patients</Typography>
                        </Box>
                    </Box>
                    {/* API WIP */}
                    {/* 
                    <Box className="pb-3 mt-1">
                        <Box
                            component={Paper}
                            sx={{
                                width: '100%',
                                border: '1px solid',
                                borderColor: variables.gray200,
                                borderRadius: '8px',
                                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.06)',
                                p: 2,
                                display: 'flex',
                                justifyContent: 'start',
                                gap: 4,
                            }}
                        >
                            <Box sx={{ width: '50%' }}>
                                <Typography className="gray-600 fs-6 fw-normal pb-2">
                                    Office Provider
                                </Typography>
                                <Tooltip
                                    arrow
                                    title="Filtering patient results according to the current logged in provider"
                                    followCursor
                                    slotProps={{
                                        popper: {
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, 24],
                                                    },
                                                },
                                            ],
                                        },
                                    }}
                                >
                                    <Autocomplete
                                        disabled
                                        limitTags={4}
                                        value={currentProviders || null}
                                        onChange={(event, newValue) => {
                                            dispatch(setCurrentProviders(newValue))
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: variables.gray200,
                                                    borderRadius: '8px',
                                                    borderWidth: '2px !important',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: variables.indigo300,
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: variables.indigo500,
                                                },
                                                '&.Mui-disabled fieldset': {
                                                    borderColor: variables.gray200,
                                                    backgroundColor: variables.whiteTransparent,
                                                    pointerEvents: 'initial !important',
                                                    cursor: 'not-allowed !important',
                                                },
                                            },
                                        }}
                                        className="medication-multiselect"
                                        multiple
                                        id="tags-outlined"
                                        options={providerList ?? []}
                                        getOptionLabel={(option) => {
                                            return option?.firstName + ' ' + option?.lastName
                                        }}
                                        filterSelectedOptions
                                        isOptionEqualToValue={(option, value) =>
                                            option?.prUid === value?.prUid
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Current Provider"
                                                sx={{ minWidth: '550px' }}
                                            />
                                        )}
                                        popupIcon={<ExpandMoreIcon />}
                                    />
                                </Tooltip>
                            </Box>
                            <Box>
                                <Typography className="gray-600 fs-6 fw-normal pb-2">Duration</Typography>
                                <Box sx={{ minWidth: 500, pl: 1 }}>
                                    <Tooltip
                                        arrow
                                        title="Filtering patient results according to the current logged in provider"
                                        followCursor
                                        slotProps={{
                                            popper: {
                                                modifiers: [
                                                    {
                                                        name: 'offset',
                                                        options: {
                                                            offset: [0, 24],
                                                        },
                                                    },
                                                ],
                                            },
                                        }}
                                    >
                                        <Slider
                                            disabled
                                            isOptionEqualToValue={null}
                                            getAriaLabel={() => 'Year range'}
                                            value={durationValue}
                                            onChange={handleDurationChange}
                                            valueLabelDisplay="off" //auto
                                            getAriaValueText={valuetext}
                                            marks={marks}
                                            track="normal"
                                            min={2014}
                                            max={2024}
                                            sx={{
                                                color: variables.gray200,
                                                height: 8,
                                                padding: '13px 0',
                                                '& .MuiSlider-thumb': {
                                                    height: 14,
                                                    width: 14,
                                                    backgroundColor: '#fff',
                                                    border: '1px solid ',
                                                    borderColor: variables.purple,

                                                    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                                                        boxShadow: '0 0 0 8px rgba(31, 27, 90, 0.56)',
                                                        pointerEvents: 'auto !important',
                                                    },
                                                    '&:before': {
                                                        display: 'none',
                                                    },
                                                },
                                                '& .MuiSlider-track': {
                                                    color: variables.purple,
                                                    height: 8,
                                                },

                                                '& .MuiSlider-markLabel': {
                                                    color: variables.gray500,
                                                    fontSize: '0.85rem',
                                                    fontWeight: 400,
                                                },
                                                '& .MuiSlider-rail': {
                                                    color: variables.lightGray1,
                                                },
                                                '& .MuiSlider-mark': {
                                                    color: variables.lightGray1,
                                                    opacity: '0%',
                                                },
                                                '& .MuiSlider-markActive': {
                                                    opacity: '100%',
                                                },
                                                '&.Mui-disabled': {
                                                    pointerEvents: 'initial !important',
                                                    cursor: 'not-allowed !important',
                                                },
                                            }}
                                        />
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    */}
                    <Box className="my-2">
                        {/* "pb-3 mt-1" */}
                        <Box
                            component={Paper}
                            sx={{
                                width: '100%',
                                border: '1px solid',
                                borderColor: variables.gray200,
                                borderRadius: '8px',
                                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.06)',
                                p: 1.5,
                                minHeight: '10.19rem',
                            }}
                        >
                            {!!goldPatientSummaryLoading ? (
                                <Box
                                    sx={{
                                        minHeight: '8rem',
                                    }}
                                    className="d-flex flex-column align-items-center justify-content-center"
                                >
                                    <Loading />
                                    <Typography className="gray-600 fw-normal fs-7 pb-2">
                                        Fetching Summary Criteria...
                                    </Typography>
                                </Box>
                            ) : (
                                <>
                                    <Typography className="fs-6 fw-bold bg-gray-600 mb-2 text-capitalize">
                                        {goldPatientSummary?.trial_name} Criteria Summary
                                    </Typography>
                                    <Divider className="mb-2" sx={{ backgroundColor: variables.gray600 }} />
                                    <TrialCriteriaSummary cardData={cardData} />
                                </>
                            )}
                        </Box>
                    </Box>
                    <Box className="d-flex justify-content-end pb-1 mx-2">
                        <Button
                            size="large"
                            onClick={() => {
                                dispatch(clearMatchedPatient())
                                dispatch(clearPartiallyMatchedPatient())
                                dispatch(clearMatchesPdf())
                                dispatch(clearPartialMatchesPdf())
                                dispatch(clearPatientAppointment())
                                dispatch(clearAppointmentPdf())
                                dispatch(clearPatientInfoData())
                                dispatch(clearMatchedPatientTrialCount())
                                dispatch(clearPartiallyMatchedPatientTrialCount())
                                dispatch(clearGoldPatient())
                                dispatch(clearGoldPatientSummary())
                                navigate(`/screenflow-step-3?trial_name=${query.get('trial_name')}`)
                            }}
                            variant="contained"
                            className="btn-secondary"
                            endIcon={<ArrowForwardIcon />}
                        >
                            Partially Eligible Patients
                        </Button>
                    </Box>

                    <Box className="pb-4 mt-1">
                        <Box
                            component={Paper}
                            sx={{
                                width: '100%',
                                border: '1px solid',
                                borderColor: variables.gray200,
                                borderRadius: '8px',
                                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.06)',
                                p: 1.5,
                                minHeight: '20vh',
                            }}
                        >
                            <Typography className="fs-6 fw-bold bg-gray-600 mb-2 text-capitalize">
                                {goldPatientList?.trial_name} Trial List
                            </Typography>

                            <Divider className="mb-1 mt-1" sx={{ backgroundColor: variables.gray600 }} />

                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={1}
                                sx={{ minHeight: '3rem' }}
                            >
                                <Typography className="fs-6 fw-bold bg-gray-600">Matches</Typography>
                                <Chip
                                    variant="filled"
                                    sx={{
                                        color: variables.green500,
                                        backgroundColor: variables.greenTransparent,
                                        fontWeight: 600,
                                    }}
                                    label={goldPatientList?.num_eligible_patients ?? 0}
                                    size="small"
                                />
                                <Tooltip title="Download matches list">
                                    <Button
                                        sx={{ width: '9rem !important' }}
                                        size="small"
                                        disabled={
                                            !!downloadPdfFlag || !!matchesPdfLoading || !matchesPdf?.key
                                        }
                                        onClick={handlePdfDownload}
                                        variant="contained"
                                        className="btn-secondary"
                                        startIcon={
                                            !!downloadPdfFlag ? (
                                                <HourglassBottomIcon />
                                            ) : (
                                                <DownloadForOfflineIcon />
                                            )
                                        }
                                    >
                                        {!!downloadPdfFlag ? 'Processing ...' : 'Download PDF'}
                                    </Button>
                                </Tooltip>
                            </Stack>

                            <Divider className="mb-1" sx={{ backgroundColor: variables.gray600 }} />
                            <Box
                                sx={{
                                    width: '100%',

                                    my: 2,
                                    display: 'flex',
                                    justifyContent: 'start',
                                    alignItems: 'center',
                                }}
                            >
                                <TextField
                                    fullWidth
                                    id="appointment-search"
                                    placeholder="Search Patient"
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            py: '3.5px !important',
                                            '& fieldset': {
                                                borderColor: variables.gray200,
                                                borderRadius: '8px',
                                                borderWidth: '2px !important',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: variables.indigo300,
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: variables.indigo500,
                                            },
                                        },
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <>
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            </>
                                        ),
                                        endAdornment:
                                            !!patientSearch || !!inputSearch ? (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="delete search value"
                                                        onClick={() => {
                                                            setPage(0)
                                                            setPatientSearch('')
                                                            setInputSearch('')
                                                            // const payloadData = {
                                                            //     collectionName: query.get('trial_name'),
                                                            //     page: 1,
                                                            //     pageLimit: 10,
                                                            //     patientName: '',
                                                            //     partialMatch: false,
                                                            // }
                                                            // dispatch(
                                                            //     getMatchedPatientList(payloadData, token)
                                                            // )
                                                            const goldPayloadData = {
                                                                trial_id: query.get('trial_name'),
                                                                searchTerm: '',
                                                                page: 1,
                                                                pageLimit: 10,
                                                            }
                                                            dispatch(
                                                                getTrialGoldListData_v1(
                                                                    goldPayloadData,
                                                                    token
                                                                )
                                                            )
                                                        }}
                                                    >
                                                        <ClearIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ) : null,
                                    }}
                                    value={inputSearch}
                                    onChange={(e) => {
                                        setInputSearch(e.target.value)
                                    }}
                                    onKeyUp={(event, newValue) => {
                                        handleMatchesPatientSearch(event)
                                    }}
                                />
                                <Box>
                                    <Box sx={{ pl: 1 }}>
                                        <Button
                                            size="large"
                                            onClick={() => {
                                                setPage(0)
                                                // const payloadData = {
                                                //     collectionName: query.get('trial_name'),
                                                //     page: 1,
                                                //     pageLimit: 10,
                                                //     patientName: inputSearch,
                                                //     partialMatch: false,
                                                // }
                                                // dispatch(getMatchedPatientList(payloadData, token))
                                                const goldPayloadData = {
                                                    trial_id: query.get('trial_name'),
                                                    searchTerm: inputSearch,
                                                    page: 1,
                                                    pageLimit: 10,
                                                }
                                                dispatch(getTrialGoldListData_v1(goldPayloadData, token))
                                            }}
                                            variant="contained"
                                            className="btn-secondary"
                                        >
                                            Search
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>

                            <PatientListTable
                                tableHeaderData={tableHeaderData}
                                loading={goldPatientListLoading}
                                tableBodyData={patientMatchListData}
                            />
                        </Box>
                        {!!goldPatientListLoading ? (
                            <Box sx={{ my: 4 }}> </Box>
                        ) : (
                            <TablePagination
                                sx={{ my: '-8px !important', py: '-8px !important' }}
                                labelDisplayedRows={(from = page) =>
                                    `Displaying ${from.count > 10 ? `${from.from} - ` : ''}${
                                        from.to === -1 ? from.count : from.to
                                    } out of ${from.count} results`
                                }
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                component="div"
                                count={matchedPatientList?.resultCount ?? 0}
                                rowsPerPageOptions={[]}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                labelRowsPerPage="Displaying"
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'Displaying',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                ActionsComponent={TablePaginationActions}
                            />
                        )}
                    </Box>
                </Container>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    login: state.loginData.loginResponse,
    token: state.loginData.loginResponse.idToken,
    matchedPatientList: state.screenFlowPortalClinicalTrial.matchedPatientList,
    matchedPatientListLoading: state.screenFlowPortalClinicalTrial.matchedPatientListLoading,
    providerList: state.screenFlowPortalClinicalTrial.providerList,
    providerListLoading: state.screenFlowPortalClinicalTrial.providerListLoading,
    currentProviders: state.screenFlowPortalClinicalTrial.currentProviders,
    matchesPdf: state.screenFlowPortalClinicalTrial.matchesPdf,
    matchesPdfLoading: state.screenFlowPortalClinicalTrial.matchesPdfLoading,
    matchedPatientTrialCount: state.screenFlowPortalClinicalTrial.matchedPatientTrialCount,
    matchedPatientTrialCountLoading: state.screenFlowPortalClinicalTrial.matchedPatientTrialCountLoading,
    goldPatientList: state.screenFlowPortalClinicalTrial.goldPatientList,
    goldPatientListLoading: state.screenFlowPortalClinicalTrial.goldPatientListLoading,
    goldPatientSummary: state.screenFlowPortalClinicalTrial.goldPatientSummary,
    goldPatientSummaryLoading: state.screenFlowPortalClinicalTrial.goldPatientSummaryLoading,
})

const mapDispatchToProps = (dispatch) => {
    return {
        getMatchedPatientList: (payload, token) => dispatch(getMatchedPatientList(payload, token)),
        getProviderList: (payload, token) => dispatch(getProviderList(payload, token)),
        setCurrentProviders: (payload) => dispatch(setCurrentProviders(payload)),
        toggleAppointmentModal: (payload) => dispatch(toggleAppointmentModal(payload)),

        togglePatientInfoModal: (payload) => dispatch(togglePatientInfoModal(payload)),
        setPatientInfoData: (payload) => dispatch(setPatientInfoData(payload)),
        getMatchedPatientPdf: (payload, token) => dispatch(getMatchedPatientPdf(payload, token)),
        setAlert: (payload) => dispatch(setAlert(payload)),
        clearMatchedPatient: () => dispatch(clearMatchedPatient()),
        clearPartiallyMatchedPatient: () => dispatch(clearPartiallyMatchedPatient()),
        clearMatchesPdf: () => dispatch(clearMatchesPdf()),
        clearPartialMatchesPdf: () => dispatch(clearPartialMatchesPdf()),
        clearPatientAppointment: () => dispatch(clearPatientAppointment()),
        clearAppointmentPdf: () => dispatch(clearAppointmentPdf()),
        getTrialGoldListData_v1: (payload, token) => dispatch(getTrialGoldListData_v1(payload, token)),
        clearGoldPatient: () => dispatch(clearGoldPatient()),
        getGoldListSummary_v1: (payload, token) => dispatch(getGoldListSummary_v1(payload, token)),
        clearGoldPatientSummary: () => dispatch(clearGoldPatientSummary()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenFlow)
