import PropTypes from 'prop-types'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import variables from '../../../theme/variables.scss'
import _ from 'lodash'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { toggleAdverseEventModal } from '../../../redux/modals/toggleModal.actions'
import AdverseEventTable from './AdverseEventTable'
import { adverseEventFormData } from '../../../util/formData'
import AdverseForm from '../adverse-form'
import {
    getAdverseEventListData,
    getAdverseEventSubstanceData,
    setCurrentAdverseEvent,
} from '../../../redux/adverseEvent/adverseEvent.action'

const AntTabs = styled(Tabs)({
    borderBottom: 'solid',
    borderWidth: '1px',
    borderColor: variables.gray200,
    '& .MuiTabs-indicator': {
        backgroundColor: '#4F46E5',
    },
})

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    // lineHeight: 1,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: 500,
    marginRight: theme.spacing(2),
    padding: 0,
    color: '#4B5563',
    fontFamily: 'Inter',
    '&:hover': {
        color: '#4F46E5',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#4F46E5',
        fontWeight: 600,
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },
}))

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),
        borderBottom: 'none',
        minHeight: '500px',
        display: 'flex',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(0),
        position: 'absolute',
        bottom: 20,
        right: 25,
    },
    '& .MuiDialogTitle-root': {
        background: variables.gray100,
        fontWeight: 600,
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export const Index = ({
    patientId,
    token,
    adverseEventModal,
    adverseEventListData,
    adverseEventListLoading,
    adverseEventSubstanceData,
    adverseEventSubstanceLoading,
    currentAdverseEvent,
}) => {
    const [alignment, setAlignment] = React.useState('Details')
    const [showTable, setShowTable] = React.useState(false)
    const [eventID, setEventID] = React.useState('')

    const [searchValue, setSearchValue] = React.useState(null)

    //additional
    const [value, setValue] = React.useState(currentAdverseEvent ?? 'allergy')

    //when tabs changes resets table values
    useEffect(() => {
        setShowTable(false)
        setEventID('')
    }, [currentAdverseEvent])

    const handleTabChange = (event, newValue) => {
        setValue(newValue)
        getInitialValue(newValue)
    }

    const getInitialValue = (val) => {
        switch (val) {
            case 'allergy':
                const payloadAdverseAllergyList = {
                    patientId: patientId,
                    type: 'allergy',
                }

                dispatch(setCurrentAdverseEvent('allergy'))
                dispatch(getAdverseEventListData(payloadAdverseAllergyList, token))
                break
            case 'intolerance':
                const payloadAdverseIntoleranceList = {
                    patientId: patientId,
                    type: 'intolerance',
                }
                dispatch(setCurrentAdverseEvent('intolerance'))
                dispatch(getAdverseEventListData(payloadAdverseIntoleranceList, token))
                break

            default:
                return null
        }
    }

    const dispatch = useDispatch()
    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick') return
        dispatch(toggleAdverseEventModal(false))
    }

    const handleCardiacEvent = (substance) => {
        setShowTable(true)
        setEventID(substance)
        const payload = {
            patientId: patientId,
            type: value,
            substance: substance,
        }
        dispatch(getAdverseEventSubstanceData(payload, token))
    }

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth="lg"
                aria-labelledby="small-modal"
                open={adverseEventModal}
            >
                <DialogContent dividers style={{ minHeight: '80vh' }}>
                    <div
                        className="bg-gray-100 w-25 p-3 overflow-auto"
                        style={{
                            position: 'relative',
                        }}
                    >
                        <Typography className="fw-bold cursor-default gray-900 fs-5 mb-2">
                            Adverse Events
                        </Typography>
                        {/* tabs */}
                        {alignment === 'Details' && (
                            <Typography
                                className="gray-600 cursor-default"
                                sx={{
                                    width: '100%',
                                    color: 'text.secondary',
                                    // textAlign: 'right',
                                    fontSize: '0.85rem',
                                }}
                            >
                                <AntTabs value={value} onChange={handleTabChange} aria-label="ant example">
                                    <AntTab label="Allergies" value="allergy" />
                                    <AntTab label="Intolerances" value="intolerance" />
                                </AntTabs>
                            </Typography>
                        )}
                        {/* tabs end */}
                        {alignment === 'Details' ? (
                            <div
                                className="overflow-auto mt-2"
                                style={{
                                    maxHeight: '372px',
                                    backgroundColor: variables.white100,
                                    borderRadius: '5px',
                                    border: '1px solid',
                                    borderColor: variables.lightGray1,
                                    minHeight: '336px',
                                    overflow: 'auto',
                                }}
                            >
                                {adverseEventListLoading === false ? (
                                    adverseEventListData?.length > 0 ? (
                                        adverseEventListData?.map((adverseEvt, index) => {
                                            return (
                                                <p
                                                    key={index}
                                                    className={clsx(
                                                        eventID === adverseEvt?.substance &&
                                                            'bg-indigo-50 fw-bold',
                                                        'fs-8 ps-2 py-2 mb-0 gray-900 cursor-pointer'
                                                    )}
                                                    onClick={() => {
                                                        if (!!adverseEvt?.substance) {
                                                            handleCardiacEvent(adverseEvt?.substance)
                                                        }
                                                    }}
                                                >
                                                    {adverseEvt?.substance ?? '-NA-'}
                                                </p>
                                            )
                                        })
                                    ) : (
                                        <p className="mt-3 text-center p-1 fs-8 gray-900">No records found</p>
                                    )
                                ) : (
                                    <div className="loader">
                                        <img src="/loader.gif" alt="loader" />
                                    </div>
                                )}
                            </div>
                        ) : null}
                        {/* add/update Buttons */}
                        {alignment === 'Details' && (
                            <Stack
                                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                                sx={{
                                    position: 'absolute',
                                    bottom: '5%',
                                    width: '.9',
                                }}
                                spacing={1}
                            >
                                <Button
                                    onClick={() => setAlignment('Update')}
                                    size="small"
                                    variant="contained"
                                    className="btn-secondary"
                                    fullWidth={true}
                                    disabled={eventID === '' || !!adverseEventSubstanceLoading ? true : false}
                                >
                                    Update
                                </Button>
                                <Button
                                    size="small"
                                    onClick={() => {
                                        setAlignment('Add')
                                        setSearchValue(null)
                                    }}
                                    variant="contained"
                                    className="btn-light"
                                    fullWidth={true}
                                    startIcon={<AddIcon />}
                                >
                                    Add new
                                </Button>
                            </Stack>
                        )}
                    </div>

                    <div className="w-75 p-3 overflow-auto">
                        <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={4}
                            >
                                <Typography className="gray-900 cursor-default fw-bold fs-5 mt-2 mb-2">
                                    {alignment === 'Details' && !!eventID
                                        ? // ? eventID
                                          'Details'
                                        : alignment === 'Update'
                                        ? 'Update'
                                        : alignment === 'Add'
                                        ? 'Add New'
                                        : 'Details'}
                                </Typography>
                                {alignment === 'Add' ? (
                                    <Autocomplete
                                        getOptionLabel={(option) => option?.id}
                                        value={searchValue}
                                        onChange={(event, newValue) => {
                                            setSearchValue(newValue)
                                        }}
                                        size="small"
                                        disablePortal
                                        id="combo-box-demo"
                                        // options={cardiacEvents}
                                        options={adverseEventFormData}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Search Substances" />
                                        )}
                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                    />
                                ) : null}{' '}
                            </Stack>
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                variant="contained"
                                sx={{
                                    float: 'right',

                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Stack>

                        {alignment === 'Details' ? (
                            <>
                                {adverseEventSubstanceLoading === true ? (
                                    <div
                                        className="d-flex justify-content-center align-items-start"
                                        style={{
                                            height: '550px',
                                        }}
                                    >
                                        <div className="loader">
                                            <img src="/loader.gif" alt="loader" />
                                        </div>
                                    </div>
                                ) : !_.isEmpty(adverseEventSubstanceData) && !!showTable && !!eventID ? (
                                    <div className="mt-3">
                                        <AdverseEventTable
                                            tableData={adverseEventSubstanceData}
                                            eventID={eventID}
                                        />
                                    </div>
                                ) : !!showTable && !!eventID ? (
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                            border: '1px solid',
                                            borderColor: variables.gray200,
                                            borderRadius: '5px',
                                            height: '550px',
                                            fontSize: '12px',
                                        }}
                                    >
                                        <p style={{ fontSize: '12px' }}>No data found</p>
                                    </div>
                                ) : (
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                            border: '1px solid',
                                            borderColor: variables.gray200,
                                            borderRadius: '5px',
                                            height: '550px',
                                            fontSize: '12px',
                                        }}
                                    >
                                        <div>No Data Selected</div>
                                    </div>
                                )}
                            </>
                        ) : alignment === 'Add' || alignment === 'Update' ? (
                            <AdverseForm
                                setAlignment={setAlignment}
                                alignment={alignment}
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                                advFormData={alignment === 'Update' ? adverseEventSubstanceData : null}
                                advType={alignment === 'Update' ? eventID : null}
                            />
                        ) : null}
                    </div>
                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        patientId: state.patient.patientData.patientId,
        token: state.loginData.loginResponse.accessToken,

        adverseEventModal: state.toggleModals.adverseEventModal,
        adverseEventListData: state?.adverseEvent?.adverseEventListData,
        adverseEventListLoading: state?.adverseEvent?.adverseEventListLoading,
        adverseEventSubstanceData: state?.adverseEvent?.adverseEventSubstanceData,
        adverseEventSubstanceLoading: state?.adverseEvent?.adverseEventSubstanceLoading,
        currentAdverseEvent: state?.adverseEvent?.currentAdverseEvent,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleAdverseEventModal: (payload) => dispatch(toggleAdverseEventModal(payload)),
        getAdverseEventListData: (payload, token) => dispatch(getAdverseEventListData(payload, token)),
        getAdverseEventSubstanceData: (payload, token) =>
            dispatch(getAdverseEventSubstanceData(payload, token)),
        setCurrentAdverseEvent: (payload) => dispatch(setCurrentAdverseEvent(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
