import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import AdverseEventListTable from './AdverseEventListTable'

export const Index = ({ patientId, token, adverseEventListData, adverseEventListLoading, detail }) => {
    return (
        <>
            <Grid
                // spacing={2}
                rowSpacing={0.5}
                columnSpacing={2}
                container
                direction="row"
                justifyContent="start"
                alignItems="center"
            >
                {!!adverseEventListLoading ? (
                    <Grid item container direction="row" justifyContent="center" alignItems="center">
                        <img style={{ width: '35px' }} src="/loader.gif" alt="loader" />
                    </Grid>
                ) : _.isEmpty(adverseEventListData) ? (
                    <Grid item container direction="row" justifyContent="center" alignItems="center">
                        <Box>
                            <Typography className="gray-900 fw-normal fs-8 ">
                                {`No known ${
                                    detail === 'allergy'
                                        ? 'allergies'
                                        : detail === 'intolerance'
                                        ? 'intolerances'
                                        : 'Adverse Event'
                                }`}
                            </Typography>
                        </Box>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <AdverseEventListTable adverseEventListData={adverseEventListData} />
                    </Grid>
                )}
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => ({
    patientId: state.patient.patientData.patientId,
    token: state.loginData.loginResponse.accessToken,
    adverseEventListData: state?.adverseEvent?.adverseEventListData,
    adverseEventListLoading: state?.adverseEvent?.adverseEventListLoading,
})

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
