import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import moment from 'moment'
import '../../interval-history/interval-modal/interval-components/inputs.scss'
import variables from '../../../theme/variables.scss'
import { connect, useDispatch } from 'react-redux'
import axios from 'axios'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import {
    toggleDeactivateModal,
    toggleSessionModal,
    togglePhysicalModal,
} from '../../../redux/modals/toggleModal.actions'
import { setAlert } from '../../../redux/alert/alert.action'
import { getPhysicalListData } from '../../../redux/physicalExam/physicalExam.action'

import CardiacAccordion from './physical-form/Cardiac'
import RespiratoryAccordion from './physical-form/Respiratory'
import AbdominalAccordion from './physical-form/Abdominal'
import PeripheralAccordion from './physical-form/Peripheral'
import ProceduralAccordion from './physical-form/Procedural'
import SummaryText from './summary-text/index'
import AdditionalNotes from '../../interval-history/interval-modal/interval-form/AdditionalNotes'
import { TranscribeContext } from '../../../context/awsTranscribe'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),

        minHeight: '500px',
        minWidth: '500px',
        display: 'flex',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialogTitle-root': {
        background: variables.gray100,
        fontWeight: 600,
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export const Index = ({ patientId, token, physicalModal, lastUpdatedPhysical, physicalExamData }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = React.useState(false)
    const [expanded, setExpanded] = React.useState(false)
    const [isRecording, setIsRecording] = React.useState(false)
    const [partialTranscript, setPartialTranscript] = React.useState('')
    const { awsTranscribe } = React.useContext(TranscribeContext)
    const setFieldValueRef = React.useRef(null)
    const additionalTextRef = React.useRef(null)

    //heart accrodian
    const [heartAccordion, setHeartAccordion] = React.useState(false)
    const [murmurAccordion, setMurmurAccordion] = React.useState(false)

    //peripheral nested accordions
    const [carotidAccordion, setCarotidAccordion] = React.useState(false)
    const [radialAccordion, setRadialAccordion] = React.useState(false)
    const [femoralAccordion, setFemoralAccordion] = React.useState(false)
    const [pedalAccordion, setPedalAccordion] = React.useState(false)
    const [renalAccordion, setRenalAccordion] = React.useState(false)

    /* peripheral accodions */
    const handleCarotidAccordionChange = (panel) => (event, newExpanded) => {
        setCarotidAccordion(newExpanded ? panel : false)
    }
    const handleRadialAccordionChange = (panel) => (event, newExpanded) => {
        setRadialAccordion(newExpanded ? panel : false)
    }
    const handleFemoralAccordionChange = (panel) => (event, newExpanded) => {
        setFemoralAccordion(newExpanded ? panel : false)
    }
    const handlePedalAccordionChange = (panel) => (event, newExpanded) => {
        setPedalAccordion(newExpanded ? panel : false)
    }
    const handleRenalAccordionChange = (panel) => (event, newExpanded) => {
        setRenalAccordion(newExpanded ? panel : false)
    }

    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick') return
        if (awsTranscribe.socket) {
            awsTranscribe.stop()
        }
        dispatch(togglePhysicalModal(false))
    }

    /* accordian state handler */
    const handleAccordianChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }
    /* heart accordion change */
    const handleHeartAccordionChange = (panel) => (event, newExpanded) => {
        setHeartAccordion(newExpanded ? panel : false)
    }

    const handleMurmurAccordionChange = (panel) => (event, newExpanded) => {
        setMurmurAccordion(newExpanded ? panel : false)
    }

    //Validation
    const validationSchema = Yup.object().shape({
        PhysicalExam: Yup.array().of(
            Yup.object().shape({
                active: Yup.boolean().required('Value Required').nullable(),
            })
        ),
    })

    const initialValue = {
        physicalExam: [
            {
                type: 'Cardiac',
                active: false,
                diagnosisType: {
                    auscultaion: {
                        heartSounds: {
                            s1: '',
                            s2: '',
                            other: '',
                        },
                        murmurs: [
                            {
                                active: false,
                                intesity: null,
                                pitch: '',
                                timing: '',
                                peak: '',
                                profile: '',
                                location: '',
                                radiation: '',
                                maneuvers: {
                                    degree: '',
                                    value: '',
                                },
                            },
                        ],
                    },
                    palpitations: {
                        parasternum: {
                            character: '',
                            distribution: '',
                            place: '',
                        },
                        apex: {
                            impulse: '',
                            position: '',
                            character: '',
                            distribution: '',
                        },
                        tenderness: {
                            value: '',
                            additionalNotes: '',
                        },
                    },
                },
            },
            {
                type: 'Respiratory',
                active: false,
                diagnosisType: {
                    side: '',
                    area: '',
                    intensity: '',
                    charater: '',
                    otherSounds: '',
                },
            },
            {
                type: 'Abdominal',
                active: false,
                diagnosisType: {
                    palpation: {
                        tension: '',
                        tenderness: {
                            type: '',
                            horizontalSide: '',
                            verticalSide: '',
                        },
                        mass: '',
                    },
                    auscultation: {
                        bowelSounds: '',
                    },
                },
            },
            {
                type: 'Peripheral',
                active: false,
                diagnosisType: {
                    pulses: {
                        carotid: {
                            side: '',
                            pulse: '',
                            bruit: '',
                        },
                        radial: {
                            side: '',
                            pulse: '',
                        },
                        femoral: {
                            side: '',
                            pulse: '',
                            bruit: '',
                        },
                        pedal: {
                            side: '',
                            pulse: '',
                        },
                        renal: {
                            side: '',
                            bruit: '',
                        },
                    },
                    jvp: {
                        unit: 'cm',
                        value: 0,
                    },
                    edema: {
                        degree: '',
                        extent: '',
                        side: '',
                    },
                },
            },
            {
                type: 'Procedural',
                active: false,
                diagnosisType: {
                    accessSite: {
                        location: {
                            side: '',
                            area: '',
                        },
                        appearance: {
                            type: '',
                            injury: '',
                        },
                    },
                    additionalDetails: '',
                },
            },
            { type: 'Description', additionalNotes: '' },
        ],
    }
    const onSumbitHandler = (values) => {
        setLoading(true)
        if (awsTranscribe.socket) {
            awsTranscribe.stop()
        }
        const payload = { patientId: patientId, physicalExam: values?.physicalExam }

        /* axios */
        axios({
            method: !!lastUpdatedPhysical ? 'put' : 'post',
            url: `${process.env.REACT_APP_CARD_URL}/physical-exam?patientId=${patientId}`,
            data: payload,
            headers: {
                accessToken: token,
            },
        }).then((response) => {
            if (response?.data?.status === 200) {
                setLoading(false)
                dispatch(
                    setAlert({
                        alertType: 'success',

                        alertMsg: `Physical Exam ${!!lastUpdatedPhysical ? 'Updated' : 'Added'} Successfully`,
                        alertOpen: true,
                    })
                )
                const payloadList = {
                    patientId: patientId,
                }

                dispatch(getPhysicalListData(payloadList, token))
                dispatch(togglePhysicalModal(false))
            } else if (response?.data?.status === 400) {
                setLoading(false)
                dispatch(
                    setAlert({
                        alertType: 'error',

                        alertMsg: `Error ${response?.data?.error?.errorCode ?? ' '} : ${
                            response?.data?.error?.message
                        }`,
                        alertOpen: true,
                    })
                )
            } else if (response?.data?.status === 401) {
                setLoading(false)
                dispatch(toggleSessionModal(true, response?.data?.error))
            } else if (response?.data?.status === 403) {
                setLoading(false)
                dispatch(toggleDeactivateModal(true))
            }
            setLoading(false)
        })
    }

    const handleRecording = (checker) => {
        if (checker === 'uncheck') {
            setIsRecording(true)
            awsTranscribe.start()
        } else {
            setIsRecording(false)
            awsTranscribe.stop()
        }
    }

    React.useEffect(() => {
        const handleTranscriptError = (event) => {
            const errorMessage = event.detail.error
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg: 'Error in AWS Medical Transcribe',
                    alertOpen: true,
                })
            )
            awsTranscribe.stop()
            setIsRecording(false)
            console.log('Error in AWS Transcribe: ', errorMessage)
        }

        document.addEventListener('transcript-error', handleTranscriptError)
        return () => {
            document.removeEventListener('transcript-error', handleTranscriptError)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        const handleTranscript = (event) => {
            const isFinal = event.detail.isFinal
            const transcript = event.detail.transcript
            const valuesRef = additionalTextRef.current
            if (isFinal) {
                const updatedText = valuesRef + transcript + '\n'
                setFieldValueRef.current('physicalExam[5].additionalNotes', updatedText)
                setPartialTranscript('')
            } else {
                const partialTranscript = transcript + '...' // Modify this based on your desired format
                setPartialTranscript(partialTranscript)
            }
        }
        if (setFieldValueRef.current) {
            document.addEventListener('transcript', handleTranscript)
        }
        return () => {
            document.removeEventListener('transcript', handleTranscript)
        }
    }, [isRecording])

    return (
        <Formik
            enableReinitialize={true}
            initialValues={
                !!lastUpdatedPhysical ? { physicalExam: physicalExamData?.physicalExam } : initialValue
            }
            validationSchema={validationSchema}
            onSubmit={onSumbitHandler}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                isValid,
                setValues,
                setFieldValue,
                isSubmitting,
                setSubmitting,
                submitForm,
            }) => {
                // Assign the setFieldValue function to the useRef reference
                setFieldValueRef.current = setFieldValue
                additionalTextRef.current = values?.physicalExam[5]?.additionalNotes
                return (
                    <Form noValidate autoComplete="off">
                        <div name="physicalExam">
                            <BootstrapDialog
                                onClose={handleClose}
                                maxWidth="lg"
                                aria-labelledby="small-modal"
                                open={physicalModal}
                            >
                                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                                    <Typography className="fs-5 fw-bold">Physical Exam</Typography>
                                    <Typography
                                        className=" cursor-default gray-600 fs-9"
                                        sx={{
                                            fontWeight: 400,
                                        }}
                                    >
                                        {!!lastUpdatedPhysical
                                            ? `Last Updated: ${moment(lastUpdatedPhysical).fromNow()}`
                                            : 'Add Physical Exam'}
                                    </Typography>
                                </BootstrapDialogTitle>

                                <DialogContent dividers sx={{ padding: '0 !important' }}>
                                    <div className={clsx('w-61 overflow-auto p-2')}>
                                        <Stack
                                            sx={{ width: '100%', pr: 2, pl: 1 }}
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            spacing={4}
                                        >
                                            <Typography className="fs-5 fw-bold" gutterBottom>
                                                {!!lastUpdatedPhysical ? 'Update Physical Exam' : ''}
                                            </Typography>
                                        </Stack>

                                        {/* Cardiac*/}
                                        <CardiacAccordion
                                            values={values}
                                            handleAccordianChange={handleAccordianChange}
                                            expanded={expanded}
                                            setExpanded={setExpanded}
                                            handleHeartAccordionChange={handleHeartAccordionChange}
                                            heartAccordion={heartAccordion}
                                            setHeartAccordion={setHeartAccordion}
                                            murmurAccordion={murmurAccordion}
                                            setMurmurAccordion={setMurmurAccordion}
                                            handleMurmurAccordionChange={handleMurmurAccordionChange}
                                        />

                                        {/* Respiratory */}
                                        <RespiratoryAccordion
                                            values={values}
                                            expanded={expanded}
                                            setExpanded={setExpanded}
                                            handleAccordianChange={handleAccordianChange}
                                        />

                                        {/* Abdominal */}
                                        <AbdominalAccordion
                                            values={values}
                                            expanded={expanded}
                                            setExpanded={setExpanded}
                                            handleAccordianChange={handleAccordianChange}
                                        />

                                        {/* Peripheral */}
                                        <PeripheralAccordion
                                            values={values}
                                            expanded={expanded}
                                            setExpanded={setExpanded}
                                            handleAccordianChange={handleAccordianChange}
                                            carotidAccordion={carotidAccordion}
                                            setCarotidAccordion={setCarotidAccordion}
                                            handleCarotidAccordionChange={handleCarotidAccordionChange}
                                            radialAccordion={radialAccordion}
                                            setRadialAccordion={setRadialAccordion}
                                            handleRadialAccordionChange={handleRadialAccordionChange}
                                            femoralAccordion={femoralAccordion}
                                            setFemoralAccordion={setFemoralAccordion}
                                            handleFemoralAccordionChange={handleFemoralAccordionChange}
                                            pedalAccordion={pedalAccordion}
                                            setPedalAccordion={setPedalAccordion}
                                            handlePedalAccordionChange={handlePedalAccordionChange}
                                            renalAccordion={renalAccordion}
                                            setRenalAccordion={setRenalAccordion}
                                            handleRenalAccordionChange={handleRenalAccordionChange}
                                        />

                                        {/* procedural */}
                                        <ProceduralAccordion
                                            values={values}
                                            expanded={expanded}
                                            setExpanded={setExpanded}
                                            handleAccordianChange={handleAccordianChange}
                                        />
                                    </div>
                                    <div
                                        className={clsx('w-39 bg-gray-100  p-2')}
                                        style={{
                                            position: 'relative',
                                        }}
                                    >
                                        <Typography className="gray-600 fs-8 fw-normal" gutterBottom>
                                            Summary
                                        </Typography>

                                        <Box>
                                            {/* Dynamic summary text */}
                                            <SummaryText values={values?.physicalExam} expanded={expanded} />
                                        </Box>

                                        <AdditionalNotes
                                            title="Additional Notes"
                                            fieldName={`physicalExam[5].additionalNotes`}
                                            fieldVal={`${values?.physicalExam[5]?.additionalNotes}${
                                                isRecording ? partialTranscript : ''
                                            }`}
                                            isRecording={isRecording}
                                            handleRecording={handleRecording}
                                        />
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 1,
                                            justifyContent: 'end',
                                            padding: '2px',
                                        }}
                                    >
                                        <Button
                                            onClick={() => dispatch(togglePhysicalModal(false))}
                                            variant="contained"
                                            className="btn-white-outline"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="button"
                                            variant="contained"
                                            className="btn-primary"
                                            onClick={submitForm}
                                            disabled={!!loading}
                                        >
                                            {!!loading ? 'Processing...' : 'Save'}
                                        </Button>
                                    </Box>
                                </DialogActions>
                            </BootstrapDialog>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

const mapStateToProps = (state) => {
    return {
        patientId: state.patient.patientData.patientId,
        token: state.loginData.loginResponse.accessToken,
        physicalModal: state?.toggleModals?.physicalModal,
        lastUpdatedPhysical: state?.physicalExam?.lud,
        physicalExamData: state?.physicalExam?.list,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSessionModal: (payload, data) => dispatch(toggleSessionModal(payload, data)),
        toggleDeactivateModal: (payload) => dispatch(toggleDeactivateModal(payload)),
        setAlert: (payload) => dispatch(setAlert(payload)),
        togglePhysicalModal: (payload) => dispatch(togglePhysicalModal(payload)),
        getPhysicalListData: (payload, token) => dispatch(getPhysicalListData(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
