import React from 'react'
import '../interval-components/inputs.scss'
import variables from '../../../../theme/variables.scss'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import CustomizedAccordions from '../interval-components/Accordian'
import ColorToggleButton from '../interval-components/Toggler'
import Inputs from '../interval-components/Inputs'
import BoxWrapper from '../interval-components/BoxWrapper'
import StackWrapper from '../interval-components/StackWrapper'

const BreathAccordion = ({ values, expanded, setExpanded, handleAccordianChange }) => {
    return (
        <>
            <CustomizedAccordions
                expanded={expanded}
                setExpanded={setExpanded}
                handleAccordianChange={handleAccordianChange}
                title={'Shortness of Breath'}
                toggleBtn={
                    <ColorToggleButton
                        btnName={`intervalHistory[1].active`}
                        btnVal={values?.intervalHistory[1]?.active}
                    />
                }
            >
                <StackWrapper title="NYHA Class">
                    <BoxWrapper>
                        <Inputs
                            inputType={'toggleBtn'}
                            inputName={`intervalHistory[1].nyhaClass`}
                            inputVal={values?.intervalHistory[1]?.nyhaClass}
                            toggleBtnArr={_.range(1, 5)}
                        />
                    </BoxWrapper>
                </StackWrapper>

                <StackWrapper title="When did it start">
                    <BoxWrapper styles={{ alignItems: 'center', gap: '2px' }}>
                        <Inputs
                            inputType={'dateInput'}
                            inputName={`intervalHistory[1].since.date`}
                            inputVal={values?.intervalHistory[1]?.since?.date}
                        />
                    </BoxWrapper>

                    <BoxWrapper styles={{ alignItems: 'center', gap: '2px' }}>
                        <Typography
                            className="fw-normal"
                            sx={{
                                color: variables.gray500,
                                fontSize: '12px',
                                mx: '2px',
                                minWidth: '72px',
                            }}
                        >
                            Duration
                        </Typography>
                        <Inputs
                            inputType={'counter'}
                            inputName={`intervalHistory[1].since.value`}
                            inputVal={values?.intervalHistory[1]?.since?.value}
                        />
                        <Inputs
                            inputType={'dowpdown'}
                            inputName={`intervalHistory[1].since.unit`}
                            inputVal={values?.intervalHistory[1]?.since?.unit}
                            inputSize="small"
                            toggleBtnArr={['seconds', 'minutes', 'hours', 'days']}
                        />
                    </BoxWrapper>
                </StackWrapper>

                <StackWrapper title="What does it feel like">
                    <BoxWrapper>
                        <Inputs
                            inputType={'toggleBtn'}
                            inputName={`intervalHistory[1].feeling`}
                            inputVal={values?.intervalHistory[1]?.feeling}
                            inputSize="xxl"
                            toggleBtnArr={['Racing', 'Extra Beats', 'Skipped Beats', 'Thumping', 'Harder']}
                        />
                    </BoxWrapper>
                </StackWrapper>

                <StackWrapper title="What makes it worse">
                    <BoxWrapper>
                        <Inputs
                            inputType={'toggleBtn'}
                            inputName={`intervalHistory[1].exacerbatedBy`}
                            inputVal={values?.intervalHistory[1]?.exacerbatedBy}
                            inputSize="xl"
                            toggleBtnArr={['Nothing', 'Exertion', 'Laying Down', 'Sleep', 'Eating']}
                        />
                    </BoxWrapper>
                </StackWrapper>

                <StackWrapper title="What makes it better">
                    <BoxWrapper>
                        <Inputs
                            inputType={'toggleBtn'}
                            inputName={`intervalHistory[1].alleviatedBy`}
                            inputVal={values?.intervalHistory[1]?.alleviatedBy}
                            inputSize="xl"
                            toggleBtnArr={['Nothing', 'Rest', 'Laying Down', 'Inhalers']}
                        />
                    </BoxWrapper>
                </StackWrapper>

                <StackWrapper title="How often does it happen">
                    <BoxWrapper styles={{ alignItems: 'center', gap: '2px' }}>
                        <Typography
                            className="fw-normal"
                            sx={{
                                color: variables.gray500,
                                fontSize: '12px',
                                mx: '2px',
                                minWidth: '72px',
                            }}
                        >
                            Frequency
                        </Typography>
                        <Inputs
                            inputType={'counter'}
                            inputName={`intervalHistory[1].frequency.times`}
                            inputVal={values?.intervalHistory[1]?.frequency?.times}
                        />
                        <Inputs
                            inputType={'dowpdown'}
                            inputName={`intervalHistory[1].frequency.totalTime`}
                            inputVal={values?.intervalHistory[1]?.frequency?.totalTime}
                            inputSize="small"
                            toggleBtnArr={['daily', 'weekly', 'monthly', 'yearly']}
                        />
                    </BoxWrapper>

                    <BoxWrapper styles={{ alignItems: 'center', gap: '2px' }}>
                        <Typography
                            className="fw-normal"
                            sx={{
                                color: variables.gray500,
                                fontSize: '12px',
                                mx: '2px',
                                minWidth: '72px',
                            }}
                        >
                            Duration
                        </Typography>
                        <Inputs
                            inputType={'counter'}
                            inputName={`intervalHistory[1].frequency.value`}
                            inputVal={values?.intervalHistory[1]?.frequency?.value}
                        />
                        <Inputs
                            inputType={'dowpdown'}
                            inputName={`intervalHistory[1].frequency.unit`}
                            inputVal={values?.intervalHistory[1]?.frequency?.unit}
                            inputSize="small"
                            toggleBtnArr={['seconds', 'minutes', 'hours', 'days']}
                        />
                    </BoxWrapper>
                </StackWrapper>

                <StackWrapper title="Other associated symptoms">
                    <BoxWrapper styles={{ gap: 1 }}>
                        <Inputs
                            inputType={'toggleBtn'}
                            inputName={`intervalHistory[1].associatedSymptoms`}
                            inputVal={values?.intervalHistory[1]?.associatedSymptoms}
                            inputSize="xxxl"
                            toggleBtnArr={['Chest Pain', 'Unconscious', 'Cough', 'Throat Discomfort']}
                        />
                        <Inputs
                            inputType={'toggleBtn'}
                            inputName={`intervalHistory[1].associatedSymptoms`}
                            inputVal={values?.intervalHistory[1]?.associatedSymptoms}
                            inputSize="xxxl"
                            toggleBtnArr={['Palpitations', 'Lightheadedness']}
                        />
                    </BoxWrapper>
                </StackWrapper>

                {values?.intervalHistory[1]?.associatedSymptoms === 'Chest Pain' ? (
                    <StackWrapper title="CCS Class">
                        <BoxWrapper>
                            <Inputs
                                inputType={'toggleBtn'}
                                inputName={`intervalHistory[1].ccsClass`}
                                inputVal={
                                    values?.intervalHistory[1]?.associatedSymptoms === 'Chest Pain'
                                        ? values?.intervalHistory[1]?.ccsClass
                                        : null
                                }
                                toggleBtnArr={_.range(5)}
                            />
                        </BoxWrapper>
                    </StackWrapper>
                ) : null}
            </CustomizedAccordions>
        </>
    )
}

export default BreathAccordion
