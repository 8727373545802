import * as React from 'react'
import _ from 'lodash'
import parse from 'html-react-parser'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import variables from '../../../theme/variables.scss'
import '../../../util/screenflow-data.scss'

export default function FlowTable({ tabValue, inCriteria, exCriteria }) {
    return (
        <>
            <ol>
                {(tabValue === 'inc' && _.isEmpty(inCriteria)) ||
                (tabValue === 'exc' && _.isEmpty(exCriteria)) ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography className="fs-7 gray-800">No data available</Typography>
                    </Box>
                ) : null}

                {(tabValue === 'inc' ? inCriteria : tabValue === 'exc' ? exCriteria : [])?.map(
                    (item, idx) => (
                        <li key={idx} style={{ marginBottom: '4px', color: variables.gray900 }}>
                            {parse(item?.criterion)}
                        </li>
                    )
                )}
            </ol>
        </>
    )
}
