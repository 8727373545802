import * as React from 'react'
import { useMemo } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { styled } from '@mui/material/styles'
import variables from '../../../theme/variables.scss'
import _ from 'lodash'
import Table from '@mui/material/Table'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { DATE_FORMAT } from '../../../util/dateTimeFormats'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: variables.purple,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        textTransform: 'capitalize',
    },
}))

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//     '&:nth-of-type(odd)': {
//         backgroundColor: variables.purple100,
//     },
//     // hide last border
//     '&:last-child td, &:last-child th': {
//         border: 0,
//     },
// }))

const MedicalHistoryTable = ({ tableData, token, patientId, eventID }) => {
    const formattedDate = useMemo(
        () => moment(_.get(tableData, 'diagnoseDate', new Date())).format(DATE_FORMAT.MMM_YYYY),
        [tableData]
    )

    return (
        <>
            <TableContainer>
                <Table
                    sx={{
                        minWidth: 450,
                        marginBottom: 0.5,
                    }}
                    size="small"
                    aria-label="customized table"
                >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell
                                sx={{
                                    backgroundColor: `${variables.gray100} !important`,
                                    color: `${variables.gray900} !important`,
                                    textTransform: 'capitalize',
                                }}
                            >
                                {tableData?.subDiagnosis ?? 'Sub-Diagnosis: N/A'}
                            </StyledTableCell>
                            <StyledTableCell
                                sx={{
                                    backgroundColor: `${variables.gray100} !important`,
                                    color: `${variables.gray900} !important`,
                                }}
                                align="left"
                            >
                                {!!tableData?.diagnoseDate ? formattedDate : '-'}
                            </StyledTableCell>
                            <StyledTableCell
                                sx={{
                                    backgroundColor: `${variables.gray100} !important`,
                                    color: `${variables.gray900} !important`,
                                }}
                                align="left"
                            >
                                {tableData?.treatment}
                            </StyledTableCell>
                            <StyledTableCell
                                sx={{
                                    backgroundColor: `${variables.gray100} !important`,
                                    color: `${variables.gray900} !important`,
                                }}
                                align="left"
                            >
                                {tableData?.severity}
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.loginData.loginResponse.accessToken,
        patientId: state.patient.patientData.patientId,
        currentAdverseEvent: state?.adverseEvent?.currentAdverseEvent,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicalHistoryTable)
