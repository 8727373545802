import React from 'react'
import '../interval-components/inputs.scss'
import Box from '@mui/material/Box'

const BoxWrapper = ({ children, ...rest }) => {
    return (
        <Box
            sx={{
                maxWidth: '100% !important',
                display: 'flex',
                flexWrap: 'wrap',
                overflow: 'auto',
                ...rest.styles,
            }}
        >
            {children}
        </Box>
    )
}

export default BoxWrapper
