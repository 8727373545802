import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import variables from '../../../theme/variables.scss'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import Paper from '@mui/material/Paper'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '6px 16px',
    },
}))

export default function CardiacHistoryTimeline({ timelineData }) {
    const classes = useStyles()

    return (
        <Timeline position={timelineData?.length > 1 ? 'alternate' : 'left'} sx={{ pt: 2 }}>
            {timelineData.map((data, idx) => (
                <TimelineItem>
                    {timelineData?.length - 1 !== idx ? (
                        <TimelineSeparator>
                            <TimelineDot variant="outlined" sx={{ borderColor: variables.purple }} />
                            {timelineData?.length - 1 !== idx && <TimelineConnector />}
                        </TimelineSeparator>
                    ) : null}
                    {timelineData?.length - 1 === idx && (
                        <TimelineDot variant="outlined" sx={{ borderColor: variables.purple }} />
                    )}
                    <TimelineContent
                        key={`${idx}-${data}`}
                        sx={{
                            fontSize: 12,
                            fontWeight: 500,
                            color: variables.gray900,
                        }}
                    >
                        <Paper elevation={3} className={classes.paper}>
                            {data}
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    )
}
