import React from 'react'
import '../../../interval-history/interval-modal/interval-components/inputs.scss'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import CustomizedAccordions from '../../../interval-history/interval-modal/interval-components/Accordian'
import ColorToggleButton from '../../../interval-history/interval-modal/interval-components/Toggler'
import Inputs from '../../../interval-history/interval-modal/interval-components/Inputs'
import BoxWrapper from '../../../interval-history/interval-modal/interval-components/BoxWrapper'
import StackWrapper from '../../../interval-history/interval-modal/interval-components/StackWrapper'

const PeripheralAccordion = ({
    values,
    expanded,
    setExpanded,
    handleAccordianChange,
    carotidAccordion,
    setCarotidAccordion,
    handleCarotidAccordionChange,
    radialAccordion,
    setRadialAccordion,
    handleRadialAccordionChange,
    femoralAccordion,
    setFemoralAccordion,
    handleFemoralAccordionChange,
    pedalAccordion,
    setPedalAccordion,
    handlePedalAccordionChange,
    renalAccordion,
    setRenalAccordion,
    handleRenalAccordionChange,
}) => {
    return (
        <>
            <CustomizedAccordions
                expanded={expanded}
                setExpanded={setExpanded}
                handleAccordianChange={handleAccordianChange}
                title={'Peripheral'}
                toggleBtn={
                    <ColorToggleButton
                        btnName={`physicalExam[3].active`}
                        btnVal={values?.physicalExam[3]?.active}
                    />
                }
            >
                <Typography className="fs-8 mt-1 fw-bold gray-500" sx={{ minWidth: '150px' }}>
                    Palpation
                </Typography>

                {/*palpation accordions  */}
                <Box className="w-100">
                    <CustomizedAccordions
                        expanded={carotidAccordion}
                        setExpanded={setCarotidAccordion}
                        handleAccordianChange={handleCarotidAccordionChange}
                        title={'Carotid'}
                    >
                        {/* Side */}
                        <StackWrapper title="Side" direction="row" alignItems="center" spacing={2}>
                            <BoxWrapper>
                                <Inputs
                                    inputType={'toggleBtn'}
                                    inputSize="large"
                                    inputName={`physicalExam[3].diagnosisType.pulses.carotid.side`}
                                    inputVal={values?.physicalExam[3]?.diagnosisType?.pulses?.carotid?.side}
                                    toggleBtnArr={['Bilateral', 'Left', 'Right']}
                                />
                            </BoxWrapper>
                        </StackWrapper>

                        {/* pulse */}
                        <StackWrapper title="Pulse" direction="row" alignItems="center" spacing={2}>
                            <BoxWrapper>
                                <Inputs
                                    inputType={'toggleBtn'}
                                    inputSize="large"
                                    inputName={`physicalExam[3].diagnosisType.pulses.carotid.pulse`}
                                    inputVal={values?.physicalExam[3]?.diagnosisType?.pulses?.carotid?.pulse}
                                    toggleBtnArr={['Normal', 'Decreased', 'Absent']}
                                />
                            </BoxWrapper>
                        </StackWrapper>

                        {/* Bruit */}
                        <StackWrapper title="Bruit" direction="row" alignItems="center" spacing={2}>
                            <BoxWrapper>
                                <Inputs
                                    inputType={'toggleBtn'}
                                    inputSize="large"
                                    inputName={`physicalExam[3].diagnosisType.pulses.carotid.bruit`}
                                    inputVal={values?.physicalExam[3]?.diagnosisType?.pulses?.carotid?.bruit}
                                    toggleBtnArr={['None', 'Present']}
                                />
                            </BoxWrapper>
                        </StackWrapper>
                    </CustomizedAccordions>
                </Box>

                <Box className="w-100">
                    <CustomizedAccordions
                        expanded={radialAccordion}
                        setExpanded={setRadialAccordion}
                        handleAccordianChange={handleRadialAccordionChange}
                        title={'Radial'}
                    >
                        {/* Side */}
                        <StackWrapper title="Side" direction="row" alignItems="center" spacing={2}>
                            <BoxWrapper>
                                <Inputs
                                    inputType={'toggleBtn'}
                                    inputSize="large"
                                    inputName={`physicalExam[3].diagnosisType.pulses.radial.side`}
                                    inputVal={values?.physicalExam[3]?.diagnosisType?.pulses?.radial?.side}
                                    toggleBtnArr={['Bilateral', 'Left', 'Right']}
                                />
                            </BoxWrapper>
                        </StackWrapper>
                        {/* pulse */}
                        <StackWrapper title="Pulse" direction="row" alignItems="center" spacing={2}>
                            <BoxWrapper>
                                <Inputs
                                    inputType={'toggleBtn'}
                                    inputSize="large"
                                    inputName={`physicalExam[3].diagnosisType.pulses.radial.pulse`}
                                    inputVal={values?.physicalExam[3]?.diagnosisType?.pulses?.radial?.pulse}
                                    toggleBtnArr={['Normal', 'Decreased', 'Absent']}
                                />
                            </BoxWrapper>
                        </StackWrapper>
                    </CustomizedAccordions>
                </Box>

                <Box className="w-100">
                    <CustomizedAccordions
                        expanded={femoralAccordion}
                        setExpanded={setFemoralAccordion}
                        handleAccordianChange={handleFemoralAccordionChange}
                        title={'Femoral'}
                    >
                        {/* Side */}
                        <StackWrapper title="Side" direction="row" alignItems="center" spacing={2}>
                            <BoxWrapper>
                                <Inputs
                                    inputType={'toggleBtn'}
                                    inputSize="large"
                                    inputName={`physicalExam[3].diagnosisType.pulses.femoral.side`}
                                    inputVal={values?.physicalExam[3]?.diagnosisType?.pulses?.femoral?.side}
                                    toggleBtnArr={['Bilateral', 'Left', 'Right']}
                                />
                            </BoxWrapper>
                        </StackWrapper>

                        {/* pulse */}
                        <StackWrapper title="Pulse" direction="row" alignItems="center" spacing={2}>
                            <BoxWrapper>
                                <Inputs
                                    inputType={'toggleBtn'}
                                    inputSize="large"
                                    inputName={`physicalExam[3].diagnosisType.pulses.femoral.pulse`}
                                    inputVal={values?.physicalExam[3]?.diagnosisType?.pulses?.femoral?.pulse}
                                    toggleBtnArr={['Normal', 'Decreased', 'Absent']}
                                />
                            </BoxWrapper>
                        </StackWrapper>

                        {/* Bruit */}
                        <StackWrapper title="Bruit" direction="row" alignItems="center" spacing={2}>
                            <BoxWrapper>
                                <Inputs
                                    inputType={'toggleBtn'}
                                    inputSize="large"
                                    inputName={`physicalExam[3].diagnosisType.pulses.femoral.bruit`}
                                    inputVal={values?.physicalExam[3]?.diagnosisType?.pulses?.femoral?.bruit}
                                    toggleBtnArr={['None', 'Present']}
                                />
                            </BoxWrapper>
                        </StackWrapper>
                    </CustomizedAccordions>
                </Box>

                <Box className="w-100">
                    <CustomizedAccordions
                        expanded={pedalAccordion}
                        setExpanded={setPedalAccordion}
                        handleAccordianChange={handlePedalAccordionChange}
                        title={'Pedal'}
                    >
                        {/* Side */}
                        <StackWrapper title="Side" direction="row" alignItems="center" spacing={2}>
                            <BoxWrapper>
                                <Inputs
                                    inputType={'toggleBtn'}
                                    inputSize="large"
                                    inputName={`physicalExam[3].diagnosisType.pulses.pedal.side`}
                                    inputVal={values?.physicalExam[3]?.diagnosisType?.pulses?.pedal?.side}
                                    toggleBtnArr={['Bilateral', 'Left', 'Right']}
                                />
                            </BoxWrapper>
                        </StackWrapper>

                        {/* pulse */}
                        <StackWrapper title="Pulse" direction="row" alignItems="center" spacing={2}>
                            <BoxWrapper>
                                <Inputs
                                    inputType={'toggleBtn'}
                                    inputSize="large"
                                    inputName={`physicalExam[3].diagnosisType.pulses.pedal.pulse`}
                                    inputVal={values?.physicalExam[3]?.diagnosisType?.pulses?.pedal?.pulse}
                                    toggleBtnArr={['Normal', 'Decreased', 'Absent']}
                                />
                            </BoxWrapper>
                        </StackWrapper>
                    </CustomizedAccordions>
                </Box>

                <Box className="w-100">
                    <CustomizedAccordions
                        expanded={renalAccordion}
                        setExpanded={setRenalAccordion}
                        handleAccordianChange={handleRenalAccordionChange}
                        title={'Renal'}
                    >
                        {/* Side */}
                        <StackWrapper title="Side" direction="row" alignItems="center" spacing={2}>
                            <BoxWrapper>
                                <Inputs
                                    inputType={'toggleBtn'}
                                    inputSize="large"
                                    inputName={`physicalExam[3].diagnosisType.pulses.renal.side`}
                                    inputVal={values?.physicalExam[3]?.diagnosisType?.pulses?.renal?.side}
                                    toggleBtnArr={['Bilateral', 'Left', 'Right']}
                                />
                            </BoxWrapper>
                        </StackWrapper>

                        {/* Bruit */}
                        <StackWrapper title="Bruit" direction="row" alignItems="center" spacing={2}>
                            <BoxWrapper>
                                <Inputs
                                    inputType={'toggleBtn'}
                                    inputSize="large"
                                    inputName={`physicalExam[3].diagnosisType.pulses.renal.bruit`}
                                    inputVal={values?.physicalExam[3]?.diagnosisType?.pulses?.renal?.bruit}
                                    toggleBtnArr={['None', 'Present']}
                                />
                            </BoxWrapper>
                        </StackWrapper>
                    </CustomizedAccordions>
                </Box>

                {/* JVP */}
                <StackWrapper
                    title="JVP"
                    direction="row"
                    alignItems="center"
                    titleFontWeight="600"
                    spacing={2}
                >
                    <BoxWrapper styles={{ gap: 1 }}>
                        <Inputs
                            inputType={'counter'}
                            inputName={`physicalExam[3].diagnosisType.jvp.value`}
                            inputVal={values?.physicalExam[3]?.diagnosisType?.jvp?.value}
                        />
                        <Inputs
                            inputType={'dowpdown'}
                            inputName={`physicalExam[3].diagnosisType.jvp.unit`}
                            inputVal={values?.physicalExam[3]?.diagnosisType?.jvp?.unit}
                            inputSize="small"
                            toggleBtnArr={['mm', 'cm']}
                        />
                    </BoxWrapper>
                </StackWrapper>

                {/* edema */}
                <StackWrapper title="Edema" direction="row" titleFontWeight="600" spacing={2}>
                    <BoxWrapper
                        styles={{
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'start',
                            gap: 1,
                        }}
                    >
                        <BoxWrapper
                            styles={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'start',
                                gap: 1,
                            }}
                        >
                            <Typography className="fs-9 mt-1 fw-normal gray-500" sx={{ minWidth: '60px' }}>
                                Degree
                            </Typography>
                            <Inputs
                                inputType={'toggleBtn'}
                                inputSize="medium"
                                inputName={`physicalExam[3].diagnosisType.edema.degree`}
                                inputVal={values?.physicalExam[3]?.diagnosisType?.edema?.degree}
                                toggleBtnArr={['None', '+1', '+2', '+3', '+4']}
                            />
                        </BoxWrapper>

                        <BoxWrapper
                            styles={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'start',
                                gap: 1,
                            }}
                        >
                            <Typography className="fs-9 mt-1 fw-normal gray-500" sx={{ minWidth: '60px' }}>
                                Extent
                            </Typography>
                            <Inputs
                                inputType={'toggleBtn'}
                                inputSize="medium"
                                inputName={`physicalExam[3].diagnosisType.edema.extent`}
                                inputVal={values?.physicalExam[3]?.diagnosisType?.edema?.extent}
                                toggleBtnArr={['Feet', 'Ankles', 'Shins', 'Thighs', 'Sacrum']}
                            />
                        </BoxWrapper>

                        <BoxWrapper
                            styles={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'start',
                                gap: 1,
                            }}
                        >
                            <Typography className="fs-9 mt-1 fw-normal gray-500" sx={{ minWidth: '60px' }}>
                                Side
                            </Typography>
                            <Inputs
                                inputType={'toggleBtn'}
                                inputSize="medium"
                                inputName={`physicalExam[3].diagnosisType.edema.side`}
                                inputVal={values?.physicalExam[3]?.diagnosisType?.edema?.side}
                                toggleBtnArr={['Right', 'Left', 'Bilateral']}
                            />
                        </BoxWrapper>
                    </BoxWrapper>
                </StackWrapper>
            </CustomizedAccordions>
        </>
    )
}

export default PeripheralAccordion
