import PropTypes from 'prop-types'
import clsx from 'clsx'
import axios from 'axios'
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import variables from '../../../theme/variables.scss'
import { styled } from '@mui/material/styles'
import _ from 'lodash'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Chip from '@mui/material/Chip'
import DeleteIcon from '@mui/icons-material/Delete'
import DeleteDialog from '../../adverse-event/adverse-modal/DeleteDialog'
import {
    toggleMedicalHistoryModal,
    toggleDeactivateModal,
    toggleSessionModal,
} from '../../../redux/modals/toggleModal.actions'
import { sleep } from '../../../util'

import {
    getMedicalHistoryListData,
    getPastMedicalHistoryListData,
    getMedicalHistoryDiagnosisData,
    setCurrentMedicalHistory,
    deleteMedicalHistoryDiagnosis,
} from '../../../redux/medicalHistory/medicalHistory.action'
import { setAlert } from '../../../redux/alert/alert.action'
import MedicalHistoryTable from './MedicalHistoryTable'
import MedicalForm from '../medical-form/index'
import FilterBox from './FilterBox'

const filter = createFilterOptions()

const AntTabs = styled(Tabs)({
    borderBottom: 'solid',
    borderWidth: '1px',
    borderColor: variables.gray200,
    '& .MuiTabs-indicator': {
        backgroundColor: '#4F46E5',
    },
})

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    // lineHeight: 1,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: 500,
    marginRight: theme.spacing(2),
    padding: 0,
    color: '#4B5563',
    fontFamily: 'Inter',
    '&:hover': {
        color: '#4F46E5',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#4F46E5',
        fontWeight: 600,
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },
}))

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),
        borderBottom: 'none',
        minHeight: '500px',
        display: 'flex',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(0),
        position: 'absolute',
        bottom: 20,
        right: 25,
    },
    '& .MuiDialogTitle-root': {
        background: variables.gray100,
        fontWeight: 600,
    },
}))

const BootstrapDialogTitle = React.memo((props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
})

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export const Index = ({
    patientId,
    token,
    medicalHistoryModal,
    medicalHistoryListData,
    medicalHistoryListLoading,
    medicalHistoryDiagnosisData,
    medicalHistoryDiagnosisLoading,
    currentMedicalHistory,
    pastMedicalHistoryListData,
    pastMedicalHistoryListLoading,
    isDemoPatient,
}) => {
    const [open, setOpen] = React.useState(false) // eslint-disable-line no-unused-vars

    const [alignment, setAlignment] = React.useState('Details')
    const [showTable, setShowTable] = React.useState(false)
    const [eventID, setEventID] = React.useState('')
    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)
    const [deleteLoader, setDeleteLoader] = React.useState(false)

    const [value, setValue] = React.useState(currentMedicalHistory ?? 'other') //Tab value
    /* search functionality */
    const [searchOptions, setSearchOptions] = React.useState([])
    const [searchValue, setSearchValue] = React.useState(searchOptions[0] ?? null)
    const [inputSearchValue, setInputSearchValue] = React.useState('')
    const [searchLoading, setSearchLoading] = React.useState(false)

    const [filterOptions, setFilterOptions] = React.useState({ chapterCode: '', sectionCode: '' })

    useEffect(() => {
        const fetchData = async () => {
            try {
                setSearchLoading(true)

                const filterObj = !!filterOptions?.chapterCode
                    ? { section: filterOptions?.sectionCode, chapter: filterOptions?.chapterCode }
                    : {}
                const result = await axios.get(
                    `${process.env.REACT_APP_CARD_URL}/diagnosis-history/search?type=other&searchTerm=${inputSearchValue}`,
                    {
                        params: filterObj,
                        headers: {
                            accessToken: token,
                        },
                    }
                )
                if (result?.data?.status === 200) {
                    setSearchOptions(result?.data?.output ?? [])
                    setSearchLoading(false)
                } else if (result?.data?.status === 401) {
                    dispatch(toggleSessionModal(true, result?.data?.error))
                    setSearchLoading(false)
                } else if (result?.data?.status === 403) {
                    dispatch(toggleDeactivateModal(true))
                    setSearchLoading(false)
                }
            } catch (err) {
                console.log(err)
                setSearchLoading(false)
            }
        }

        const cleanTimeout = setTimeout(() => {
            if (inputSearchValue?.length > 0) {
                fetchData()
            }
        }, 2000)
        return () => {
            clearTimeout(cleanTimeout)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputSearchValue])

    //when tabs changes resets table values
    useEffect(() => {
        setShowTable(false)
        setEventID('')
    }, [currentMedicalHistory])

    const handleTabChange = (event, newValue) => {
        setValue(newValue)
        getInitialValue(newValue)
    }

    const getInitialValue = (val) => {
        switch (val) {
            case 'other':
                const payloadMedicalHistoryList = {
                    patientId: patientId,
                    type: 'other',
                    status: 'active',
                }
                dispatch(setCurrentMedicalHistory('other'))
                dispatch(getMedicalHistoryListData(payloadMedicalHistoryList, token))
                break
            case 'past':
                const payloadPastMedicalHistoryList = {
                    patientId: patientId,
                    type: 'other',
                    status: 'past',
                }
                dispatch(getPastMedicalHistoryListData(payloadPastMedicalHistoryList, token))
                dispatch(setCurrentMedicalHistory('past'))
                break

            default:
                return null
        }
    }

    const dispatch = useDispatch()
    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick') return
        dispatch(toggleMedicalHistoryModal(false))
    }

    const handleCardiacEvent = (diagnosis) => {
        if (_.isNil(diagnosis) || diagnosis === '') {
            setEventID('')
            dispatch(
                setAlert({
                    alertType: 'error',
                    alertMsg: `Something went wrong! Unable to fetch details. Please! try again.`,
                    alertOpen: true,
                })
            )
            return
        }
        setShowTable(true)
        setEventID(diagnosis)
        const payload = {
            patientId: patientId,
            name: diagnosis,
            type: value,
        }
        dispatch(getMedicalHistoryDiagnosisData(payload, token))
    }

    /* delete diagnosis */
    const handleDelete = async () => {
        setDeleteLoader(true)
        const deletePayload = {
            patientId: patientId,
            type: 'other',
            diagnosis: {
                _id: medicalHistoryDiagnosisData?._id,
                name: medicalHistoryDiagnosisData?.name,
            },
        }
        dispatch(deleteMedicalHistoryDiagnosis(deletePayload, token))
        await sleep(800)
        setShowTable(false)
        setEventID('')
        getInitialValue(value)
        setDeleteModalOpen(false)
        setDeleteLoader(false)
        clearTimeout(sleep)
    }

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth="lg"
                aria-labelledby="small-modal"
                open={medicalHistoryModal}
            >
                <DialogContent dividers style={{ minHeight: '80vh' }}>
                    <div
                        className="bg-gray-100 w-25 p-3 overflow-auto"
                        style={{
                            position: 'relative',
                        }}
                    >
                        <Typography className="fw-bold cursor-default gray-900 fs-5 mb-2">
                            Medical History
                        </Typography>
                        {/* tabs */}
                        {alignment === 'Details' && (
                            <Typography
                                className="gray-600 cursor-default"
                                sx={{
                                    width: '100%',
                                    color: 'text.secondary',
                                    // textAlign: 'right',
                                    fontSize: '0.85rem',
                                }}
                            >
                                <AntTabs value={value} onChange={handleTabChange} aria-label="ant example">
                                    <AntTab label="Active" value="other" />
                                    <AntTab label="Past" value="past" disabled={!!isDemoPatient} />
                                </AntTabs>
                            </Typography>
                        )}

                        {alignment === 'Details' ? (
                            <div
                                className="overflow-auto mt-2"
                                style={{
                                    maxHeight: '372px',
                                    backgroundColor: variables.white100,
                                    borderRadius: '5px',
                                    border: '1px solid',
                                    borderColor: variables.lightGray1,
                                    minHeight: '336px',
                                    overflow: 'auto',
                                }}
                            >
                                {(currentMedicalHistory === 'other'
                                    ? medicalHistoryListLoading
                                    : pastMedicalHistoryListLoading) === false ? (
                                    (currentMedicalHistory === 'other'
                                        ? medicalHistoryListData
                                        : pastMedicalHistoryListData
                                    )?.length > 0 ? (
                                        (currentMedicalHistory === 'other'
                                            ? medicalHistoryListData
                                            : pastMedicalHistoryListData
                                        )?.map((diagnosisDetail, index) => {
                                            return (
                                                <p
                                                    key={index}
                                                    className={clsx(
                                                        eventID === diagnosisDetail?.name &&
                                                            'bg-indigo-50 fw-bold',
                                                        'fs-8 ps-2 py-2 mb-0 gray-900 cursor-pointer text-truncate text-capitalize'
                                                    )}
                                                    onClick={() => handleCardiacEvent(diagnosisDetail?.name)}
                                                >
                                                    {!!diagnosisDetail?.customLabel
                                                        ? diagnosisDetail?.customLabel ?? '-'
                                                        : diagnosisDetail?.name ?? '-'}
                                                </p>
                                            )
                                        })
                                    ) : (
                                        <p className="mt-3 text-center p-1 fs-8 gray-900">No records found</p>
                                    )
                                ) : (
                                    <div className="loader">
                                        <img src="/loader.gif" alt="loader" />
                                    </div>
                                )}
                            </div>
                        ) : null}
                        {/* add/update Buttons */}
                        {alignment === 'Details' && (
                            <Stack
                                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                                sx={{
                                    position: 'absolute',
                                    bottom: '5%',
                                    width: '.9',
                                }}
                                spacing={1}
                            >
                                <Button
                                    onClick={() => setAlignment('Update')}
                                    size="small"
                                    variant="contained"
                                    className="btn-secondary"
                                    fullWidth={true}
                                    disabled={
                                        eventID === '' || !!medicalHistoryDiagnosisLoading ? true : false
                                    }
                                >
                                    Update
                                </Button>
                                <Button
                                    size="small"
                                    onClick={() => {
                                        setAlignment('Add')
                                        setSearchValue(null)
                                    }}
                                    variant="contained"
                                    className="btn-light"
                                    fullWidth={true}
                                    startIcon={<AddIcon />}
                                >
                                    Add new
                                </Button>
                            </Stack>
                        )}
                    </div>

                    <div className="w-75 p-3 overflow-auto">
                        <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={4}
                            >
                                <Typography className="gray-900 cursor-default fw-bold fs-5 mt-2 mb-2">
                                    {alignment === 'Details' && !!eventID
                                        ? // ? eventID
                                          'Details'
                                        : alignment === 'Update'
                                        ? 'Update'
                                        : alignment === 'Add'
                                        ? 'Add New'
                                        : 'Details'}
                                </Typography>
                                {alignment === 'Add' ? (
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <Autocomplete
                                            size="small"
                                            // disablePortal
                                            id="free-solo-with-text-demo"
                                            value={searchValue}
                                            onChange={(event, newValue) => {
                                                if (typeof newValue === 'string') {
                                                    setSearchValue({ name: newValue })
                                                    setOpen(false)
                                                } else if (newValue && newValue.inputValue) {
                                                    // Create a new value from the user input
                                                    setSearchValue({ name: newValue.inputValue })
                                                    setOpen(false)
                                                } else {
                                                    setSearchValue(newValue)
                                                    setOpen(false)
                                                }
                                                setOpen(false)
                                            }}
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params)
                                                const { inputValue } = params
                                                // flag for the creation of a new value
                                                const isExisting = options.some(
                                                    (option) => inputValue === option.name
                                                )

                                                if (inputValue !== '' && !isExisting && !searchLoading) {
                                                    filtered.push({
                                                        inputValue,
                                                        name: `Add "${inputValue}"`,
                                                    })
                                                }
                                                return filtered
                                            }}
                                            selectOnFocus
                                            clearOnBlur
                                            handleHomeEndKeys
                                            options={searchOptions}
                                            getOptionLabel={(option) => {
                                                // Value selected with enter, right from the input
                                                if (typeof option === 'string') {
                                                    return option
                                                }
                                                // Add "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.inputValue
                                                }
                                                return ` ${option?.code ? `${option?.code} - ` : ''}${
                                                    option?.name
                                                }`
                                            }}
                                            renderOption={(props, option) => (
                                                <li {...props}>{option.name}</li>
                                            )}
                                            sx={{ width: 300 }}
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField {...params} label="Search ICD Diagnosis" />
                                            )}
                                            inputValue={inputSearchValue}
                                            onInputChange={(event, newInputValue) => {
                                                setInputSearchValue(newInputValue)
                                            }}
                                            loading={searchLoading}
                                            loadingText={'Loading...'}
                                            isOptionEqualToValue={(option, value) =>
                                                option?._id === value?._id
                                            }
                                        />
                                        <FilterBox
                                            filterOptions={filterOptions}
                                            setFilterOptions={setFilterOptions}
                                        />
                                    </Stack>
                                ) : null}{' '}
                            </Stack>
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                variant="contained"
                                sx={{
                                    float: 'right',
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Stack>

                        {alignment === 'Details' ? (
                            <>
                                {medicalHistoryDiagnosisLoading === true ? (
                                    <div
                                        className="d-flex justify-content-center align-items-start"
                                        style={{
                                            height: '550px',
                                        }}
                                    >
                                        <div className="loader">
                                            <img src="/loader.gif" alt="loader" />
                                        </div>
                                    </div>
                                ) : !_.isEmpty(medicalHistoryDiagnosisData) && !!showTable && !!eventID ? (
                                    <div className="mt-1">
                                        {/* title and label */}
                                        <Typography className="gray-900 cursor-default fw-bold fs-5 mb-2 text-capitalize">
                                            {medicalHistoryDiagnosisData?.name ?? '-'}{' '}
                                            {medicalHistoryDiagnosisData?.icd10Code && (
                                                <span className="text-uppercase">
                                                    ({medicalHistoryDiagnosisData?.icd10Code})
                                                </span>
                                            )}
                                            {!!medicalHistoryDiagnosisData?.customLabel ? (
                                                <>
                                                    <Chip
                                                        label={
                                                            medicalHistoryDiagnosisData?.customLabel ?? null
                                                        }
                                                        sx={{
                                                            minWidth: '60px',
                                                            mx: 1,
                                                            textTransform: 'capitalize',
                                                            fontSize: '12px',
                                                            fontWeight: 600,
                                                            color: variables.white100,
                                                            backgroundColor: variables.purple,
                                                        }}
                                                    />
                                                </>
                                            ) : null}
                                            <>
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() => setDeleteModalOpen(true)}
                                                >
                                                    <DeleteIcon
                                                        sx={{
                                                            color: variables.red600,
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </IconButton>
                                            </>
                                        </Typography>

                                        <MedicalHistoryTable
                                            tableData={medicalHistoryDiagnosisData}
                                            eventID={eventID}
                                        />
                                        <DeleteDialog
                                            open={deleteModalOpen}
                                            setOpen={setDeleteModalOpen}
                                            handleDelete={handleDelete}
                                            handleClose={() => setDeleteModalOpen(false)}
                                            loading={deleteLoader}
                                        />
                                    </div>
                                ) : !!showTable && !!eventID ? (
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                            border: '1px solid',
                                            borderColor: variables.gray200,
                                            borderRadius: '5px',
                                            height: '550px',
                                            fontSize: '12px',
                                        }}
                                    >
                                        <p style={{ fontSize: '12px' }}>No data found</p>
                                    </div>
                                ) : (
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                            border: '1px solid',
                                            borderColor: variables.gray200,
                                            borderRadius: '5px',
                                            height: '550px',
                                            fontSize: '12px',
                                        }}
                                    >
                                        <div>No Data Selected</div>
                                    </div>
                                )}
                            </>
                        ) : alignment === 'Add' || alignment === 'Update' ? (
                            <MedicalForm
                                setAlignment={setAlignment}
                                alignment={alignment}
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                                advFormData={alignment === 'Update' ? medicalHistoryDiagnosisData : null}
                                advType={alignment === 'Update' ? eventID : null}
                            />
                        ) : null}
                    </div>
                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        patientId: state.patient.patientData.patientId,
        token: state.loginData.loginResponse.accessToken,
        medicalHistoryModal: state.toggleModals.medicalHistoryModal,
        medicalHistoryListData: state?.medicalHistory?.medicalHistoryListData,
        medicalHistoryListLoading: state?.medicalHistory?.medicalHistoryListLoading,
        medicalHistoryDiagnosisData: state?.medicalHistory?.medicalHistoryDiagnosisData,
        medicalHistoryDiagnosisLoading: state?.medicalHistory?.medicalHistoryDiagnosisLoading,
        currentMedicalHistory: state?.medicalHistory?.currentMedicalHistory,
        pastMedicalHistoryListData: state?.medicalHistory?.pastMedicalHistoryListData,
        pastMedicalHistoryListLoading: state?.medicalHistory?.pastMedicalHistoryListLoading,
        isDemoPatient: state.patient.patientData.isDemoPatient,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleMedicalHistoryModal: (payload) => dispatch(toggleMedicalHistoryModal(payload)),
        getMedicalHistoryListData: (payload, token) => dispatch(getMedicalHistoryListData(payload, token)),
        getPastMedicalHistoryListData: (payload, token) =>
            dispatch(getPastMedicalHistoryListData(payload, token)),
        getMedicalHistoryDiagnosisData: (payload, token) =>
            dispatch(getMedicalHistoryDiagnosisData(payload, token)),
        setCurrentMedicalHistory: (payload) => dispatch(setCurrentMedicalHistory(payload)),
        deleteMedicalHistoryDiagnosis: (payload, token) =>
            dispatch(deleteMedicalHistoryDiagnosis(payload, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
