import * as React from 'react'
import { useEffect } from 'react'
import variables from '../../theme/variables.scss'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import LaunchIcon from '@mui/icons-material/Launch'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

export default function TrialCriteriaSummary({ consultLetterFlag = false, cardData, loading }) {
    function getDuration(end) {
        if (end < 100) {
            return 400
        }
        if (end > 100 && end < 600) {
            return 700
        }
        if (end > 600 && end < 900) {
            return 900
        } else {
            return 1100
        }
    }

    function animateValue(index, start, end) {
        const duration = getDuration(end)
        const obj = document.getElementById(`flipper-animation-${index}`)
        let startTimestamp = null
        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp
            const progress = Math.min((timestamp - startTimestamp) / duration, 1)
            obj.innerHTML = Math.floor(progress * (end - start) + start)
            if (progress < 1) {
                window.requestAnimationFrame(step)
            }
        }
        window.requestAnimationFrame(step)
    }

    /* Animated value function to run only once */
    useEffect(() => {
        cardData?.map((item, index) => animateValue(index, 0, item?.subTitle))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: { xs: 1, sm: 0.8 },
                flexWrap: 'wrap',
                justifyContent: { xs: 'start', md: 'space-between' },
                minHeight: '6rem',
            }}
        >
            {cardData.map((item, index) => (
                <Card
                    sx={{
                        fontSize: '14px',
                        borderRadius: '11px',
                        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.06)',
                        border: '1px solid #E0E0E0',
                        minWidth: { xs: '100%', md: '32%' },
                    }}
                    key={index}
                >
                    <CardContent sx={{ paddingBottom: '2px !important' }}>
                        <Box className="d-flex justify-content-start align-items-center fs-8 mb-2">
                            <Typography
                                sx={{
                                    fontWeight: '600 !important',
                                    fontSize: '0.9rem !important',
                                    color: variables.gray600,
                                }}
                            >
                                {item.title}
                            </Typography>
                            {item.popup && (
                                <IconButton
                                    id={`popup-icon-${index}`}
                                    sx={{
                                        p: '0px !important',
                                        ml: '0.5rem !important',
                                    }}
                                    edge="end"
                                    onClick={(event) => {
                                        item?.popupFn()

                                        event.stopPropagation()
                                    }}
                                >
                                    <LaunchIcon
                                        sx={{ width: '0.85rem', height: '0.85rem', color: variables.purple }}
                                    />
                                </IconButton>
                            )}
                        </Box>
                        <Box className="d-flex justify-content-start align-items-center fs-8 mb-2 gap-2">
                            {item.icon}
                            <Typography
                                id={`flipper-animation-${index}`}
                                variant="h5"
                                sx={{ color: item.color, fontWeight: 600 }}
                                component="div"
                            >
                                {item?.subTitle}
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            ))}
        </Box>
    )
}
