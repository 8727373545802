import * as React from 'react'
import _ from 'lodash'
import variables from '../../../../theme/variables.scss'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import CircleIcon from '@mui/icons-material/Circle'
import { physicalExamsummaryGenerator } from '../../../../util/index'
import Typography from '@mui/material/Typography'

const SummaryText = ({ values, expanded, consultLetterFlag = false }) => {
    return (
        <>
            <List
                dense={true}
                sx={{
                    width: '100%',
                    maxHeight: consultLetterFlag ? 'fit-content' : '340px',
                    minHeight: '100px',
                    cursor: 'not-allowed',
                    fontSize: consultLetterFlag ? '12px' : '14px',
                    borderRadius: '6px',
                    border: '1px solid ',
                    borderColor: variables.lightGray1,
                    borderWidth: 1,
                    mb: 2,
                    background: variables.white100,
                    overflow: 'auto',
                }}
            >
                {!consultLetterFlag ? (
                    <ListItem sx={{ gap: 1, padding: '0px 6px 0px !important' }}>
                        <ListItemText
                            primary={
                                <Typography
                                    style={{
                                        fontWeight: consultLetterFlag ? 500 : 600,
                                        fontSize: consultLetterFlag ? '0.875rem' : '0.9375rem',
                                        color: consultLetterFlag ? variables.gray900 : 'initial',
                                    }}
                                >
                                    {expanded || ``}
                                </Typography>
                            }
                        />
                    </ListItem>
                ) : null}
                {!consultLetterFlag &&
                    !!expanded &&
                    _.isEmpty(physicalExamsummaryGenerator(_.find(values, ['type', expanded]))) && (
                        <Typography sx={{ padding: '0px 6px 0px !important' }}>
                            No summary available
                        </Typography>
                    )}
                {physicalExamsummaryGenerator(_.find(values, ['type', expanded]))?.map((value, idx) => (
                    <ListItem
                        key={idx}
                        sx={{
                            gap: 1,
                            padding: '0px 18px 0px !important',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                        }}
                    >
                        {value?.includes(':-') ? null : value?.includes(':') ? (
                            <CircleIcon sx={{ width: 6, height: 6 }} />
                        ) : null}
                        <ListItemText
                            disableTypography
                            primary={
                                <Typography
                                    style={{
                                        fontWeight:
                                            value?.includes(':') || value?.includes(':-') ? 'normal' : 500,
                                        fontSize: consultLetterFlag ? '0.75rem' : '0.875rem',
                                        lineHeight: consultLetterFlag ? 'normal' : 'normal',
                                        color: consultLetterFlag ? variables.gray900 : 'initial',
                                    }}
                                >
                                    {value}
                                </Typography>
                            }
                        />
                    </ListItem>
                ))}
            </List>
        </>
    )
}

export default SummaryText
