import PropTypes from 'prop-types'
import clsx from 'clsx'
import axios from 'axios'
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import variables from '../../../../theme/variables.scss'
import { alpha, styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Autocomplete from '@mui/material/Autocomplete'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import {
    toggleEcgModal,
    toggleCardiacModal,
    toggleDeactivateModal,
    toggleSessionModal,
} from '../../../../redux/modals/toggleModal.actions'

import { FormControl, InputBase, MenuItem, Select } from '@mui/material'
import moment from 'moment'
import MedChart from '../cardiac-chart/index'
import MedicationDetails from '../medication-details'
import { DATE_FORMAT } from '../../../../util/dateTimeFormats'
import { MEDICATION_TYPE } from '../../../../util'
import {
    getCardiacData,
    getCardiacListData,
    getPastListData,
    getOtherListData,
    getMeasurementData,
    clearMeasurement,
    resetCardiacModal,
    setCurrentMedication,
} from '../../../../redux/COP/cardiacList/cardiacList.action'
import CardiacForm from '../cardiac-form'
import { measurementDropdownData } from '../../../../util/formData'
import _ from 'lodash'

const AntTabs = styled(Tabs)({
    borderBottom: 'solid',
    borderWidth: '1px',
    borderColor: variables.gray200,
    '& .MuiTabs-indicator': {
        backgroundColor: '#4F46E5',
    },
})

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    // lineHeight: 1,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: 500,
    marginRight: theme.spacing(2),
    padding: 0,
    color: '#4B5563',
    fontFamily: 'Inter',
    '&:hover': {
        color: '#4F46E5',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#4F46E5',
        fontWeight: 600,
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },
}))

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),
        borderBottom: 'none',
        minHeight: '500px',
        display: 'flex',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(0),
        position: 'absolute',
        bottom: 20,
        right: 25,
    },
    '& .MuiDialogTitle-root': {
        background: variables.gray100,
        fontWeight: 600,
    },
}))

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        width: '100%',
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? variables.gray300 : variables.gray800,
        border: '1px solid',
        borderColor: variables.lightGray1,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.5,
        height: '15px',
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: 'Inter',
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export const Index = ({
    cardiacModal,
    patientId,
    token,
    cardiacData,
    cardiacLoading,
    cardiacMedication,
    cardiacMedLoading,
    currentMedication,
    otherData,
    otherLoading,
    pastData,
    pastLoading,
    isDemoPatient,
}) => {
    const submit = React.useRef(null)
    const [alignment, setAlignment] = React.useState('Details')

    const [value, setValue] = React.useState(currentMedication ?? 'cardiac')
    const [medID, setMedID] = React.useState('')
    const [module, setModule] = React.useState('none')
    const [subModuleData, setSubModuleData] = React.useState([])
    const [subModule, setSubModule] = React.useState('none')

    // const [searchValue, setSearchValue] = React.useState(options[0]);
    const [searchOptions, setSearchOptions] = React.useState([])
    const [searchValue, setSearchValue] = React.useState(searchOptions[0] ?? null)
    const [inputSearchValue, setInputSearchValue] = React.useState('')
    const [searchLoading, setSearchLoading] = React.useState(false)

    // const inputSearchHandler = (event, newInputValue) => {
    //     setInputSearchValue(newInputValue)
    // }
    // const searchValueHandler = (event, newValue) => {
    //     setSearchValue(newValue)
    // }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setSearchLoading(true)
                const result = await axios.get(
                    `${process.env.REACT_APP_CARD_URL}/medication/search?searchTerm=${inputSearchValue}`,
                    {
                        headers: {
                            accessToken: token,
                        },
                    }
                )
                if (result?.data?.status === 200) {
                    setSearchOptions(result?.data?.output ?? [])
                    setSearchLoading(false)
                } else if (result?.data?.status === 401) {
                    dispatch(toggleSessionModal(true, result?.data?.error))
                    setSearchLoading(false)
                } else if (result?.data?.status === 403) {
                    dispatch(toggleDeactivateModal(true))
                    setSearchLoading(false)
                }
            } catch (err) {
                console.log(err)
                setSearchLoading(false)
            }
        }

        const cleanTimeout = setTimeout(() => {
            if (inputSearchValue?.length > 2) {
                fetchData()
            }
        }, 2000)
        return () => {
            clearTimeout(cleanTimeout)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputSearchValue])

    /*  */

    const dispatch = useDispatch()
    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick') return
        dispatch(toggleCardiacModal(false))
        dispatch(clearMeasurement())
    }

    const handleTabChange = (event, newValue) => {
        setValue(newValue)
        getInitialValue(newValue)
    }

    const getInitialValue = (val) => {
        switch (val) {
            case MEDICATION_TYPE.CARDIAC:
                const payloadCardiacList = {
                    patientId: patientId,
                    type: MEDICATION_TYPE.CARDIAC,
                    status: 'active',
                }
                dispatch(getCardiacListData(payloadCardiacList, token))
                dispatch(setCurrentMedication(MEDICATION_TYPE.CARDIAC))
                break
            case MEDICATION_TYPE.OTHER:
                const payloadOtherList = {
                    patientId: patientId,
                    type: MEDICATION_TYPE.OTHER,
                    status: 'active',
                }
                dispatch(getOtherListData(payloadOtherList, token))
                dispatch(setCurrentMedication(MEDICATION_TYPE.OTHER))
                break
            case MEDICATION_TYPE.PAST:
                const payloadPastList = {
                    patientId: patientId,
                    status: MEDICATION_TYPE.PAST,
                }
                dispatch(getPastListData(payloadPastList, token))
                dispatch(setCurrentMedication(MEDICATION_TYPE.PAST))
                break
            default:
                return null
        }
    }

    /* module & submodule changes changes */
    const handleChangeModule = (event) => {
        setModule(event.target.value)
        // setSubModule()
    }

    const handleChangeSubModule = (event) => {
        if (medID !== '' && event.target.value !== 'none') {
            const sixtyMonth = moment().subtract(60, 'months').calendar()
            const proposedDate = moment(sixtyMonth).format(DATE_FORMAT.YYYY_MM_DD) + 'T00:00:00.000Z'
            const payload = {
                patientId: patientId,
                type: medID,
                moduleKey: module ?? null,
                moduleValue: event.target.value,
                date: proposedDate,
            }
            dispatch(getMeasurementData(payload, token))
            setSubModule(event.target.value)
        }
    }

    useEffect(() => {
        setSubModuleData(_.find(measurementDropdownData, { moduleKey: module })?.moduleValue ?? [])
        setSubModule('none')
    }, [module])

    /* handleMedicationData */
    const handleMedicationData = (medication) => {
        setMedID(medication?.name)
        const sixtyMonth = moment().subtract(60, 'months').calendar()
        const proposedDate = moment(sixtyMonth).format('YYYY-MM-DD') + 'T00:00:00.000Z'
        const payload = {
            patientId: patientId,
            type: medication?.name,
            date: proposedDate,
        }
        dispatch(getCardiacData(payload, token))
    }

    //when tab changes resets medications details, graph values and effect values
    useEffect(() => {
        setSubModule('none')
        setModule('none')
        dispatch(clearMeasurement())
        dispatch(resetCardiacModal())
        setMedID('')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMedication])

    const medicationSwitchHelper = (val, isDataSwitch) => {
        const CARDIAC_VALUE = isDataSwitch ? cardiacData : cardiacLoading
        const OTHER_VALUE = isDataSwitch ? otherData : otherLoading
        const PAST_VALUE = isDataSwitch ? pastData : pastLoading
        const helperValues = {
            [MEDICATION_TYPE.CARDIAC]: CARDIAC_VALUE,
            [MEDICATION_TYPE.OTHER]: OTHER_VALUE,
            [MEDICATION_TYPE.PAST]: PAST_VALUE,
        }
        return helperValues[val] || CARDIAC_VALUE
    }

    // Default to Metoprolol - Vitals - BP for demo patient
    useEffect(() => {
        if (!!isDemoPatient) {
            /* Dispatch medication */
            setMedID('metoprolol')
            const sixtyMonth = moment().subtract(60, 'months').calendar()
            const proposedDate = moment(sixtyMonth).format('YYYY-MM-DD') + 'T00:00:00.000Z'
            const payloadMedication = {
                patientId: patientId,
                type: 'metoprolol',
                date: proposedDate,
            }
            dispatch(getCardiacData(payloadMedication, token))
            /* set module */
            setModule('vitals')
            /* set Submodule */
            const payloadSubModule = {
                patientId: patientId,
                type: 'metoprolol',
                moduleKey: 'vitals',
                moduleValue: 'bp',
                date: proposedDate,
            }
            dispatch(getMeasurementData(payloadSubModule, token))
            setTimeout(() => {
                setSubModule('bp')
            }, 1000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth="lg"
                aria-labelledby="small-modal"
                open={cardiacModal}
            >
                <DialogContent dividers style={{ minHeight: '80vh' }}>
                    <div
                        className="bg-gray-100 w-25 p-3 overflow-auto"
                        style={{
                            position: 'relative',
                        }}
                    >
                        <Typography className="fw-bold cursor-default gray-900 fs-5 mb-0">
                            Medication
                        </Typography>

                        {/* tabs */}
                        {alignment === 'Details' && (
                            <Typography
                                className="gray-600 cursor-default"
                                sx={{
                                    width: '100%',
                                    color: 'text.secondary',
                                    // textAlign: 'right',
                                    fontSize: '0.85rem',
                                }}
                            >
                                <AntTabs value={value} onChange={handleTabChange} aria-label="ant example">
                                    <AntTab label="Cardiac" value="cardiac" />
                                    <AntTab label="Other" value="other" />
                                    <AntTab label="Past" value="past" />
                                </AntTabs>
                            </Typography>
                        )}
                        {/* tabs end */}

                        {alignment === 'Details' && (
                            <>
                                <div
                                    className="overflow-auto mt-2"
                                    style={{
                                        maxHeight: '224px',
                                        backgroundColor: variables.white100,
                                        borderRadius: '5px',
                                        border: '1px solid',
                                        borderColor: variables.lightGray1,
                                        minHeight: '186px',
                                        overflow: 'auto',
                                    }}
                                >
                                    {medicationSwitchHelper(currentMedication, false) === false ? (
                                        medicationSwitchHelper(currentMedication, true)?.length > 0 ? (
                                            medicationSwitchHelper(currentMedication, true)?.map(
                                                (med, index) => {
                                                    return (
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            // spacing={2}
                                                            key={index}
                                                            className={clsx(
                                                                medID === med?.name && 'bg-indigo-50 fw-bold',
                                                                'ps-2 py-2 fs-8 gray-900 cursor-pointer'
                                                            )}
                                                            sx={{
                                                                textTransform: 'capitalize !important',
                                                            }}
                                                            onClick={() => handleMedicationData(med)}
                                                        >
                                                            <Grid
                                                                sx={{
                                                                    width: 'fit-content !important',
                                                                    maxWidth: '50% !important',
                                                                    overflow: 'clip',
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow: 'ellipsis',
                                                                }}
                                                                item
                                                                xs={7.5}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        textOverflow: 'ellipsis',
                                                                        overflow: 'clip !important',
                                                                    }}
                                                                >
                                                                    {med?.name ?? '-'}
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={4.5}>
                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="space-between"
                                                                    alignItems="center"
                                                                    sx={{
                                                                        textTransform: 'uppercase !important',
                                                                        px: 1,
                                                                    }}
                                                                >
                                                                    <Typography className="fs-9">
                                                                        {(med?.strength || med?.dosage) ??
                                                                            'N/A'}
                                                                    </Typography>
                                                                    <Typography className="fs-9">
                                                                        {med?.frequency ?? 'N/A'}
                                                                    </Typography>
                                                                </Stack>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                }
                                            )
                                        ) : (
                                            <p className="mt-3 text-center p-1 fs-8 gray-900">
                                                No records found
                                            </p>
                                        )
                                    ) : (
                                        <div className="loader">
                                            <img src="/loader.gif" alt="loader" />
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                        {alignment === 'Details' && (
                            <Typography className="fw-bold cursor-default gray-900 fs-8 mt-3">
                                Plot with:
                            </Typography>
                        )}

                        {/* adding form control dropdown */}
                        {alignment === 'Details' && (
                            <>
                                <FormControl sx={{ m: 0, width: 1 }} variant="standard">
                                    <Typography className="gray-600 fs-9 mt-2" gutterBottom>
                                        Module
                                    </Typography>

                                    <Select
                                        labelId="demo-customized-select-label1"
                                        id="demo-customized-select1"
                                        value={module}
                                        defaultValue="none"
                                        onChange={handleChangeModule}
                                        input={<BootstrapInput />}
                                    >
                                        {module === 'none' && (
                                            <MenuItem className="fs-9" value="none" disabled>
                                                Select Module
                                            </MenuItem>
                                        )}
                                        <MenuItem className="fs-9" value="vitals">
                                            Vitals
                                        </MenuItem>
                                        <MenuItem className="fs-9" value="labs">
                                            Labs
                                        </MenuItem>
                                        <MenuItem className="fs-9" value="ecg">
                                            ECG
                                        </MenuItem>
                                        <MenuItem className="fs-9" value="holter">
                                            Holter
                                        </MenuItem>
                                        <MenuItem className="fs-9" value="gxt">
                                            GXT
                                        </MenuItem>
                                        <MenuItem className="fs-9" value="echo">
                                            Echo
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ m: 0, width: 1 }} variant="standard">
                                    <Typography className="gray-600 fs-9 mt-2" gutterBottom>
                                        Measurement
                                    </Typography>

                                    <Select
                                        labelId="demo-customized-select-label0"
                                        id="demo-customized-select0"
                                        value={subModule}
                                        defaultValue="none"
                                        onChange={handleChangeSubModule}
                                        input={<BootstrapInput />}
                                        // disabled={medID === '' ? true : false} //optional
                                    >
                                        {subModule === 'none' && (
                                            <MenuItem className="fs-9" value="none" disabled>
                                                Select Measurement
                                            </MenuItem>
                                        )}
                                        {subModuleData?.length > 0 &&
                                            subModuleData?.map((subM, idx) => {
                                                return (
                                                    <MenuItem
                                                        className="fs-9"
                                                        // onClick={()=>console.log(subM?.id)}
                                                        key={idx}
                                                        value={subM?.val}
                                                    >
                                                        {subM?.id}
                                                    </MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                                {(module !== 'none' || subModule !== 'none') && _.isEmpty(medID) ? (
                                    <Typography className="gray-600 fs-10 mt-2" gutterBottom>
                                        Please select a medication from the medication list to get the
                                        Dose-Effect analysis graph.
                                    </Typography>
                                ) : null}
                            </>
                        )}
                        {/* dropdown ends */}

                        {/* add/update Buttons */}
                        {alignment === 'Details' && (
                            <Stack
                                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                                sx={{
                                    position: 'absolute',
                                    bottom: '5%',
                                    width: '.9',
                                }}
                                spacing={1}
                            >
                                <Button
                                    onClick={() => setAlignment('Update')}
                                    size="small"
                                    variant="contained"
                                    className="btn-secondary"
                                    fullWidth={true}
                                    disabled={
                                        medID === '' ||
                                        !!cardiacMedLoading ||
                                        !!cardiacMedication[0]?.isCombinationDrug
                                            ? true
                                            : false
                                    }
                                >
                                    Update
                                </Button>
                                <Button
                                    size="small"
                                    onClick={() => {
                                        setAlignment('Add')
                                        setSearchOptions([])
                                        // setSearchValue(searchOptions[0] ?? null)
                                        setSearchValue(null)
                                        setInputSearchValue('')
                                    }}
                                    variant="contained"
                                    className="btn-light"
                                    fullWidth={true}
                                    startIcon={<AddIcon />}
                                >
                                    Add new
                                </Button>
                            </Stack>
                        )}
                    </div>
                    <div className="w-75 p-3 overflow-auto">
                        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={4}>
                            <Typography className="gray-900 cursor-default fw-bold fs-5 mb-2">
                                {alignment === 'Details'
                                    ? 'Dose-Effect Analysis'
                                    : alignment === 'Add'
                                    ? 'Add New'
                                    : alignment === 'Update'
                                    ? 'Update'
                                    : 'NA'}
                            </Typography>
                            {alignment === 'Add' ? (
                                <Autocomplete
                                    loading={searchLoading}
                                    getOptionLabel={(option) =>
                                        `${option?.brandName} ${
                                            _.isNil(option?.strength) ? '' : `(${option?.strength})`
                                        }`
                                    }
                                    value={searchValue}
                                    onChange={(event, newValue) => {
                                        setSearchValue(newValue)
                                    }}
                                    inputValue={inputSearchValue}
                                    onInputChange={(event, newInputValue) => {
                                        setInputSearchValue(newInputValue)
                                    }}
                                    size="small"
                                    disablePortal
                                    id="combo-box-demo"
                                    options={searchOptions}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Search Medication" />
                                    )}
                                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                                />
                            ) : null}
                        </Stack>

                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            variant="contained"
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>

                        {/* Right hand modal form or graph */}
                        {alignment === 'Update' || alignment === 'Add' ? (
                            <CardiacForm
                                submit={submit}
                                setAlignment={setAlignment}
                                alignment={alignment}
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                            />
                        ) : alignment === 'Details' ? (
                            <>
                                <MedChart type="desktop" />
                                <div className="p-1 ps-0 pe-0">
                                    <p
                                        className="color-primary fw-bold"
                                        style={{ marginBottom: '5px', marginTop: '8px' }}
                                    >
                                        Medication Details
                                    </p>

                                    {/* medication details */}
                                    {cardiacMedLoading ? (
                                        <div className="loader">
                                            <img src="/loader.gif" alt="loader" />
                                        </div>
                                    ) : (
                                        <MedicationDetails medicationdata={cardiacMedication} />
                                    )}
                                </div>
                            </>
                        ) : null}
                    </div>
                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        patientId: state.patient.patientData.patientId,
        token: state.loginData.loginResponse.accessToken,
        cardiacModal: state?.toggleModals?.cardiacModal,
        cardiacData: state?.cardiacList?.list,
        cardiacMedication: state?.cardiacList?.cardiacData,
        cardiacLoading: state?.cardiacList?.cardiacLoading,
        cardiacMedLoading: state?.cardiacList?.cardiacMedLoading,
        measurementLoading: state?.cardiacList?.measurementLoading,
        measurementData: state?.cardiacList?.measurementData,
        otherData: state.cardiacList.otherData,
        otherLoading: state.cardiacList.otherLoading,
        pastData: state.cardiacList.pastData,
        pastLoading: state.cardiacList.pastLoading,
        currentMedication: state.cardiacList.currentMedication,
        isDemoPatient: state.patient.patientData.isDemoPatient,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleEcgModal: (payload) => dispatch(toggleEcgModal(payload)),
        toggleCardiacModal: (payload) => dispatch(toggleCardiacModal(payload)),
        getCardiacData: (payload, token) => dispatch(getCardiacData(payload, token)),
        getMeasurementData: (payload, token) => dispatch(getMeasurementData(payload, token)),
        clearMeasurement: () => dispatch(clearMeasurement()),
        getCardiacListData: (payload, token) => dispatch(getCardiacListData(payload, token)),
        getOtherListData: (payload, token) => dispatch(getOtherListData(payload, token)),
        getPastListData: (payload, token) => dispatch(getPastListData(payload, token)),
        resetCardiacModal: () => dispatch(resetCardiacModal()),
        setCurrentMedication: (payload) => dispatch(setCurrentMedication(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
